import currency from "currency.js";
import moment from "moment";
import React from "react";

const EmpProfile = ({
  emergencyInfo,
  personalInfo,
  getAddressValue,
  getPhoneValue,
  employeeHMOs,
  lifeInsurances,
  jobInfo,
  jobGrades,
  contracts,
  supervisors,
  departments,
  locations,
  educationInfo,
}) => {
  return (
    <>
      <div className="row">
        <div className="col-md-6">
          <div className="card-box profile-box">
            <h3 className="card-title">
              Personal Information{" "}
              <span
                className="edit-icon p-cursor"
                data-toggle="modal"
                data-target="#personal_info_modal"
              >
                <i className="fa fa-pencil"></i>
              </span>
            </h3>
            <ul className="personal-info">
              <div style={{ display: "flex", padding: "4px 0px" }}>
                <div className="title" style={{ flex: 1 }}>
                  Name:
                </div>
                <div className="text" style={{ flex: 1 }}>
                  {(personalInfo?.name?.first
                    ? personalInfo?.name?.first
                    : "") +
                    " " +
                    (personalInfo?.name?.middle
                      ? personalInfo?.name?.middle
                      : "") +
                    " " +
                    (personalInfo?.name?.last ? personalInfo?.name?.last : "")}
                </div>
              </div>
              <div style={{ display: "flex", padding: "4px 0px" }}>
                <div className="title" style={{ flex: 1 }}>
                  Address:
                </div>
                <div className="text" style={{ flex: 1 }}>
                  {getAddressValue(
                    personalInfo?.address?.apartment,
                    personalInfo?.address?.street,
                    personalInfo?.address?.city,
                    personalInfo?.address?.state,
                    personalInfo?.address?.zip
                  )}
                </div>
              </div>
              <div style={{ display: "flex", padding: "4px 0px" }}>
                <div className="title" style={{ flex: 1 }}>
                  Tel:
                </div>
                <div className="text" style={{ flex: 1 }}>
                  <span className="p-cursor">
                    {getPhoneValue(
                      personalInfo?.phone?.home,
                      personalInfo?.phone?.alternate
                    )}
                  </span>
                </div>
              </div>
              <div style={{ display: "flex", padding: "4px 0px" }}>
                <div className="title" style={{ flex: 1 }}>
                  Email:
                </div>
                <div className="text" style={{ flex: 1 }}>
                  <span className="p-cursor">{personalInfo?.email}</span>
                </div>
              </div>
              <div style={{ display: "flex", padding: "4px 0px" }}>
                <div className="title" style={{ flex: 1 }}>
                  Govt ID:
                </div>
                <div className="text" style={{ flex: 1 }}>
                  {personalInfo?.govtId}
                </div>
              </div>
              <div style={{ display: "flex", padding: "4px 0px" }}>
                <div className="title" style={{ flex: 1 }}>
                  Birthday:
                </div>
                <div className="text" style={{ flex: 1 }}>
                  {personalInfo?.birth}
                </div>
              </div>
              <div style={{ display: "flex", padding: "4px 0px" }}>
                <div className="title" style={{ flex: 1 }}>
                  Marital Status:
                </div>
                <div className="text" style={{ flex: 1 }}>
                  {personalInfo?.martial}
                </div>
              </div>
              <div style={{ display: "flex", padding: "4px 0px" }}>
                <div className="title" style={{ flex: 1 }}>
                  Employment of spouse:
                </div>
                <div className="text" style={{ flex: 1 }}>
                  {personalInfo?.spouse?.name}
                </div>
              </div>
              <div style={{ display: "flex", padding: "4px 0px" }}>
                <div className="title" style={{ flex: 1 }}>
                  Spouse’s Employer:
                </div>
                <div className="text" style={{ flex: 1 }}>
                  {personalInfo?.spouse?.employer}
                </div>
              </div>
              <div style={{ display: "flex", padding: "4px 0px" }}>
                <div className="title" style={{ flex: 1 }}>
                  Spouse’s Work Phone:
                </div>
                <div className="text" style={{ flex: 1 }}>
                  {personalInfo?.spouse?.phone}
                </div>
              </div>
              <div style={{ display: "flex", padding: "4px 0px" }}>
                <div className="title" style={{ flex: 1 }}>
                  Previous Employee:
                </div>
                <div className="text" style={{ flex: 1 }}>
                  {personalInfo?.prevEmployee?.employee}
                </div>
              </div>
              <div style={{ display: "flex", padding: "4px 0px" }}>
                <div className="title" style={{ flex: 1 }}>
                  Previous Employer Phone:
                </div>
                <div className="text" style={{ flex: 1 }}>
                  {personalInfo?.prevEmployee?.phone}
                </div>
              </div>
            </ul>
          </div>
        </div>
        <div className="col-md-6">
          <div className="card-box profile-box">
            <h3 className="card-title">
              Emergency Contact{" "}
              <span
                className="p-cursor edit-icon"
                data-toggle="modal"
                data-target="#emergency_contact_modal"
              >
                <i className="fa fa-pencil"></i>
              </span>
            </h3>
            <ul className="personal-info">
              <div style={{ display: "flex", padding: "4px 0px" }}>
                <div className="title" style={{ flex: 1 }}>
                  Name:
                </div>
                <div className="text" style={{ flex: 1 }}>
                  {(emergencyInfo?.name?.first
                    ? emergencyInfo.name.first
                    : "") +
                    " " +
                    (emergencyInfo?.name?.middle
                      ? emergencyInfo?.name?.middle
                      : "") +
                    " " +
                    (emergencyInfo?.name?.last
                      ? emergencyInfo?.name?.last
                      : "")}
                </div>
              </div>
              <div style={{ display: "flex", padding: "4px 0px" }}>
                <div className="title" style={{ flex: 1 }}>
                  Address:
                </div>
                <div className="text" style={{ flex: 1 }}>
                  {getAddressValue(
                    emergencyInfo?.address?.apartment,
                    emergencyInfo?.address?.street,
                    emergencyInfo?.address?.city,
                    emergencyInfo?.address?.state,
                    emergencyInfo?.address?.zip
                  )}
                </div>
              </div>
              <div style={{ display: "flex", padding: "4px 0px" }}>
                <div className="title" style={{ flex: 1 }}>
                  Phone:{" "}
                </div>
                <div className="text" style={{ flex: 1 }}>
                  {getPhoneValue(
                    emergencyInfo?.phone?.primary,
                    emergencyInfo?.phone?.secondary
                  )}
                </div>
              </div>
              <div style={{ display: "flex", padding: "4px 0px" }}>
                <div className="title" style={{ flex: 1 }}>
                  Relationship:
                </div>
                <div className="text" style={{ flex: 1 }}>
                  {emergencyInfo?.relation}
                </div>
              </div>
              <div style={{ display: "flex", padding: "4px 0px" }}>
                <div className="title" style={{ flex: 1 }}>
                  Guarantor:
                </div>
                <div className="text" style={{ flex: 1 }}>
                  {(emergencyInfo?.guarantor?.title
                    ? emergencyInfo?.guarantor?.title
                    : "") +
                    " " +
                    (emergencyInfo?.guarantor?.name
                      ? emergencyInfo?.guarantor?.name
                      : "")}
                </div>
              </div>
              <div style={{ display: "flex", padding: "4px 0px" }}>
                <div className="title" style={{ flex: 1 }}>
                  Guarantor Phone:
                </div>
                <div className="text" style={{ flex: 1 }}>
                  {emergencyInfo?.guarantor?.phone}
                </div>
              </div>
              <div style={{ display: "flex", padding: "4px 0px" }}>
                <div className="title" style={{ flex: 1 }}>
                  Guarantor Address:
                </div>
                <div className="text" style={{ flex: 1 }}>
                  {emergencyInfo?.guarantor?.address}
                </div>
              </div>
              <div style={{ display: "flex", padding: "4px 0px" }}>
                <div className="title" style={{ flex: 1 }}>
                  Referee:
                </div>
                <div className="text" style={{ flex: 1 }}>
                  {(emergencyInfo?.referee?.title
                    ? emergencyInfo?.referee?.title
                    : "") +
                    " " +
                    (emergencyInfo?.referee?.name
                      ? emergencyInfo?.referee?.name
                      : "")}
                </div>
              </div>
              <div style={{ display: "flex", padding: "4px 0px" }}>
                <div className="title" style={{ flex: 1 }}>
                  Referee Phone:
                </div>
                <div className="text" style={{ flex: 1 }}>
                  {emergencyInfo?.referee?.phone}
                </div>
              </div>
              <div style={{ display: "flex", padding: "4px 0px" }}>
                <div className="title" style={{ flex: 1 }}>
                  Referee Address:
                </div>
                <div className="text" style={{ flex: 1 }}>
                  {emergencyInfo?.referee?.address}
                </div>
              </div>
              <div style={{ display: "flex", padding: "4px 0px" }}>
                <div className="title" style={{ flex: 1 }}>
                  Employee HMO:
                </div>
                <div className="text" style={{ flex: 1 }}>
                  {employeeHMOs?.length > 0
                    ? employeeHMOs?.map((item) => {
                        return item?._id === emergencyInfo?.employeeHMO
                          ? item?.name
                          : "";
                      })
                    : null}
                </div>
              </div>
              <div style={{ display: "flex", padding: "4px 0px" }}>
                <div className="title" style={{ flex: 1 }}>
                  Group Life Insurance:
                </div>
                <div className="text" style={{ flex: 1 }}>
                  {lifeInsurances?.length > 0
                    ? lifeInsurances.map((item) => {
                        return item?._id === emergencyInfo?.lifeInsurance
                          ? item?.name
                          : "";
                      })
                    : null}
                </div>
              </div>
            </ul>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <div className="card-box profile-box">
            <h3 className="card-title">
              Job Information{" "}
              <span
                className="edit-icon p-cursor"
                data-toggle="modal"
                data-target="#job_information_modal"
              >
                <i className="fa fa-pencil"></i>
              </span>
            </h3>
            <ul className="personal-info">
              <div style={{ display: "flex", padding: "4px 0px" }}>
                <div className="title" style={{ flex: 1 }}>
                  Job Title:
                </div>
                <div className="text" style={{ flex: 1 }}>
                  {jobInfo.title}
                </div>
              </div>
              <div style={{ display: "flex", padding: "4px 0px" }}>
                <div className="title" style={{ flex: 1 }}>
                  Employee ID:
                </div>
                <div className="text" style={{ flex: 1 }}>
                  {jobInfo?.changeableId}
                </div>
              </div>
              <div style={{ display: "flex", padding: "4px 0px" }}>
                <div className="title" style={{ flex: 1 }}>
                  Job Grade:
                </div>
                <div className="text" style={{ flex: 1 }}>
                  {jobGrades?.length > 0
                    ? jobGrades?.map((item) => {
                        return item?._id === jobInfo?.grade ? item?.name : "";
                      })
                    : null}
                </div>
              </div>

              <div style={{ display: "flex", padding: "4px 0px" }}>
                <div className="title" style={{ flex: 1 }}>
                  Contract:
                </div>
                <div className="text" style={{ flex: 1 }}>
                  {contracts?.length > 0
                    ? contracts?.map((item) => {
                        return item?._id === jobInfo?.contractType
                          ? item?.contractName
                          : "";
                      })
                    : null}
                </div>
              </div>

              <div style={{ display: "flex", padding: "4px 0px" }}>
                <div className="title" style={{ flex: 1 }}>
                  Supervisor name:
                </div>
                <div className="text" style={{ flex: 1 }}>
                  {supervisors?.length && jobInfo?.supervisor?.length > 0
                    ? supervisors?.map((item) => {
                        if (jobInfo?.supervisor.indexOf(item?.id) > -1)
                          return (
                            <span>
                              <a
                                href={`${item.id}`}
                                style={{
                                  textDecoration: "underline",
                                }}
                              >
                                {item?.name}
                              </a>
                              &nbsp;&nbsp;
                            </span>
                          );
                        else return <span></span>;
                      })
                    : null}
                </div>
              </div>

              <div style={{ display: "flex", padding: "4px 0px" }}>
                <div className="title" style={{ flex: 1 }}>
                  Supervisor email:
                </div>
                <div className="text" style={{ flex: 1 }}>
                  {supervisors?.length && jobInfo?.supervisor?.length > 0
                    ? supervisors?.map((item) => {
                        if (jobInfo?.supervisor.indexOf(item?.id) > -1)
                          return (
                            <span>
                              {item?.email}
                              &nbsp;&nbsp;
                            </span>
                          );
                        else return <span></span>;
                      })
                    : null}
                </div>
              </div>

              <div style={{ display: "flex", padding: "4px 0px" }}>
                <div className="title" style={{ flex: 1 }}>
                  Supervisor phone:
                </div>
                <div className="text" style={{ flex: 1 }}>
                  {supervisors?.length && jobInfo?.supervisor?.length > 0
                    ? supervisors?.map((item) => {
                        if (jobInfo?.supervisor.indexOf(item?.id) > -1)
                          return (
                            <span>
                              {item?.phone}
                              &nbsp;&nbsp;
                            </span>
                          );
                        else return <span></span>;
                      })
                    : null}
                </div>
              </div>

              <div style={{ display: "flex", padding: "4px 0px" }}>
                <div className="title" style={{ flex: 1 }}>
                  Department:
                </div>
                <div className="text" style={{ flex: 1 }}>
                  {departments?.length > 0
                    ? departments.map((item) => {
                        return item._id === jobInfo.department ? item.name : "";
                      })
                    : null}
                </div>
              </div>
              <div style={{ display: "flex", padding: "4px 0px" }}>
                <div className="title" style={{ flex: 1 }}>
                  Work Location:
                </div>
                <div className="text" style={{ flex: 1 }}>
                  {locations?.length > 0
                    ? locations.map((item) => {
                        return item._id === jobInfo.workLocation
                          ? item.name
                          : "";
                      })
                    : null}
                </div>
              </div>
              <div style={{ display: "flex", padding: "4px 0px" }}>
                <div className="title" style={{ flex: 1 }}>
                  Email:
                </div>
                <div className="text" style={{ flex: 1 }}>
                  {jobInfo.email}
                </div>
              </div>
              <div style={{ display: "flex", padding: "4px 0px" }}>
                <div className="title" style={{ flex: 1 }}>
                  Work Phone:
                </div>
                <div className="text" style={{ flex: 1 }}>
                  {jobInfo.workPhone}
                </div>
              </div>
              <div style={{ display: "flex", padding: "4px 0px" }}>
                <div className="title" style={{ flex: 1 }}>
                  Cell Phone:
                </div>
                <div className="text" style={{ flex: 1 }}>
                  {jobInfo.cellPhone}
                </div>
              </div>
              <div style={{ display: "flex", padding: "4px 0px" }}>
                <div className="title" style={{ flex: 1 }}>
                  Join Date:
                </div>
                <div className="text" style={{ flex: 1 }}>
                  {jobInfo?.joinDate
                    ? moment(jobInfo?.joinDate, "DD/MM/YYYY").format(
                        "DD MM YYYY"
                      )
                    : ""}
                </div>
              </div>
              <div style={{ display: "flex", padding: "4px 0px" }}>
                <div className="title" style={{ flex: 1 }}>
                  Start Date:
                </div>
                <div className="text" style={{ flex: 1 }}>
                  {jobInfo?.startDate
                    ? moment(jobInfo?.startDate, "DD/MM/YYYY").format(
                        "DD MMM YYYY"
                      )
                    : ""}
                </div>
              </div>
              <div style={{ display: "flex", padding: "4px 0px" }}>
                <div className="title" style={{ flex: 1 }}>
                  Salary:
                </div>
                <div className="text" style={{ flex: 1 }}>
                  {(jobInfo.salary
                    ? currency(jobInfo.salary, {
                        symbol: "",
                      }).format()
                    : "") +
                    " " +
                    (jobInfo.salaryBasis ? jobInfo.salaryBasis : "")}
                </div>
              </div>
              <div style={{ display: "flex", padding: "4px 0px" }}>
                <div className="title" style={{ flex: 1 }}>
                  Status:
                </div>
                <div className="text" style={{ flex: 1 }}>
                  {jobInfo.status}
                </div>
              </div>
            </ul>
          </div>
        </div>
        <div className="col-md-6">
          <div className="card-box profile-box">
            <h3 className="card-title">
              Education Information{" "}
              <span
                className="edit-icon p-cursor"
                data-toggle="modal"
                data-target="#education_info"
              >
                <i className="fa fa-pencil"></i>
              </span>
            </h3>
            <div className="experience-box">
              <ul className="experience-list">
                {educationInfo?.map((education) => {
                  return (
                    <li>
                      <div className="experience-user">
                        <div className="before-circle"></div>
                      </div>
                      <div className="experience-content">
                        <div className="timeline-content">
                          <a href="#/" className="name">
                            {education.institution}
                          </a>
                          <div>{education.subject}</div>
                          <div>Grade: {education.grade}</div>
                          <span className="time">
                            {education.startDate +
                              " - " +
                              education.completeDate}
                          </span>
                        </div>
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EmpProfile;
