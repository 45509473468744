import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Breadcrumb, BreadcrumbItem, Spinner } from "reactstrap";

import { toast } from "react-toastify";

import { Helmet } from "react-helmet";
import Topbar from "../topbar/Topbar";
import Sidebar from "../sidebar/Sidebar";
import { URLS } from "../urls";

import "../../../src/assets/css/ga-styles.css";
import TableComponent from "../TableComponent";
import ModalLoader from "../ModalLoader";
import { useCookie } from "../../utils";
import AddJobCategoryModal from "./AddJobCategory";
import EditJobCategoryModal from "./EditJobCategory";
import FoundEmployee from "./FoundEmployee";

function JobCategory() {
  const branch_id = useCookie("branch_id");

  const [loader, setLoader] = useState(false);
  const [jobCategories, setJobCategory] = useState([]);
  const [selectedJobCategory, setSelectedJobCategory] = useState({
    name: "",
    description: "",
  });
  const [showAddModal, setShowAddModal] = useState(false);
  const toggle = () => setShowAddModal(!showAddModal);

  const [foundModal, setFoundModal] = useState(false);
  const toggleFound = () => setFoundModal(!foundModal);

  const [showEditModal, setShowEditModal] = useState(false);
  const changeEditJobState = () => setShowEditModal(!showEditModal);

  const [found, setFound] = useState([]);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      fetchAll();
    }

    return () => {
      isMounted = false;
    };
  }, []);

  const fetchAll = () => {
    setLoading(true);
    const token = "dummy token";
    fetch(`${URLS.backendJobCategory}/get/${branch_id}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        setJobCategory([...result.jobCategories]);
        // console.log(result.jobCategories);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        toast("Unable to get employees category, please refresh your browser", {
          type: "error",
        });
      });
  };

  const changeShowJobState = () => {
    toggle();
  };
  const updateJobCategoriesList = (item) => {
    setJobCategory([...jobCategories, item]);
  };

  const handleEdit = (categoryId) => {
    setSelectedJobCategory(jobCategories.find((job) => job._id === categoryId));
    changeEditJobState();
  };

  const setUpDelete = (categoryId) => {
    setSelectedJobCategory(jobCategories.find((job) => job._id === categoryId));
    window.$("#delete_job_grade").modal("toggle");
  };

  const handleDeleteJobGrade = () => {
    window.$("#delete_job_grade").modal("toggle");
    setLoader(true);
    const token = "dummy token";
    fetch(`${URLS.backendJobCategory}/delete`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
      body: JSON.stringify({
        jobCategoryId: selectedJobCategory._id,
      }),
    })
      .then((res) => res.json())
      .then((result) => {
        console.log(result);
        if (result.success) {
          toast("Employees category deleted", {
            type: "success",
          });
          setJobCategory(
            jobCategories.filter((job) => job._id !== selectedJobCategory._id)
          );
        } else {
          if (result.found) {
            setFound(
              result.found.map((employee) => ({
                ...employee.userId,
                jobGradeId: employee.jobInfo.grade,
              }))
            );
            toggleFound();
          } else {
            throw Error();
          }
        }
      })
      .catch((err) => {
        toast("Employees category not deleted", {
          type: "error",
        });
      })
      .finally(() => {
        setLoader(false);
      });
  };

  const setJobGradeChange = (e) => {
    found[parseInt(e.target.name)].jobGradeId = e.target.value;

    setFound([...found]);
  };

  const submitChanges = (e) => {
    e.preventDefault();
    setLoader(true);
    const token = "dummy token";
    fetch(`${URLS.backendJobCategory}/update-user-category`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
      body: JSON.stringify({
        users: found,
        jobCategoryId: selectedJobCategory._id,
      }),
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.success) {
          toast("Employees category changed, and employees category deleted", {
            type: "success",
          });
          setJobCategory(
            jobCategories.filter((el) => el._id !== selectedJobCategory._id)
          );
          toggleFound();
        } else {
          throw Error;
        }
      })
      .catch((err) => {
        toast("Unable to update employees category", {
          type: "error",
        });
      })
      .finally(() => {
        setLoader(false);
      });
  };

  const tableHead = () => {
    return (
      <thead>
        <tr>
          <th>#</th>
          <th>Name</th>
          <th>Description</th>
          <th>Actions</th>
        </tr>
      </thead>
    );
  };

  const tableBodyData = (category, index) => {
    return (
      <>
        <td>{index + 1}</td>
        <td>{category?.name}</td>
        <td>{category?.description ?? "..."}</td>
        <th style={{ fontSize: "22px" }}>
          {loader && selectedJobCategory?._id === category?._id ? (
            <Spinner size="sm" color="primary" />
          ) : (
            <>
              <button
                className="mr-2 text-secondary"
                title="Edit"
                onClick={() => handleEdit(category?._id)}
              >
                <i className="la la-edit"></i>
              </button>

              <button
                className="text-danger"
                title="Delete"
                onClick={() => setUpDelete(category?._id)}
              >
                <i className="la la-trash"></i>
              </button>
            </>
          )}
        </th>
      </>
    );
  };

  return (
    <body>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="robots" content="noindex, nofollow"></meta>
        <title>InvexERP</title>
      </Helmet>
      <div className="main-wrapper">
        <Topbar />
        <Sidebar />
        {/*<!-- Page Wrapper -->*/}
        <div className="page-wrapper">
          {/*<!-- Page Content -->*/}
          <div className="content container-fluid">
            {/*<!-- Page Title -->*/}
            <div className="row">
              <div className="col-md-9 col-sm-8 text-capitalize">
                <Breadcrumb>
                  <BreadcrumbItem>
                    <Link to={`${URLS.dashboard}`}>Dashboard</Link>
                  </BreadcrumbItem>
                  <BreadcrumbItem active>Employees category</BreadcrumbItem>
                </Breadcrumb>
              </div>
              <div className="col-md-3 col-sm-4 text-right m-b-30">
                <button
                  className="btn add-btn"
                  data-toggle="modal"
                  onClick={changeShowJobState}
                >
                  <i className="fa fa-plus"></i> Add Employees category
                </button>
              </div>
              {/*<div className="col-sm-7 col-7 text-right m-b-30"></div>*/}
            </div>
            {/*<!-- Content Starts -->*/}
            <div className="row">
              <div className="col-md-12">
                {/* {jobGrades?.length > 0 ? ( */}
                <TableComponent
                  responsive
                  borderless
                  striped
                  tableHeadsFunction={tableHead}
                  mainDataArray={jobCategories}
                  tableDataRowFunction={tableBodyData}
                  className="product-table text-nowrap"
                />
                {/* // ) : ( */}
                <ModalLoader show={loading} />
                {/* // )} */}

                {/* </div> */}
              </div>
            </div>
            {/*<!-- /Content End -->*/}
          </div>
          {/*<!-- /Page Content -->*/}
          <div>
            {showAddModal && (
              <AddJobCategoryModal
                updateJobCategoryList={updateJobCategoriesList}
                isShow={showAddModal}
                changeShowJobState={changeShowJobState}
                refetch={fetchAll}
              />
            )}

            {showEditModal && (
              <EditJobCategoryModal
                key={selectedJobCategory._id}
                isShow={showEditModal}
                changeEditJobState={changeEditJobState}
                setLoader={setLoader}
                refetch={fetchAll}
                id={selectedJobCategory._id}
              />
            )}

            {/*<!-- Delete Employee Modal -->*/}
            <div
              className="modal custom-modal fade"
              id="delete_job_grade"
              role="dialog"
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-body">
                    <div className="form-header">
                      <h3>Delete Employees category</h3>
                      <p>Are you sure want to delete?</p>
                    </div>
                    <div className="modal-btn delete-action">
                      <div className="row d-flex align-items-center justify-content-center">
                        <div className="">
                          <button
                            className="btn dlt-btn continue-btn"
                            onClick={handleDeleteJobGrade}
                          >
                            Delete
                          </button>
                        </div>
                        <div className="">
                          <button
                            data-dismiss="modal"
                            className=" text-white cncl-btn cancel-btn"
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*<!-- /Found Employee Modal -->*/}

            {foundModal && (
              <FoundEmployee
                loader={loader}
                foundModal={foundModal}
                toggleFound={toggleFound}
                selectedJobCategory={selectedJobCategory}
                submitChanges={submitChanges}
                found={found}
                setJobGradeChange={setJobGradeChange}
                jobCategories={jobCategories}
              />
            )}
          </div>
        </div>
        {/*<!-- /Page Wrapper -->*/}
      </div>
      {/*<!-- /Main Wrapper -->*/} {/* <!-- Sidebar Overlay --> */}
      <div className="sidebar-overlay" data-reff=""></div>
    </body>
  );
}

export default JobCategory;
