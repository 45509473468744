import React, { useEffect, useState } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import Select from "react-select";
import { read, utils } from "xlsx";
import { ImportIcon } from "../icons";
import Cookies from "universal-cookie";
import { fetchActionsUtil, useCookie } from "../../utils";
import { URLS } from "../urls";
import { toast } from "react-toastify";
import ModalLoader from "../ModalLoader";
import moment from "moment";

export const ImportEmployees = ({ show, handleClose, refresh }) => {
  const cookies = new Cookies();
  const company_id = cookies.get("company_id");
  const branch_id = useCookie("branch_id");
  const token = "dummy token";

  const [isLoading, setIsLoading] = useState(false);
  const [state, setState] = useState({
    grades: [],
    department: [],
    category: [],
  });

  const [gradeId, setGradeId] = useState("");
  const [departmentId, setDepartmentId] = useState("");
  const [categoryId, setCategoryId] = useState("");
  const [employees, setEmployees] = useState([]);
  const [savedEmployees, setSavedEmployees] = useState([]);
  const [departmentName, setDepartmentName] = useState("");

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const token = "dummy token";

      const gradesData = fetchActionsUtil(
        `${URLS.backendJobGrades}/get/${branch_id}`,
        "GET",
        token
      );

      const departmentsData = fetchActionsUtil(
        `${URLS.backendDepartments}/get/${branch_id}`,
        "GET",
        token
      );

      const categoryData = fetchActionsUtil(
        `${URLS.backendJobCategory}/get/${branch_id}`,
        "GET",
        token
      );

      const [gradesResult, departmentsResult, categoryResult] =
        await Promise.all([gradesData, departmentsData, categoryData]);

      const grades = gradesResult?.jobGrades?.map((el) => ({
        value: el?._id,
        label: el?.name,
      }));
      const department = departmentsResult?.departments?.map((el) => ({
        value: el?._id,
        label: el?.name,
      }));

      const category = categoryResult?.jobCategories?.map((el) => ({
        value: el?._id,
        label: el?.name,
      }));

      setState({
        grades,
        department,
        category,
      });
    } catch (error) {
      console.log(error);
      toast.error("Fetching Error !!!");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      fetchData();
    }

    return () => {
      isMounted = false;
    };
  }, []);

  async function handleFile({ eventData: e }) {
    if (gradeId === "" || departmentId === "") {
      return toast.error("Please select grade and department");
    }

    setIsLoading(true);

    const file = e?.target?.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = async function (e) {
        try {
          const workbook = read(e.target.result, {
            cellDates: true,
            dateNF: "yyyy-MM-dd HH:mm:ss.SSS",
          });

          let rows = utils.sheet_to_json(
            workbook.Sheets[workbook.SheetNames[0]],
            {
              defval: "",
              dateNF: "yyyy-MM-dd HH:mm:ss.SSS",
            }
          );
          // console.log("excel", rows);

          if (rows?.length > 0) {
            const employees = rows?.map((el) => {
              let supers = `${el?.supervisor_employeeID}`
                .split(",")
                .filter((el) => el !== "");

              const min = 2;
              const max = 4;
              const random = Math.floor(Math.random() * (max - min + 1)) + min;

              const username = el?.jobInfo_email
                ? `${el?.jobInfo_email?.split("@")[0]}${random}`
                : "";

              return {
                branch: branch_id,
                supervisor: supers?.length > 0 ? supers : [],
                department: departmentName,
                isLineManager: el?.isLineManager == "1" ? true : false,
                personalInfo: {
                  name: {
                    first: el?.personalInfo_name_first,
                    last: el?.personalInfo_name_last,
                    middle: el?.personalInfo_name_middle,
                  },
                  address: {
                    apartment: el?.personalInfo_address_apartment,
                    street: el?.personalInfo_address_street,
                    city: el?.personalInfo_address_city,
                    state: el?.personalInfo_address_state,
                    zip: el?.personalInfo_address_zip,
                  },
                  phone: {
                    home: el?.personalInfo_phone_home,
                    alternate: el?.personalInfo_phone_alternate,
                  },
                  email: el?.personalInfo_email,
                  birth: moment(
                    el?.personalInfo_birth,
                    "yyyy-MM-dd HH:mm:ss.SSS"
                  ).format("DD/MM/YYYY"),
                  isAdmin: el?.admin == "1" ? true : false,
                  isSuperAdmin: el?.admin == "1" ? true : false,
                  grantAccess: el?.admin == "1" ? true : false,
                  govtId: el?.personalInfo_govtId,
                  gender:
                    el?.personalInfo_gender?.charAt(0).toUpperCase() === "M"
                      ? "Male"
                      : el?.personalInfo_gender?.charAt(0).toUpperCase() === "F"
                      ? "Female"
                      : "",
                  martial: el?.personalInfo_martial,
                  govtType: e?.personalInfo_govtType,
                  spouse: {
                    name: el?.personalInfo_spouse_name,
                    employer: el?.personalInfo_spouse_employer,
                    phone: el?.personalInfo_spouse_phone,
                  },
                  prevEmployee: {
                    employee: "",
                    phone: "",
                  },
                },
                jobInfo: {
                  title: el?.jobInfo_title,
                  category: categoryId,
                  employeeId: el?.jobInfo_employeeId,
                  changeableId: el?.jobInfo_employeeId,
                  grade: gradeId,
                  company_id: company_id ? company_id : "",
                  supervisor: [],
                  department: departmentId,
                  workLocation: null,
                  email: el?.jobInfo_email,
                  // password: `${el?.jobInfo_password}`,
                  workPhone: el?.jobInfo_workPhone,
                  cellPhone: el?.jobInfo_cellPhone,
                  startDate: moment(
                    el?.jobInfo_startDate,
                    "yyyy-MM-dd HH:mm:ss.SSS"
                  ).format("DD/MM/YYYY"),
                  joinDate: moment(
                    el?.jobInfo_confirmDate,
                    "yyyy-MM-dd HH:mm:ss.SSS"
                  ).format("DD/MM/YYYY"),
                  salary: el?.jobInfo_salary,
                  status: "",
                  salaryBasis: "",
                  contract: false,
                  contractType: "",
                  sfa_id: el?.jobInfo_sfa_id,
                  workLocationBranch: null,
                  confirmDate: moment(
                    el?.jobInfo_confirmDate,
                    "yyyy-MM-dd HH:mm:ss.SSS"
                  ).format("DD/MM/YYYY"),
                  state: el?.jobInfo_state,
                },
                emergencyInfo: {
                  name: {
                    first: el?.emergencyInfo_name_first,
                    last: el?.emergencyInfo_name_last,
                    middle: el?.emergencyInfo_name_middle,
                  },
                  address: {
                    apartment: el?.emergencyInfo_address_apartment,
                    street: el?.emergencyInfo_address_street,
                    city: el?.emergencyInfo_address_city,
                    state: el?.emergencyInfo_address_state,
                    zip: el?.emergencyInfo_address_zip,
                  },
                  phone: {
                    primary: el?.emergencyInfo_phone_primary,
                    secondary: el?.emergencyInfo_phone_secondary,
                  },
                  relation: el?.emergencyInfo_relation,
                  guarantor: {
                    title: el?.emergencyInfo_guarantor_title,
                    name: el?.emergencyInfo_guarantor_name,
                    phone: el?.emergencyInfo_guarantor_phone,
                    address: el?.emergencyInfo_guarantor_address,
                  },
                  referee: {
                    title: el?.emergencyInfo_referee_title,
                    name: el?.emergencyInfo_referee_name,
                    phone: el?.emergencyInfo_referee_phone,
                    address: el?.emergencyInfo_referee_address,
                  },
                  employeeHMO: null,
                  lifeInsurance: null,
                  image: "",
                  resume: "",
                  certificate: "",
                },
                educationInfo: [
                  {
                    institution: "",
                    subject: "",
                    startDate: "",
                    completeDate: "",
                    degree: "",
                    grade: "",
                    certificate: "",
                  },
                ],
                educationCertificateFiles: [
                  {
                    file: null,
                  },
                ],
                user: {
                  name: {
                    first: el?.personalInfo_name_first,
                    last: el?.personalInfo_name_last,
                    middle: el?.personalInfo_name_middle,
                  },
                  username: username,
                  email: el?.jobInfo_email || "",
                  password: `${el?.jobInfo_password}` || "",
                  isAdmin: el?.admin == "1" ? true : false,
                  isSuperAdmin: el?.admin == "1" ? true : false,
                  company_id: company_id,
                  department_id: departmentId,
                  grantAccess: el?.admin == "1" ? true : false,
                  branch: branch_id,
                  workEmail: el?.jobInfo_email,
                  gender:
                    el?.personalInfo_gender?.charAt(0).toUpperCase() === "M"
                      ? "Male"
                      : el?.personalInfo_gender?.charAt(0).toUpperCase() === "F"
                      ? "Female"
                      : "",
                  Staff_ID: el?.jobInfo_employeeId,
                },

                bankDetails: {
                  bankName: el?.bankDetails_bankName,
                  accountNumber: el?.bankDetails_accountNumber,
                  accountType: el?.bankDetails_accountType,
                  accountOfficer: el?.bankDetails_accountOfficer,
                  bankCode: el?.bankDetails_bankCode,
                  pensionNumber: el?.bankDetails_pensionNumber,
                  pensionAdmin: el?.bankDetails_pensionAdmin,
                },
              };
            });
            // console.log(employees);

            setEmployees(employees);

            const savedEmployee = employees?.filter(
              (item) =>
                item.user.email !== "" &&
                item.user.password !== "" &&
                item.jobInfo.employeeId !== "" &&
                item.jobInfo.email !== ""
            );
            setSavedEmployees(savedEmployee);
          }
        } catch (err) {
          console.log(err);
        } finally {
          setIsLoading(false);
        }
      };

      reader.readAsArrayBuffer(file);
      e.target.value = "";
    }
  }

  const saveEmployees = async () => {
    try {
      setIsLoading(true);
      if (savedEmployees.length > 0) {
        const { success } = await fetchActionsUtil(
          `${URLS.backendEmployees}/multiple-add`,
          "POST",
          token,
          {
            employees: savedEmployees,
          }
        );
        if (success) {
          refresh();
          toast.success("Added successfully");
          handleClose();
        }
      }
    } catch (error) {
      toast.error(error?.message);

      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Import Employees</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="mb-4">
          <Row>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>
                  Select Job Grade<span className="text-danger">*</span>
                </Form.Label>
                <Select
                  closeMenuOnSelect={true}
                  isSearchable
                  required
                  classNamePrefix="form-control"
                  value={state?.grades?.find((el) => el.value === gradeId)}
                  onChange={(selected) => {
                    setGradeId(selected?.value || "");
                  }}
                  options={state?.grades}
                  isClearable
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>
                  Select Department<span className="text-danger">*</span>
                </Form.Label>
                <Select
                  closeMenuOnSelect={true}
                  isSearchable
                  required
                  classNamePrefix="form-control"
                  value={state.department?.find(
                    (el) => el.value === departmentId
                  )}
                  onChange={(selected) => {
                    setDepartmentId(selected?.value || "");
                    setDepartmentName(selected?.label || "");
                  }}
                  options={state.department}
                  isClearable
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>Select Category</Form.Label>
                <Select
                  closeMenuOnSelect={true}
                  isSearchable
                  // required
                  classNamePrefix="form-control"
                  value={state.category?.find((el) => el.value === categoryId)}
                  onChange={(selected) => {
                    setCategoryId(selected?.value || "");
                  }}
                  options={state.category}
                  isClearable
                />
              </Form.Group>
            </Col>
          </Row>
          {gradeId !== "" && departmentId !== "" ? (
            <div className="d-flex justify-content-center mt-3">
              <label className="btn btn-outline-primary mx-3 p-cursor">
                <ImportIcon />
                <span className="mx-2" style={{ color: "#2463AE" }}>
                  Import
                </span>
                <input
                  type="file"
                  className="d-none"
                  onChange={(e) => {
                    handleFile({
                      eventData: e,
                    });
                  }}
                  accept=".xlsx"
                />
              </label>
            </div>
          ) : null}
        </div>
      </Modal.Body>
      <div className="my-3">
        {employees.length > 0 ? (
          <div className="d-flex justify-content-center">
            <div>
              <div>
                <p>{employees.length} employees detected</p>
              </div>
              <div>
                {savedEmployees.length !== employees.length ? (
                  <p className="text-danger">
                    {employees.length - savedEmployees.length} employee does not
                    have the required fields
                  </p>
                ) : null}
              </div>
            </div>
          </div>
        ) : null}
      </div>

      {employees.length > 0 ? (
        <>
          {employees.length === savedEmployees.length ? (
            <div className="d-flex justify-content-between my-3 mx-4">
              <div>
                <button className="btn btn-secondary" onClick={handleClose}>
                  Cancel
                </button>
              </div>
              <div>
                <button className="btn btn-primary" onClick={saveEmployees}>
                  Save
                </button>
              </div>
            </div>
          ) : null}
        </>
      ) : null}
      <ModalLoader show={isLoading} />
    </Modal>
  );
};
