import React from "react";
import { URLS } from "../urls";
import { toast } from "react-toastify";
import { Modal } from "react-bootstrap";

export const DeleteSuspension = ({
  suspensionIdToDelete,
  suspensions,
  setSuspensions,
  show,
  handleClose,
}) => {
  const deleteSuspension = () => {
    const token = "dummy token";
    fetch(`${URLS.backendSuspension}/delete`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
      body: JSON.stringify({ suspensionId: suspensionIdToDelete }),
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.success) {
          const removedDeleted = suspensions.filter(
            (resign) => resign._id !== suspensionIdToDelete
          );
          setSuspensions([...removedDeleted]);
          toast("Suspension deleted", {
            type: "success",
          });
          handleClose();
        } else {
          throw Error();
        }
      })
      .catch((err) => {
        console.log(err);
        toast("Unable to delete suspension", {
          type: "error",
        });
      });
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      aria-labelledby=""
      centered
      size="md"
    >
      <Modal.Header closeButton>
        <Modal.Title>Delete Suspension</Modal.Title>
      </Modal.Header>
      <div className="modal-body">
        <div className="form-header">
          <h3>Delete Suspension</h3>
          <p>Are you sure want to delete?</p>
        </div>
        <div className="d-flex justify-content-center">
          <div className="row">
            <div className="col-6">
              <button
                className="btn btn-primary continue-btn"
                onClick={() => {
                  deleteSuspension();
                }}
              >
                Delete
              </button>
            </div>
            <div className="col-6">
              <button
                onClick={() => handleClose()}
                className="btn btn-primary cancel-btn"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};
