import React from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
} from "chart.js";

ChartJS.register(LineElement, CategoryScale, LinearScale, PointElement);

const WaveChart = () => {
  const gradient = (ctx) => {
    const { chart } = ctx; 
    const { ctx: chartCtx, width, height } = chart; 
    const gradient = chartCtx.createLinearGradient(0, 0, 0, height);
    gradient.addColorStop(0, "rgb(211, 178, 115)");
    gradient.addColorStop(0.5, "rgb(220, 195, 148)");
    gradient.addColorStop(1, "#FFFFFF");
    return gradient;
  };

  const data = {
    labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul"],
    datasets: [
      {
        label: "Wave Chart",
        data: [5, 10, 15, 20, 15, 10, 5],
        borderColor: "#FFC90A",
        backgroundColor: (context) => gradient(context), 
        borderWidth: 2,
        fill: true,
        tension: 0.4,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        grid: { display: false },
        ticks: { color: "#888" },
      },
      y: {
        grid: { display: true, color: "#eaeaea" },
        ticks: { color: "#888" },
      },
    },
  };

  return (
    <div style={{ height: "300px", width: "100%" }}>
      <Line data={data} options={options} />
    </div>
  );
};

export default WaveChart;