import React from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip, Legend);

const BarChart = () => {
  const data = {
    labels: ["Group 1", "Group 2", "Group 3"], // Replace with your actual group labels
    datasets: [
      {
        label: "Total goals set",
        data: [3, 5, 2], // Randomized heights for Dataset A
        backgroundColor: "#2463ae", // Updated color for Dataset A
        borderRadius: 30, // Round the tops
        barPercentage: 0.8, // Thinner bars
        categoryPercentage: 0.2, // Closer groups
      },
      {
        label: "Total goals completed",
        data: [4, 3, 5], // Randomized heights for Dataset B
        backgroundColor: "#17e28c", // Updated color for Dataset B
        borderRadius: 30, // Round the tops
        barPercentage: 0.8, // Thinner bars
        categoryPercentage: 0.2, // Closer groups
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
        labels: {
          color: "#555",
          font: {
            size: 14,
          },
        },
      },
      tooltip: {
        callbacks: {
          label: (context) => `${context.dataset.label}: ${context.raw}`, // Example: "Total goals set: 2"
        },
      },
    },
    scales: {
      x: {
        stacked: false,
        ticks: {
          color: "#555",
        },
        grid: {
          display: false, // Hide grid lines for the X-axis
        },
      },
      y: {
        ticks: {
          color: "#555",
        },
        grid: {
          color: "#eee", // Light grid lines for the Y-axis
        },
        beginAtZero: true,
      },
    },
  };

  return (
    <div style={{ width: "100%", height: "500px", margin: "auto" }}> {/* Increased height */}
      <Bar data={data} options={options} />
    </div>
  );
};

export default BarChart;
