import React, { createRef } from "react";
import { Form, Col, Row, Image, Modal } from "react-bootstrap";
// import $ from 'jquery';
import { pdfjs, Document, Page } from "react-pdf";
import { toast } from "react-toastify";
import Cookies from "universal-cookie";
import CurrencyInput from "react-currency-input-field";
import Select from "react-select";

import "../../assets/css/employee.css";
import "../../../src/assets/css/date-picker.css";
import { URLS } from "../urls";
import AddJobGradeModal from "./AddJobGradeModal";
import AddLocationModal from "./AddLocationModal";
import AddEmployeeHMOModal from "./AddEmployeeHMOModal";
import AddLifeInsuranceModal from "./AddLifeInsuranceModal";
import Datepicker from "react-datetime"; //'react-bootstrap-datetimepicker';
import moment from "moment";
import Multiselect from "./Multiselect";
import AddEmployeeDepartmentModal from "./AddEmployeeDepartment";
import { branch_id } from "../../utils";
import AddJobCategoryModal from "../job-category/AddJobCategory";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export default class EmployeeModal extends React.Component {
  constructor(props) {
    super(props);

    const cookies = new Cookies();
    const company_id = cookies.get("company_id");

    // this.handleChange = this.handleChange.bind(this);
    this.handleEducationInfoField = this.handleEducationInfoField.bind(this);
    this.addMoreEducationInfo = this.addMoreEducationInfo.bind(this);
    this.updatePersonalGender = this.updatePersonalGender.bind(this);
    this.updateJobGradesList = this.updateJobGradesList.bind(this);

    this.state = {
      show_add_job: false,
      show_add_jobCategory: false,
      show_add_location: false,
      show_add_employeeHMO: false,
      show_add_lifeInsurance: false,
      show_add_department: false,
      displayImageSrc: null,
      displayResumeSrc: null,
      pdfPageNumber: 1,
      buttonText: "Submit",
      isWaiting: false,
      grantAccess: true,
      showBranch: false,
      branch: "",
      branches: [],
      branchesLocations: [],
      isLineManager: false,
      personalInfo: {
        name: {
          first: "",
          last: "",
          middle: "",
        },
        address: {
          apartment: "",
          street: "",
          city: "",
          state: "",
          zip: "",
        },
        phone: {
          home: "",
          alternate: "",
        },
        email: "",
        birth: "",
        isAdmin: false,
        grantAccess: false,
        govtId: "",
        gender: "Male",
        martial: "Single",
        govtType: "",
        spouse: {
          name: "",
          employer: "",
          phone: "",
        },
        prevEmployee: {
          employee: "",
          phone: "",
        },
      },
      jobInfo: {
        title: "",
        employeeId: "",
        changeableId: "",
        grade: null,
        company_id: company_id ? company_id : "",
        supervisor: [],
        department: null,
        workLocation: null,
        email: "",
        // password: "",
        workPhone: "",
        cellPhone: "",
        startDate: "",
        joinDate: moment(new Date()).format("DD/MM/YYYY"),
        salary: "",
        status: "",
        salaryBasis: "",
        contract: false,
        contractType: "",
        sfa_id: "",
        workLocationBranch: null,
        confirmDate: "",
        state: "",
        category: "",
      },
      emergencyInfo: {
        name: {
          first: "",
          last: "",
          middle: "",
        },
        address: {
          apartment: "",
          street: "",
          city: "",
          state: "",
          zip: "",
        },
        phone: {
          primary: "",
          secondary: "",
        },
        relation: "",
        guarantor: {
          title: "",
          name: "",
          phone: "",
          address: "",
        },
        referee: {
          title: "",
          name: "",
          phone: "",
          address: "",
        },
        employeeHMO: null,
        lifeInsurance: null,
        image: "",
        resume: "",
        certificate: "",
      },
      educationInfo: [
        {
          institution: "",
          subject: "",
          startDate: "",
          completeDate: "",
          degree: "",
          grade: "",
          certificate: "",
        },
      ],
      jobGrades: [],
      jobCategories: [],
      locations: [],
      departments: [],
      employeeHMOs: [],
      lifeInsurances: [],
      educationCertificateFiles: [
        {
          file: null,
        },
      ],
      supervisors: [],
      projects: [],
      companies: [],
      user: {
        name: {
          first: "",
          last: "",
        },
        username: "",
        email: "",
        password: "",
        isAdmin: false,
        company_id: company_id,
        department_id: "",
        grantAccess: false,
      },

      bankDetails: {
        bankName: "",
        accountNumber: "",
        accountType: "",
        accountOfficer: "",
        bankCode: "",
        pensionNumber: "",
        pensionAdmin: "",
      },
    };
  }
  result(params) {
    console.log(params);
  }

  validationEndDate = (currentDate) => {
    const { jobInfo } = this.state;
    return currentDate.isAfter(
      moment(jobInfo.joinDate, "DD/MM/YYYY").add(-1, "days")
    );
  };

  componentWillMount() {
    const token = "dummy token";
    fetch(`${URLS.backendJobGrades}/get/${branch_id}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        this.setState({
          jobGrades: result.jobGrades,
        });
      });
    fetch(`${URLS.backendJobCategory}/get/${branch_id}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        this.setState({
          jobCategories: result.jobCategories,
        });
      });
    //
    fetch(`${URLS.backendDepartments}/get/${branch_id}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        this.setState({
          departments: result.departments,
        });
      });

    fetch(`${URLS.backendEmployeeHMOs}/get`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        this.setState({
          employeeHMOs: result.employeeHMOs,
        });
      });

    fetch(`${URLS.backendLifeInsurances}/get`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        this.setState({
          lifeInsurances: result.lifeInsurances,
        });
      });

    fetch(`${URLS.backendLocations}/get`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        this.setState({
          locations: result.locations,
        });
      });
    fetch(`${URLS.backendEmployees}/supervisors`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.supervisors) {
          this.setState({
            supervisors: result.supervisors,
          });
        }
      });
    fetch(`${URLS.backendContracts}/get/${branch_id}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        this.setState({
          projects: result.contracts,
        });
      });

    fetch(`${URLS.backendCompanies}/get`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.companies) {
          this.setState({
            companies: result.companies,
          });
        }
      });

    fetch(`${URLS.backendBranch}/get`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        result = result.branches.map((el) => ({
          value: el._id,
          label: el.name,
        }));
        this.setState({
          branches: result,
        });
      });

    const cookie = new Cookies();

    this.setState({
      showBranch: cookie.get("isSuperAdmin"),
      branch: cookie.get("branch_id"),
    });

    this.setState({
      personalInfo: {
        name: {
          first: "",
          last: "",
          middle: "",
        },
        address: {
          apartment: "",
          street: "",
          city: "",
          state: "",
          zip: "",
        },
        phone: {
          home: "",
          alternate: "",
        },
        email: "",
        birth: "",
        isAdmin: false,
        grantAccess: false,
        govtId: "",
        gender: "Male",
        martial: "Single",
        govtType: "",
        spouse: {
          name: "",
          employer: "",
          phone: "",
        },
        prevEmployee: {
          employee: "",
          phone: "",
        },
      },
      jobInfo: {
        title: "",
        employeeId: "",
        grade: null,
        changeableId: "",
        supervisor: [],
        department: null,
        workLocation: null,
        email: "",
        // password: "",
        workPhone: "",
        cellPhone: "",
        startDate: "",
        joinDate: moment(new Date()).format("DD/MM/YYYY"),
        salary: "",
        status: "",
        salaryBasis: "",
        contract: false,
        contractType: "",
        confirmDate: "",
      },
      emergencyInfo: {
        name: {
          first: "",
          last: "",
          middle: "",
        },
        address: {
          apartment: "",
          street: "",
          city: "",
          state: "",
          zip: "",
        },
        phone: {
          primary: "",
          secondary: "",
        },
        relation: "",
        guarantor: {
          title: "",
          name: "",
          phone: "",
          address: "",
        },
        referee: {
          title: "",
          name: "",
          phone: "",
          address: "",
        },
        employeeHMO: null,
        lifeInsurance: null,
        image: "",
        resume: "",
        certificate: "",
      },
      educationInfo: [
        {
          institution: "",
          subject: "",
          startDate: "",
          completeDate: "",
          degree: "",
          grade: "",
          certificate: "",
        },
      ],
      educationCertificateFiles: [
        {
          file: null,
        },
      ],
      supervisors: [],
      projects: [],
      companies: [],
      user: {
        name: {
          first: "",
          last: "",
        },
        username: "",
        email: "",
        password: "",
        isAdmin: false,
        // company_id: company_id,
        department_id: "",
        grantAccess: false,
      },

      bankDetails: {
        bankName: "",
        accountNumber: "",
        accountType: "",
        accountOfficer: "",
        bankCode: "",
        pensionNumber: "",
        pensionAdmin: "",
      },
    });
  }

  updateUserBranch = (branch) => {
    this.setState({
      branch,
    });
  };

  handleEducationInfoField = (idx) => (evt) => {
    const newEducationInfo = this.state.educationInfo.map((info, eIdx) => {
      if (idx !== eIdx) return info;
      let tName = evt.target.name;
      const value = evt.target.value;
      return { ...info, [tName]: value };
    });
    this.setState({ educationInfo: newEducationInfo });
  };

  handleEducationInfoCompleteField = (idx) => (evt) => {
    const newEducationInfo = this.state.educationInfo.map((info, eIdx) => {
      if (idx !== eIdx) return info;
      let tName = "completeDate";
      const value = evt.target
        ? evt.target.value
        : moment(evt).format("DD/MM/YYYY");
      return { ...info, [tName]: value };
    });
    this.setState({ educationInfo: newEducationInfo });
  };

  handleEducationInfoStartField = (idx) => (evt) => {
    const newEducationInfo = this.state.educationInfo.map((info, eIdx) => {
      if (idx !== eIdx) return info;
      let tName = "startDate";
      const value = evt.target
        ? evt.target.value
        : moment(evt).format("DD/MM/YYYY");
      return { ...info, [tName]: value };
    });
    this.setState({ educationInfo: newEducationInfo });
  };

  handleEducationInfoFileField = (idx) => (evt) => {
    const refName = "certificate_" + idx;
    const file = this.refs[refName].files[0]
      ? this.refs[refName].files[0]
      : null;
    if (file !== null) {
      const { educationInfo, educationCertificateFiles } = this.state;
      let currentDate = new Date();
      currentDate = currentDate.getTime();
      educationInfo[idx].certificate = currentDate + ".pdf";
      educationCertificateFiles[idx].file = file;
      this.setState({
        educationInfo: educationInfo,
        educationCertificateFiles: educationCertificateFiles,
      });
    }
  };

  addMoreEducationInfo() {
    this.setState({
      educationInfo: this.state.educationInfo.concat({
        institution: "",
        subject: "",
        startDate: "",
        completeDate: "",
        degree: "",
        grade: "",
        certificate: "",
      }),
    });
    this.setState({
      educationCertificateFiles: this.state.educationCertificateFiles.concat({
        file: null,
      }),
    });
  }

  // handleChange(event) {
  //     const eventType = event.target.type;
  //     if (eventType === 'file') {
  //         let file = this.refs.emergency_image.files[0];
  //         let reader = new FileReader();
  //         let url = reader.readAsDataURL(file);
  //         reader.onloadend = function () {
  //             this.setState({
  //                 displayImageSrc: [reader.result]
  //             })
  //         }.bind(this);
  //     }
  //     // }else if(eventType === 'checkbox'){
  //     //     this.setState({
  //     //         isContract: !this.state.isContract,
  //     //     });
  //     // }
  // }
  changeShowJobState = () => {
    const { show_add_job } = this.state;
    this.setState({
      show_add_job: !show_add_job,
    });
  };

  changeShowJobCategoryState = () => {
    const { show_add_jobCategory } = this.state;
    this.setState({
      show_add_jobCategory: !show_add_jobCategory,
    });
  };

  changeShowLocationState = () => {
    const { show_add_location } = this.state;
    this.setState({
      show_add_location: !show_add_location,
    });
  };

  changeShowEmployeeDepartment = () => {
    const { show_add_department } = this.state;
    this.setState({
      show_add_department: !show_add_department,
    });
  };

  changeShowEmployeeHMOState = () => {
    const { show_add_employeeHMO } = this.state;
    this.setState({
      show_add_employeeHMO: !show_add_employeeHMO,
    });
  };
  changeShowLifeInsuranceState = () => {
    const { show_add_lifeInsurance } = this.state;
    this.setState({
      show_add_lifeInsurance: !show_add_lifeInsurance,
    });
  };
  updateJobGradesList = (item) => {
    const { jobGrades, jobInfo } = this.state;
    jobGrades.push(item);
    jobInfo.grade = item._id;
    this.setState({
      jobGrades,
      jobInfo,
    });
  };

  updateJobCategoryList = (item) => {
    const { jobInfo, jobCategories } = this.state;
    jobCategories.push(item);
    jobInfo.category = item._id;
    this.setState({
      jobInfo,
      jobCategories,
    });

    this.updateIsLineMangerToTrue(item?.name);
  };

  updateIsLineMangerToTrue = (item) => {
    let { isLineManager } = this.state;

    if (item?.toLowerCase() === "expatriate") {
      isLineManager = true;
    }

    this.setState({
      isLineManager,
    });
  };

  updateLocationsList = (item) => {
    const { locations, jobInfo } = this.state;
    locations.push(item);
    jobInfo.workLocation = item._id;
    const bra = item.branches;
    this.setState({
      locations,
      branchesLocations: bra,
    });
  };

  updateDepartmentsList = (item) => {
    const { departments, jobInfo } = this.state;
    departments.push(item);
    jobInfo.department = item._id;
    this.setState({
      departments,
      jobInfo,
    });
  };

  updateEmployeeHMOsList = (item) => {
    const { employeeHMOs, emergencyInfo } = this.state;
    employeeHMOs.push(item);
    emergencyInfo.employeeHMO = item._id;
    this.setState({
      employeeHMOs,
      emergencyInfo,
    });
  };
  updateLifeInsurancesList = (item) => {
    const { lifeInsurances, emergencyInfo } = this.state;
    lifeInsurances.push(item);
    emergencyInfo.lifeInsurance = item._id;
    this.setState({
      lifeInsurances,
      emergencyInfo,
    });
  };
  updatePersonalInfoFirstName = (event) => {
    const { personalInfo, user } = this.state;
    personalInfo.name.first = event.target.value;
    user.name.first = event.target.value;
    this.setState({
      personalInfo,
      user,
    });
  };
  updatePersonalInfoLastName = (event) => {
    const { personalInfo, user } = this.state;
    personalInfo.name.last = event.target.value;
    user.name.last = event.target.value;
    this.setState({
      personalInfo,
      user,
    });
  };
  updatePersonalInfoMiddleName = (event) => {
    const { personalInfo } = this.state;
    personalInfo.name.middle = event.target.value;
    this.setState({
      personalInfo,
    });
  };
  updatePersonalInfoAddressApartment = (event) => {
    const { personalInfo } = this.state;
    personalInfo.address.apartment = event.target.value;
    this.setState({
      personalInfo,
    });
  };
  updatePersonalInfoAddressStreet = (event) => {
    const { personalInfo } = this.state;
    personalInfo.address.street = event.target.value;
    this.setState({
      personalInfo,
    });
  };
  updatePersonalInfoAddressCity = (event) => {
    const { personalInfo } = this.state;
    personalInfo.address.city = event.target.value;
    this.setState({
      personalInfo,
    });
  };
  updatePersonalInfoAddressState = (event) => {
    const { personalInfo } = this.state;
    personalInfo.address.state = event.target.value;
    this.setState({
      personalInfo,
    });
  };
  updatePersonalInfoAddressZip = (event) => {
    const { personalInfo } = this.state;
    personalInfo.address.zip = event.target.value;
    this.setState({
      personalInfo,
    });
  };
  updatePersonalInfoPhoneHome = (event) => {
    const { personalInfo } = this.state;
    personalInfo.phone.home = event.target.value;
    this.setState({
      personalInfo,
    });
  };
  updatePersonalInfoPhoneAlternate = (event) => {
    const { personalInfo } = this.state;
    personalInfo.phone.alternate = event.target.value;
    this.setState({
      personalInfo,
    });
  };
  updatePersonalInfoEmail = (event) => {
    const { personalInfo } = this.state;
    personalInfo.email = event.target.value;
    this.setState({
      personalInfo,
    });
  };

  updatePersonalInfoIsAdmin = (type) => {
    let { personalInfo, user, isAdmin, isSuperAdmin } = this.state;
    let admin = isAdmin;
    let SuperAdmin = isSuperAdmin;
    if (type === "admin") {
      // isSuperAdmin = isAdmin;
      admin = !isAdmin;
      user.isAdmin = !isAdmin;
      personalInfo.isAdmin = !isAdmin;
    } else if (type === "superAdmin") {
      // isAdmin = isSuperAdmin;
      SuperAdmin = !isSuperAdmin;
      user.isSuperAdmin = !isSuperAdmin;
      personalInfo.isAdmin = !isSuperAdmin;
    }
    this.setState({
      personalInfo,
      user,
      isAdmin: admin,
      isSuperAdmin: SuperAdmin,
    });
  };
  updatePersonalInfoGovtId = (event) => {
    const { personalInfo } = this.state;
    personalInfo.govtId = event.target.value;
    this.setState({
      personalInfo,
    });
  };

  updatePersonalInfoGovtType = (event) => {
    const { personalInfo } = this.state;
    personalInfo.govtType = event.target.value;
    this.setState({
      personalInfo,
    });
  };

  updatePersonalGender = (event) => {
    const { personalInfo } = this.state;
    personalInfo.gender = event.target.value;
    this.setState({
      personalInfo,
    });
  };
  updatePersonalInfoBirth = (event) => {
    const { personalInfo } = this.state;
    console.log(event);
    personalInfo.birth = event.target
      ? event.target.value
      : moment(event).format("DD/MM/YYYY");
    this.setState({
      personalInfo,
    });
  };
  updatePersonalInfoMartial = (event) => {
    const { personalInfo } = this.state;
    personalInfo.martial = event.target.value;
    this.setState({
      personalInfo,
    });
  };
  updatePersonalInfoSpouseName = (event) => {
    const { personalInfo } = this.state;
    personalInfo.spouse.name = event.target.value;
    this.setState({
      personalInfo,
    });
  };
  updatePersonalInfoSpouseEmployer = (event) => {
    const { personalInfo } = this.state;
    personalInfo.spouse.employer = event.target.value;
    this.setState({
      personalInfo,
    });
  };
  updatePersonalInfoSpousePhone = (event) => {
    const { personalInfo } = this.state;
    personalInfo.spouse.phone = event.target.value;
    this.setState({
      personalInfo,
    });
  };
  updatePersonalInfoPrevEmployeeEmployee = (event) => {
    const { personalInfo } = this.state;
    personalInfo.prevEmployee.employee = event.target.value;
    this.setState({
      personalInfo,
    });
  };
  updatePersonalInfoPrevEmployeePhone = (event) => {
    const { personalInfo } = this.state;
    personalInfo.prevEmployee.phone = event.target.value;
    this.setState({
      personalInfo,
    });
  };
  updateJobInfoTitle = (event) => {
    const { jobInfo } = this.state;
    jobInfo.title = event.target.value;
    this.setState({
      jobInfo,
    });
  };
  updateJobInfoEmployeeId = (event) => {
    const { jobInfo } = this.state;
    jobInfo.employeeId = event.target.value;
    jobInfo.changeableId = event.target.value;
    this.setState({
      jobInfo,
    });
  };

  updateIsLineManger = () => {
    const { isLineManager } = this.state;
    this.setState({
      isLineManager: !isLineManager,
    });
  };

  updateJobInfoGrade = (event) => {
    const { jobInfo, jobGrades } = this.state;
    const value = event.target.value;
    // jobInfo.salary = item;
    if (value === "add_job") {
      const { show_add_job } = this.state;
      this.setState({
        show_add_job: !show_add_job,
      });
    } else {
      let grade = jobGrades.filter((grades) => grades._id === value);
      grade = Object.entries(grade[0].salary).reduce((c, p) => c + p[1], 0);
      jobInfo.grade = value;
      jobInfo.salary = grade;
      this.setState({
        jobInfo,
      });
    }
  };

  updateJobInfoCategory = (event) => {
    const { jobInfo } = this.state;
    const value = event.target.value;
    // jobInfo.salary = item;
    if (value === "add_jobCategory") {
      const { show_add_jobCategory } = this.state;
      this.setState({
        show_add_jobCategory: !show_add_jobCategory,
      });
    } else {
      jobInfo.category = value;
      this.setState({
        jobInfo,
      });

      // this.updateIsLineMangerToTrue(value?.name);
    }
  };

  updateJobInfoCompanyId = (event) => {
    const { jobInfo } = this.state;
    jobInfo.company_id = event.target.value;
    // user.company_id = event.target.value;
    this.setState({
      jobInfo,
    });
  };
  updateJobInfoSupervisor = (params) => {
    const { jobInfo } = this.state;
    jobInfo.supervisor = params.map((data) => {
      return data.id;
    });
    this.setState({
      jobInfo,
    });
  };
  updateJobInfoDepartment = (event) => {
    const { jobInfo, user, show_add_department } = this.state;
    const value = event.target.value;
    if (value === "add_department") {
      this.setState({
        show_add_department: !show_add_department,
      });
    } else {
      jobInfo.department = event.target.value;
      user.department_id = event.target.value;
      this.setState({
        jobInfo,
        user,
      });
    }
  };
  updateJobInfoWorkLocation = (event) => {
    const { jobInfo, locations } = this.state;
    const value = event.target.value;
    if (value === "add_location") {
      const { show_add_location } = this.state;
      this.setState({
        show_add_location: !show_add_location,
      });
    } else {
      let branchesLocations = locations.find((el) => el._id === value);
      branchesLocations = branchesLocations?.branches;
      jobInfo.workLocation = value;
      jobInfo.workLocationBranch = "";
      this.setState({
        jobInfo,
        branchesLocations,
      });
    }
  };

  updateJobInfoWorkLocationBranch = (event) => {
    const { jobInfo, branchesLocations } = this.state;
    const value = event.target.value;
    jobInfo.workLocationBranch = value;
    let state = branchesLocations.find((el) => el.name === value);
    state = state?.state;
    jobInfo.state = state;
    this.setState({
      jobInfo,
    });
  };

  updateJobInfoEmail = (event) => {
    const { jobInfo, user } = this.state;
    jobInfo.email = event.target.value;
    user.email = event.target.value;
    user.username = event.target.value;
    this.setState({ jobInfo, user });
  };
  updateJobInfoPassword = (event) => {
    const { user } = this.state;
    // jobInfo.password = event.target.value;
    user.password = event.target.value;
    this.setState({ user });
  };
  updateJobInfoWorkPhone = (event) => {
    const { jobInfo } = this.state;
    jobInfo.workPhone = event.target.value;
    this.setState({
      jobInfo,
    });
  };
  updateJobInfoCellPhone = (event) => {
    const { jobInfo } = this.state;
    jobInfo.cellPhone = event.target.value;
    this.setState({
      jobInfo,
    });
  };
  updateSFAID = (event) => {
    const { jobInfo } = this.state;
    jobInfo.sfa_id = event.target.value;
    this.setState({
      jobInfo,
    });
  };
  updateJobInfoStartDate = (event) => {
    const { jobInfo } = this.state;
    jobInfo.startDate = event.target
      ? event.target.value
      : moment(event).format("DD/MM/YYYY");
    this.setState({
      jobInfo,
    });
  };

  updateJobInfoJoinDate = (event) => {
    const { jobInfo } = this.state;
    jobInfo.joinDate = event.target
      ? event.target.value
      : moment(event).format("DD/MM/YYYY");
    jobInfo.startDate = event.target
      ? event.target.value
      : moment(event).format("DD/MM/YYYY");
    this.setState({
      jobInfo,
    });
  };

  updateJobInfoConfirmDate = (event) => {
    const { jobInfo } = this.state;
    jobInfo.confirmDate = event.target
      ? event.target.value
      : moment(event).format("DD/MM/YYYY");
    this.setState({
      jobInfo,
    });
  };

  updateJobInfoSalary = (value) => {
    const { jobInfo } = this.state;
    jobInfo.salary = value;
    this.setState({
      jobInfo,
    });
  };

  putJobGrade = (item) => {
    const { jobInfo } = this.state;
    jobInfo.salary = item;
    this.setState({
      jobInfo,
    });
  };

  updateJobInfoStatus = (event) => {
    const { jobInfo } = this.state;
    jobInfo.status = event.target.value;
    this.setState({
      jobInfo,
    });
  };
  updateJobInfoSalaryBasis = (event) => {
    const { jobInfo } = this.state;
    jobInfo.salaryBasis = event.target.value;
    this.setState({
      jobInfo,
    });
  };
  updateJobInfoContract = (event) => {
    const { jobInfo } = this.state;
    jobInfo.contract = event.target.checked;
    this.setState({
      jobInfo,
    });
  };
  updateJobInfoContractType = (event) => {
    const { jobInfo } = this.state;
    jobInfo.contractType = event.target.value;
    this.setState({
      jobInfo,
    });
  };
  updateEmergencyInfoNameFirst = (event) => {
    const { emergencyInfo } = this.state;
    emergencyInfo.name.first = event.target.value;
    this.setState({
      emergencyInfo,
    });
  };
  updateEmergencyInfoNameLast = (event) => {
    const { emergencyInfo } = this.state;
    emergencyInfo.name.last = event.target.value;
    this.setState({
      emergencyInfo,
    });
  };
  updateEmergencyInfoNameMiddle = (event) => {
    const { emergencyInfo } = this.state;
    emergencyInfo.name.middle = event.target.value;
    this.setState({
      emergencyInfo,
    });
  };
  updateEmergencyInfoAddressApartment = (event) => {
    const { emergencyInfo } = this.state;
    emergencyInfo.address.apartment = event.target.value;
    this.setState({
      emergencyInfo,
    });
  };
  updateEmergencyInfoAddressStreet = (event) => {
    const { emergencyInfo } = this.state;
    emergencyInfo.address.street = event.target.value;
    this.setState({
      emergencyInfo,
    });
  };
  updateEmergencyInfoAddressCity = (event) => {
    const { emergencyInfo } = this.state;
    emergencyInfo.address.city = event.target.value;
    this.setState({
      emergencyInfo,
    });
  };
  updateEmergencyInfoAddressState = (event) => {
    const { emergencyInfo } = this.state;
    emergencyInfo.address.state = event.target.value;
    this.setState({
      emergencyInfo,
    });
  };
  updateEmergencyInfoAddressZip = (event) => {
    const { emergencyInfo } = this.state;
    emergencyInfo.address.zip = event.target.value;
    this.setState({
      emergencyInfo,
    });
  };
  updateEmergencyInfoPhonePrimary = (event) => {
    const { emergencyInfo } = this.state;
    emergencyInfo.phone.primary = event.target.value;
    this.setState({
      emergencyInfo,
    });
  };
  updateEmergencyInfoPhoneSecondary = (event) => {
    const { emergencyInfo } = this.state;
    emergencyInfo.phone.secondary = event.target.value;
    this.setState({
      emergencyInfo,
    });
  };
  updateEmergencyInfoRelation = (event) => {
    const { emergencyInfo } = this.state;
    emergencyInfo.relation = event.target.value;
    this.setState({
      emergencyInfo,
    });
  };
  updateEmergencyInfoGuarantorTitle = (event) => {
    const { emergencyInfo } = this.state;
    emergencyInfo.guarantor.title = event.target.value;
    this.setState({
      emergencyInfo,
    });
  };
  updateEmergencyInfoGuarantorName = (event) => {
    const { emergencyInfo } = this.state;
    emergencyInfo.guarantor.name = event.target.value;
    this.setState({
      emergencyInfo,
    });
  };
  updateEmergencyInfoGuarantorPhone = (event) => {
    const { emergencyInfo } = this.state;
    emergencyInfo.guarantor.phone = event.target.value;
    this.setState({
      emergencyInfo,
    });
  };
  updateEmergencyInfoGuarantorAddress = (event) => {
    const { emergencyInfo } = this.state;
    emergencyInfo.guarantor.address = event.target.value;
    this.setState({
      emergencyInfo,
    });
  };
  updateEmergencyInfoRefereeTitle = (event) => {
    const { emergencyInfo } = this.state;
    emergencyInfo.referee.title = event.target.value;
    this.setState({
      emergencyInfo,
    });
  };
  updateEmergencyInfoRefereeName = (event) => {
    const { emergencyInfo } = this.state;
    emergencyInfo.referee.name = event.target.value;
    this.setState({
      emergencyInfo,
    });
  };
  updateEmergencyInfoRefereePhone = (event) => {
    const { emergencyInfo } = this.state;
    emergencyInfo.referee.phone = event.target.value;
    this.setState({
      emergencyInfo,
    });
  };
  updateEmergencyInfoRefereeAddress = (event) => {
    const { emergencyInfo } = this.state;
    emergencyInfo.referee.address = event.target.value;
    this.setState({
      emergencyInfo,
    });
  };
  updateEmergencyInfoEmployeeHMO = (event) => {
    const { emergencyInfo } = this.state;
    const value = event.target.value;
    if (value === "add_employeeHMO") {
      const { show_add_employeeHMO } = this.state;
      this.setState({
        show_add_employeeHMO: !show_add_employeeHMO,
      });
    } else {
      emergencyInfo.employeeHMO = value;
      this.setState({
        emergencyInfo,
      });
    }
  };
  updateEmergencyInfoLifeInsurance = (event) => {
    const { emergencyInfo } = this.state;
    const value = event.target.value;
    if (value === "add_lifeInsurance") {
      const { show_add_lifeInsurance } = this.state;
      this.setState({
        show_add_lifeInsurance: !show_add_lifeInsurance,
      });
    } else {
      emergencyInfo.lifeInsurance = value;
      this.setState({
        emergencyInfo,
      });
    }
  };
  updateEmergencyInfoImage = (event) => {
    const { emergencyInfo } = this.state;
    let currentDate = new Date();
    currentDate = currentDate.getTime();
    emergencyInfo.image = currentDate + ".png";
    this.setState({
      emergencyInfo,
    });
    let file = this.refs.emergency_image.files[0];
    let reader = new FileReader();
    let url = reader.readAsDataURL(file);
    reader.onloadend = function () {
      this.setState({
        displayImageSrc: [reader.result],
      });
    }.bind(this);
  };
  updateEmergencyInfoResume = (event) => {
    const { emergencyInfo } = this.state;
    let currentDate = new Date();
    currentDate = currentDate.getTime();
    emergencyInfo.resume = currentDate + ".pdf";
    this.setState({
      emergencyInfo,
    });
    this.setState({
      displayResumeSrc: event.target.files[0],
    });
  };
  onDocumentLoadSuccess = (e) => {
    console.log("success", e);
  };

  updateEmergencyInfoCertificate = (event) => {
    const { emergencyInfo } = this.state;
    let currentDate = new Date();
    currentDate = currentDate.toUTCString();
    currentDate = currentDate.replace(/ /g, "_");
    emergencyInfo.certificate = currentDate + ".pdf";
    this.setState({
      emergencyInfo,
    });
  };

  updateGrantAccess = () => {
    let { personalInfo, user, grantAccess } = this.state;
    personalInfo.grantAccess = !grantAccess;
    user.grantAccess = !grantAccess;
    grantAccess = !grantAccess;

    this.setState({
      personalInfo,
      user,
      grantAccess,
    });
  };

  submit = (e) => {
    e.preventDefault();
    const token = "dummy token";
    this.setState({ buttonText: "Submitting ...", isWaiting: true });
    let {
      user,
      personalInfo,
      jobInfo,
      educationInfo,
      emergencyInfo,
      educationCertificateFiles,
      bankDetails,
      isAdmin,
      isSuperAdmin,
      grantAccess,
      branch,
      isLineManager,
      departments,
    } = this.state;

    user.workEmail = jobInfo.email;
    user.Staff_ID = jobInfo.employeeId;
    user.branch = branch;
    user.email = jobInfo.email;
    if (jobInfo.email) {
      const min = 2;
      const max = 4;
      const random = Math.floor(Math.random() * (max - min + 1)) + min;
      const email = jobInfo.email;
      user.username = `${email.substring(0, email.lastIndexOf("@"))}${random}`;
      user.filesystem_entry = `${email.substring(
        0,
        email.lastIndexOf("@")
      )}${random}`;
    }

    if (jobInfo.startDate === "" || jobInfo.startDate === undefined) {
      this.setState({ buttonText: "Submit", isWaiting: false });
      alert("Start Date is needed");
      return;
    }
    if (jobInfo.joinDate === "" || jobInfo.joinDate === undefined) {
      this.setState({ buttonText: "Submit", isWaiting: false });
      alert("Joint Date is needed");
      return;
    }

    if (isAdmin || isSuperAdmin || grantAccess) {
      if (user.password === "") {
        this.setState({ buttonText: "Submit", isWaiting: false });
        alert("password is needed");
        return;
      }
    }

    if (
      jobInfo.grade === null ||
      jobInfo.grade === "" ||
      jobInfo.grade === undefined
    ) {
      this.setState({ buttonText: "Submit", isWaiting: false });
      alert("Job Grade is needed");
      return;
    }
    if (jobInfo.status === "Confirmed" && jobInfo.confirmDate === "") {
      this.setState({ buttonText: "Submit", isWaiting: false });
      alert("Confirm Date is needed");
      return;
    }

    const mainEmployee = {
      EmployeeID: jobInfo.employeeId,
      changeableId: jobInfo.changeableId,
      LastName: personalInfo.name.last,
      FirstName: personalInfo.name.first,
      MiddleName: personalInfo.name.middle,
      ContactAddress: `${personalInfo.address.apartment}, ${personalInfo.address.city}, ${personalInfo.address.state}`,
      PhoneNumber: personalInfo.phone.home,
      Email: jobInfo.email,
      Title: jobInfo.title,
      BirthDate: personalInfo.birth,
      MaritalStatus: personalInfo.martial,
      Gender: personalInfo.gender,
      Nationality: "",
      StateofOrigin: "",
      LGA: "",
      HireDate: jobInfo.startDate,
      SalaryID: "",
      Department:
        departments?.find((ele) => ele?._id === jobInfo?.department)?.name ||
        "",
      Photo: "",
      Resume: "",
      ModifiedDate: "",
      Status: "Active",
      isAdmin,
      isSuperAdmin,
      userName: user.username,
      password: user.password,
      grantAccess,
    };

    if (process.env.REACT_APP_HAS_INVEX === "true") {
      fetch(`${URLS.mainSiteBackend}/users/save/employee`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          Authorization: `Token ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ ...mainEmployee }),
      }).then((res) => {
        if (res.ok) {
          user.branch = branch;
          fetch(`${URLS.backendUsers}/add`, {
            method: "POST",
            headers: {
              Accept: "application/json",
              Authorization: `Token ${token}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ user: user }),
          }).then((res) => {
            if (res.ok) {
              res.json().then((result) => {
                let formData = new FormData();
                const imageName = this.state.emergencyInfo.image;
                const resumeName = this.state.emergencyInfo.resume;
                // if (jobInfo) {
                //   jobInfo = Object.entries(jobInfo).reduce((acc, cur, index) => {
                //     const key = cur[0];
                //     const value = cur[1];
                //     if (value !== "") {
                //       acc[key] = value;
                //     }
                //     return acc;
                //   }, {});
                // }
                const employee = {
                  userId: result.user._id,
                  personalInfo,
                  jobInfo,
                  emergencyInfo,
                  educationInfo,
                  bankDetails,
                  branch,
                  Staff_ID: jobInfo.employeeId,
                  isLineManager,
                };
                formData.append("employee", JSON.stringify(employee));
                if (
                  this.refs.emergency_image &&
                  this.refs.emergency_image.files[0]
                )
                  formData.append(
                    "file",
                    this.refs.emergency_image.files[0],
                    imageName
                  );
                if (
                  this.refs.emergency_resume &&
                  this.refs.emergency_resume.files[0]
                )
                  formData.append(
                    "file",
                    this.refs.emergency_resume.files[0],
                    resumeName
                  );
                for (let i = 0; i < educationInfo.length; i++) {
                  if (
                    educationCertificateFiles[i].file &&
                    educationCertificateFiles[i].file !== ""
                  )
                    formData.append(
                      "file",
                      educationCertificateFiles[i].file,
                      educationInfo[i].certificate
                    );
                }
                fetch(`${URLS.backendEmployees}/add`, {
                  method: "POST",
                  headers: {
                    Accept: "application/json",
                    Authorization: `Token ${token}`,
                  },
                  body: formData,
                }).then((res) => {
                  if (res.ok) {
                    res.json().then((result) => {
                      setTimeout(() => {
                        toast.success("Added Successfully", {
                          position: "top-right",
                        });
                        this.setState({
                          buttonText: "Submit",
                          isWaiting: false,
                        });
                        this.props.hindeIt();
                      }, 500);

                      this.props.updateEmployeesList(result.employee);
                    });
                  } else {
                    res.json().then((responseJson) => {
                      // console.log(responseJson);

                      toast.error("Error occurred, Check the form again.", {
                        position: "top-right",
                      });
                      this.setState({ buttonText: "Submit", isWaiting: false });
                    });
                  }
                });
              });
            } else {
              res.json().then((responseJson) => {
                // console.log(responseJson);

                toast.error(responseJson.message, {
                  position: "top-right",
                });
                this.setState({ buttonText: "Submit", isWaiting: false });
              });
            }
          });
        } else {
          res.json().then((responseJson) => {
            // console.log(responseJson);
            toast.error(responseJson.message, {
              position: "top-right",
            });
            //console.log(responseJson.message);
            this.setState({ buttonText: "Submit", isWaiting: false });
          });
        }
      });
    } else {
      fetch(`${URLS.backendUsers}/add`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          Authorization: `Token ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ user: user }),
      }).then((res) => {
        if (res.ok) {
          res.json().then((result) => {
            let formData = new FormData();
            const imageName = this.state.emergencyInfo.image;
            const resumeName = this.state.emergencyInfo.resume;
            const employee = {
              userId: result.user._id,
              personalInfo,
              jobInfo,
              emergencyInfo,
              educationInfo,
              bankDetails,
              branch,
              Staff_ID: jobInfo.employeeId,
            };
            formData.append("employee", JSON.stringify(employee));
            if (this.refs.emergency_image && this.refs.emergency_image.files[0])
              formData.append(
                "file",
                this.refs.emergency_image.files[0],
                imageName
              );
            if (
              this.refs.emergency_resume &&
              this.refs.emergency_resume.files[0]
            )
              formData.append(
                "file",
                this.refs.emergency_resume.files[0],
                resumeName
              );
            for (let i = 0; i < educationInfo.length; i++) {
              if (
                educationCertificateFiles[i].file &&
                educationCertificateFiles[i].file !== ""
              )
                formData.append(
                  "file",
                  educationCertificateFiles[i].file,
                  educationInfo[i].certificate
                );
            }
            fetch(`${URLS.backendEmployees}/add`, {
              method: "POST",
              headers: {
                Accept: "application/json",
                Authorization: `Token ${token}`,
              },
              body: formData,
            }).then((res) => {
              if (res.ok) {
                res.json().then((result) => {
                  setTimeout(() => {
                    toast.success("Updated Successful", {
                      position: "top-right",
                    });
                    this.setState({
                      buttonText: "Submit",
                      isWaiting: false,
                    });
                    this.props.hindeIt();
                  }, 500);

                  this.props.updateEmployeesList(result.employee);
                });
              } else {
                res.json().then((responseJson) => {
                  console.log(responseJson);
                  alert("Error occurred, Check the form again.");
                  this.setState({
                    buttonText: "Submit",
                    isWaiting: false,
                  });
                });
              }
            });
          });
        } else {
          res.json().then((responseJson) => {
            // console.log(responseJson);
            toast.error("Error", {
              position: "top-right",
            });
            // alert(responseJson.message);
            this.setState({
              buttonText: "Submit",
              isWaiting: false,
            });
          });
        }
      });
    }
  };

  handleBankDetails = (e) => {
    const { bankDetails } = this.state;
    bankDetails[e.target.name] = e.target.value;
    this.setState({
      bankDetails,
    });
  };

  render() {
    const { mode, hindeIt } = this.props;
    const id = mode === "edit" ? "edit_employee" : "add_empolyee";
    const title = mode === "edit" ? "Edit Employee" : "Add Employee";
    const {
      personalInfo,
      jobInfo,
      emergencyInfo,
      educationInfo,
      jobGrades,
      departments,
      locations,
      educationCertificateFiles,
      displayImageSrc,
      displayResumeSrc,
      pdfPageNumber,
      employeeHMOs,
      lifeInsurances,
      supervisors,
      projects,
      companies,
      isSuperAdmin,
      isAdmin,
      bankDetails,
      grantAccess,
      branches,
      branch,
      showBranch,
      branchesLocations,
      jobCategories,
      isLineManager,
    } = this.state;

    return (
      <Modal
        className="custom-modal"
        show={this.props.show}
        centered
        size="lg"
        onHide={this.props.hindeIt}
      >
        <div>
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Add Employee</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => hindeIt()}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <Form onSubmit={this.submit}>
                <Form.Group as={Row}>
                  {/*<Col xs={6}>*/}
                  {/*    <Image src={this.state.displayImageSrc} alt='Image'*/}
                  {/*        style={{ height: '100px', width: '80px' }} rounded />*/}
                  {/*</Col>*/}
                  <Col
                    xs={{ span: 6, offset: 6 }}
                    style={{
                      textAlignLast: "end",
                      justifyContent: "right",
                    }}
                  >
                    {/* Petrobase Group Ltd. */}
                  </Col>
                </Form.Group>
                <Form.Group
                  as={Row}
                  style={{
                    backgroundColor: "#2463ae",
                    alignItems: "center",
                    justifyContent: "center",
                    fontSize: "16px",
                    color: "#ffffff",
                    fontWeight: "bold",
                  }}
                >
                  Personal Information
                </Form.Group>
                <Form.Group as={Row}>
                  <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                    Full Name <span style={{ color: "red" }}>*</span>
                  </Form.Label>
                  <Col sm={10}>
                    <Row>
                      <Col sm={4}>
                        <Form.Control
                          placeholder="First name"
                          value={personalInfo.name.first}
                          onChange={this.updatePersonalInfoFirstName.bind(this)}
                          required
                        />
                      </Col>
                      <Col sm={4}>
                        <Form.Control
                          placeholder="Last name"
                          value={personalInfo.name.last}
                          onChange={this.updatePersonalInfoLastName.bind(this)}
                          required
                        />
                      </Col>
                      <Col sm={4}>
                        <Form.Control
                          placeholder="Middle Initial"
                          value={personalInfo.name.middle}
                          onChange={this.updatePersonalInfoMiddleName.bind(
                            this
                          )}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                    Address
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Row>
                      <Form.Group as={Col} sm={4}>
                        <Form.Control
                          placeholder="Apartment/Unit #"
                          value={personalInfo.address.apartment}
                          onChange={this.updatePersonalInfoAddressApartment.bind(
                            this
                          )}
                        />
                      </Form.Group>
                      <Form.Group as={Col} sm={8}>
                        <Form.Control
                          placeholder="Street Address"
                          value={personalInfo.address.street}
                          onChange={this.updatePersonalInfoAddressStreet.bind(
                            this
                          )}
                        />
                      </Form.Group>
                    </Form.Row>
                    <Form.Row>
                      <Form.Group as={Col} sm={4}>
                        <Form.Control
                          placeholder="City"
                          value={personalInfo.address.city}
                          onChange={this.updatePersonalInfoAddressCity.bind(
                            this
                          )}
                        />
                      </Form.Group>
                      <Form.Group as={Col} sm={4}>
                        <Form.Control
                          placeholder="State"
                          value={personalInfo.address.state}
                          onChange={this.updatePersonalInfoAddressState.bind(
                            this
                          )}
                        />
                      </Form.Group>
                      <Form.Group as={Col} sm={4}>
                        <Form.Control
                          placeholder="Zip code"
                          value={personalInfo.address.zip}
                          onChange={this.updatePersonalInfoAddressZip.bind(
                            this
                          )}
                        />
                      </Form.Group>
                    </Form.Row>
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                    Home Phone
                  </Form.Label>
                  <Col sm={4}>
                    <Form.Control
                      value={personalInfo.phone.home}
                      onChange={this.updatePersonalInfoPhoneHome.bind(this)}
                    />
                  </Col>
                  <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                    Alternate Phone
                  </Form.Label>
                  <Col sm={4}>
                    <Form.Control
                      value={personalInfo.phone.alternate}
                      onChange={this.updatePersonalInfoPhoneAlternate.bind(
                        this
                      )}
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                    Email <span style={{ color: "red" }}>*</span>
                  </Form.Label>
                  <Col sm={4}>
                    <Form.Control
                      placeholder="Email"
                      value={personalInfo.email}
                      type="email"
                      onChange={this.updatePersonalInfoEmail.bind(this)}
                      required
                    />
                  </Col>
                  {/* <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                    Is Admin
                  </Form.Label> */}
                  <Col sm={3}>
                    <div
                      className="form-check"
                      style={{
                        height: "44px",
                        padding: "6px 0",
                        display: "inline-block",
                      }}
                    >
                      <input
                        style={{ height: "30px", width: "30px" }}
                        type="checkbox"
                        checked={isAdmin}
                        onChange={() => this.updatePersonalInfoIsAdmin("admin")}
                      />
                      <label
                        style={{ margin: "10px", verticalAlign: "bottom" }}
                        onClick={() => this.updatePersonalInfoIsAdmin("admin")}
                      >
                        Admin
                      </label>
                    </div>
                  </Col>

                  <Col sm={3}>
                    <div
                      className="form-check"
                      style={{
                        height: "44px",
                        padding: "6px 0",
                        display: "inline-block",
                      }}
                    >
                      <input
                        style={{ height: "30px", width: "30px" }}
                        type="checkbox"
                        checked={isSuperAdmin}
                        onChange={() =>
                          this.updatePersonalInfoIsAdmin("superAdmin")
                        }
                      />
                      <label
                        style={{ margin: "10px", verticalAlign: "bottom" }}
                        onClick={() =>
                          this.updatePersonalInfoIsAdmin("superAdmin")
                        }
                      >
                        Super Admin
                      </label>
                    </div>
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                    Govt ID Type:
                  </Form.Label>
                  <Col sm={4}>
                    <Form.Control
                      as="select"
                      value={personalInfo.govtType}
                      onChange={this.updatePersonalInfoGovtType.bind(this)}
                    >
                      <option>Choose...</option>
                      <option value="Voter's Card">Voter's Card</option>
                      <option value="Driver's Licence">Driver's Licence</option>
                      <option value="National ID">National ID</option>
                      <option value="International ID">International ID</option>
                    </Form.Control>
                  </Col>

                  <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                    Govt ID:
                  </Form.Label>
                  <Col sm={4}>
                    <Form.Control
                      placeholder="Gov’t ID:"
                      value={personalInfo.govtId}
                      onChange={this.updatePersonalInfoGovtId.bind(this)}
                    />
                  </Col>

                  {/* <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                    Is Super Admin
                  </Form.Label> */}
                </Form.Group>
                <Form.Group as={Row}>
                  <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                    Gender:
                  </Form.Label>
                  <Col sm={4}>
                    <Form.Control
                      as="select"
                      value={personalInfo.gender}
                      onChange={this.updatePersonalGender.bind(this)}
                    >
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                      <option value="Others">Others</option>
                    </Form.Control>
                  </Col>

                  <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                    Birth Date
                  </Form.Label>
                  <Col sm={4}>
                    <div classname="datePicker">
                      <i
                        class="fa fa-calendar fa-xs calendar-icon"
                        aria-hidden="true"
                      ></i>
                      <Datepicker
                        value={personalInfo.birth}
                        closeOnSelect={true}
                        onChange={this.updatePersonalInfoBirth.bind(this)}
                      />
                    </div>
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                    Marital Status
                  </Form.Label>
                  <Col sm={4}>
                    <Form.Control
                      as="select"
                      value={personalInfo.martial}
                      onChange={this.updatePersonalInfoMartial.bind(this)}
                    >
                      <option value="Single">Single</option>
                      <option value="Married">Married</option>
                      <option value="Divorce">Divorce</option>
                      <option value="Widow">Widow</option>
                      <option value="Widower">Widower</option>
                    </Form.Control>
                  </Col>
                  {personalInfo.martial !== "Single" && (
                    <Form.Group as={Row}>
                      <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                        Spouse’s Name
                      </Form.Label>
                      <Col sm={10}>
                        <Form.Control
                          value={personalInfo.spouse.name}
                          onChange={this.updatePersonalInfoSpouseName.bind(
                            this
                          )}
                        />
                      </Col>
                    </Form.Group>
                  )}
                </Form.Group>
                {personalInfo.martial !== "Single" && (
                  <Form.Group as={Row}>
                    <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                      Spouse’s Employer
                    </Form.Label>
                    <Col sm={4}>
                      <Form.Control
                        placeholder="Spouse’s Employer"
                        value={personalInfo.spouse.employer}
                        onChange={this.updatePersonalInfoSpouseEmployer.bind(
                          this
                        )}
                      />
                    </Col>
                    <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                      Spouse’s Work Phone
                    </Form.Label>
                    <Col sm={4}>
                      <Form.Control
                        placeholder="Spouse’s Work Phone"
                        value={personalInfo.spouse.phone}
                        onChange={this.updatePersonalInfoSpousePhone.bind(this)}
                      />
                    </Col>
                  </Form.Group>
                )}
                <Form.Group as={Row}>
                  <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                    Previous Employer
                  </Form.Label>
                  <Col sm={4}>
                    <Form.Control
                      placeholder="Previous Employee"
                      value={personalInfo.prevEmployee.employee}
                      onChange={this.updatePersonalInfoPrevEmployeeEmployee.bind(
                        this
                      )}
                    />
                  </Col>
                  <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                    Previous Employer Phone
                  </Form.Label>
                  <Col sm={4}>
                    <Form.Control
                      placeholder="Previous Employer Phone"
                      value={personalInfo.prevEmployee.phone}
                      onChange={this.updatePersonalInfoPrevEmployeePhone.bind(
                        this
                      )}
                    />
                  </Col>
                </Form.Group>
                <Form.Group
                  as={Row}
                  style={{
                    backgroundColor: "#2463ae",
                    alignItems: "center",
                    justifyContent: "center",
                    fontSize: "16px",
                    color: "#ffffff",
                    fontWeight: "bold",
                  }}
                >
                  Job Information
                </Form.Group>
                <Form.Group as={Row}>
                  <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                    Job Title
                  </Form.Label>
                  <Col sm={4}>
                    <Form.Control
                      value={jobInfo.title}
                      onChange={this.updateJobInfoTitle.bind(this)}
                    />
                  </Col>
                  <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                    Employee ID <span style={{ color: "red" }}>*</span>
                  </Form.Label>
                  <Col sm={4}>
                    <Form.Control
                      value={jobInfo.changeableId}
                      onChange={this.updateJobInfoEmployeeId.bind(this)}
                      required
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                    Employee Category
                  </Form.Label>
                  <Col sm={4}>
                    <Form.Control
                      as="select"
                      value={jobInfo.category}
                      onChange={this.updateJobInfoCategory.bind(this)}
                      required
                    >
                      <option>Select</option>
                      <option value="add_jobCategory">Add New</option>
                      <option disabled>__________________</option>
                      {jobCategories?.map((jobCategory) => {
                        return (
                          <option value={jobCategory._id}>
                            {jobCategory.name}
                          </option>
                        );
                      })}
                    </Form.Control>
                  </Col>
                  <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                    Line Manager
                  </Form.Label>
                  <Col sm={4}>
                    <div className="d-flex justify-content-start">
                      <div className="">
                        <div
                          className="onoffswitch ml-0"
                          onClick={this.updateIsLineManger}
                        >
                          <input
                            type="checkbox"
                            name="onoffswitch"
                            className="onoffswitch-checkbox"
                            checked={isLineManager}
                          />
                          <label className="onoffswitch-label">
                            <span className="onoffswitch-inner"></span>
                            <span className="onoffswitch-switch"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                    Job Grade
                  </Form.Label>
                  <Col sm={4}>
                    <Form.Control
                      as="select"
                      value={jobInfo.grade}
                      onChange={this.updateJobInfoGrade.bind(this)}
                      required
                    >
                      <option>Select</option>
                      <option value="add_job">Add New</option>
                      <option disabled>__________________</option>
                      {jobGrades?.map((jobGrade) => {
                        return (
                          <option value={jobGrade._id}>{jobGrade.name}</option>
                        );
                      })}
                    </Form.Control>
                  </Col>
                  <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                    Work Location
                  </Form.Label>
                  <Col sm={4}>
                    <Form.Control
                      as="select"
                      value={jobInfo.workLocation}
                      onChange={this.updateJobInfoWorkLocation.bind(this)}
                    >
                      <option value="">Select</option>
                      <option value="add_location">Add New</option>
                      <option disabled>__________________</option>
                      {locations.map((location) => {
                        return (
                          <option value={location._id}>
                            {location.location}
                          </option>
                        );
                      })}
                    </Form.Control>
                  </Col>
                </Form.Group>
                {branchesLocations?.length > 0 && (
                  <Form.Group as={Row}>
                    <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                      Branch
                    </Form.Label>
                    <Col sm={4}>
                      <Form.Control
                        as="select"
                        value={jobInfo.workLocationBranch}
                        onChange={this.updateJobInfoWorkLocationBranch.bind(
                          this
                        )}
                      >
                        <option value="">Select</option>
                        {branchesLocations.map((location) => {
                          return (
                            <option value={location.name}>
                              {location.name}
                            </option>
                          );
                        })}
                      </Form.Control>
                    </Col>
                    <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                      State
                    </Form.Label>
                    <Col sm={4}>
                      <Form.Control value={jobInfo.state} type="text" />
                    </Col>
                  </Form.Group>
                )}
                <Form.Group as={Row}>
                  <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                    Reporting To
                  </Form.Label>
                  <Col sm={4}>
                    {supervisors && supervisors.length ? (
                      <Multiselect
                        options={supervisors}
                        onSelectOptions={this.updateJobInfoSupervisor}
                        selectedOptions={jobInfo.supervisor}
                      />
                    ) : (
                      "None Found"
                    )}
                  </Col>
                  <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                    Department <span style={{ color: "red" }}>*</span>
                  </Form.Label>
                  <Col sm={4}>
                    <Form.Control
                      as="select"
                      value={jobInfo.department}
                      onChange={this.updateJobInfoDepartment.bind(this)}
                      required
                    >
                      <option value="">Select</option>
                      <option value="add_department">Add New</option>
                      <option disabled>__________________</option>
                      {departments?.map((dep) => {
                        return <option value={dep._id}>{dep.name}</option>;
                      })}
                    </Form.Control>
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                    Work Email <span style={{ color: "red" }}>*</span>
                  </Form.Label>
                  <Col sm={4}>
                    <Form.Control
                      value={jobInfo.email}
                      type="email"
                      onChange={this.updateJobInfoEmail.bind(this)}
                      required
                      autoComplete="new-password"
                    />
                  </Col>
                  <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                    Password
                    {/* <span style={{ color: "red" }}>*</span> */}
                  </Form.Label>
                  <Col sm={4}>
                    <Form.Control
                      type="password"
                      value={jobInfo.password}
                      onChange={this.updateJobInfoPassword.bind(this)}
                      required
                      autoComplete="new-password"
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                    Work Phone
                  </Form.Label>
                  <Col sm={4}>
                    <Form.Control
                      value={jobInfo.workPhone}
                      onChange={this.updateJobInfoWorkPhone.bind(this)}
                    />
                  </Col>
                  <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                    Cell Phone
                  </Form.Label>
                  <Col sm={4}>
                    <Form.Control
                      value={jobInfo.cellPhone}
                      onChange={this.updateJobInfoCellPhone.bind(this)}
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                    Join Date <span style={{ color: "red" }}>*</span>
                  </Form.Label>
                  <Col sm={4}>
                    <div classname="datePicker">
                      <i
                        class="fa fa-calendar fa-xs calendar-icon"
                        aria-hidden="true"
                      ></i>
                      <Datepicker
                        value={jobInfo.joinDate}
                        closeOnSelect={true}
                        onChange={this.updateJobInfoJoinDate.bind(this)}
                      />
                    </div>
                  </Col>

                  <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                    Salary
                  </Form.Label>
                  <Col sm={4}>
                    <CurrencyInput
                      className="form-control"
                      value={jobInfo.salary}
                      allowNegativeValue={false}
                      // allowDecimals={false}
                      onValueChange={(value, name) =>
                        this.updateJobInfoSalary(value)
                      }
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                    Start Date <span style={{ color: "red" }}>*</span>
                  </Form.Label>
                  <Col sm={4}>
                    <div classname="datePicker">
                      <i
                        class="fa fa-calendar fa-xs calendar-icon"
                        aria-hidden="true"
                      ></i>
                      <Datepicker
                        value={jobInfo.startDate}
                        closeOnSelect={true}
                        onChange={this.updateJobInfoStartDate.bind(this)}
                        isValidDate={this.validationEndDate}
                      />
                    </div>
                  </Col>

                  <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                    SFA ID
                  </Form.Label>
                  <Col sm={4}>
                    <Form.Control
                      value={jobInfo.sfa_id}
                      onChange={this.updateSFAID.bind(this)}
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                    Status <span style={{ color: "red" }}>*</span>
                  </Form.Label>
                  <Col sm={4}>
                    <Form.Control
                      as="select"
                      value={jobInfo.status}
                      onChange={this.updateJobInfoStatus.bind(this)}
                      required
                    >
                      <option>Choose...</option>
                      <option value="Internship">Internship</option>
                      <option value="Probation">Probation</option>
                      <option value="Trainee">Trainee</option>
                      <option value="New hire">New hire</option>
                      <option value="Confirmed">Confirmed</option>
                    </Form.Control>
                  </Col>
                  <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                    Salary Basis
                  </Form.Label>
                  <Col sm={4}>
                    <Form.Control
                      as="select"
                      value={jobInfo.salaryBasis}
                      onChange={this.updateJobInfoSalaryBasis.bind(this)}
                    >
                      <option>Choose...</option>
                      <option value="Hourly">Hourly</option>
                      <option value="Daily">Daily</option>
                      <option value="Daily">Weekly</option>
                      <option value="Monthly">Monthly</option>
                    </Form.Control>
                  </Col>
                </Form.Group>
                {jobInfo.status === "Confirmed" && (
                  <Form.Group as={Row}>
                    <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                      Confirm Date <span style={{ color: "red" }}>*</span>
                    </Form.Label>
                    <Col sm={4}>
                      <div classname="datePicker">
                        <i
                          class="fa fa-calendar fa-xs calendar-icon"
                          aria-hidden="true"
                        ></i>
                        <Datepicker
                          value={jobInfo.confirmDate}
                          closeOnSelect={true}
                          onChange={this.updateJobInfoConfirmDate.bind(this)}
                        />
                      </div>
                    </Col>
                  </Form.Group>
                )}

                <Form.Group as={Row}>
                  <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                    Contractor
                  </Form.Label>
                  <Col sm={4}>
                    <div
                      className="form-check"
                      style={{
                        height: "44px",
                        padding: "6px 0",
                        display: "inline-block",
                      }}
                    >
                      <input
                        style={{ height: "30px", width: "30px" }}
                        type="checkbox"
                        checked={jobInfo.contract}
                        onChange={this.updateJobInfoContract.bind(this)}
                      />
                      <label
                        style={{ margin: "10px", verticalAlign: "bottom" }}
                      >
                        Check if contract based
                      </label>
                    </div>
                  </Col>
                  {jobInfo.contract && (
                    <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                      Contract Type
                    </Form.Label>
                  )}
                  {jobInfo.contract && (
                    <Col sm={4}>
                      <Form.Control
                        as="select"
                        value={jobInfo.contractType}
                        onChange={this.updateJobInfoContractType.bind(this)}
                      >
                        <option value="">Choose...</option>
                        {projects?.length ? (
                          projects?.map((project) => {
                            return (
                              <option value={project._id}>
                                {project.contractName}
                              </option>
                            );
                          })
                        ) : (
                          <option value="">Choose...</option>
                        )}
                      </Form.Control>
                    </Col>
                  )}

                  {showBranch && (
                    <>
                      <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                        Select Company
                      </Form.Label>
                      <Col sm={4}>
                        <Select
                          id="company"
                          placeholder="Select"
                          menuShouldScrollIntoView={false}
                          isSearchable={false}
                          value={branches.find((el) => el.value === branch)}
                          onChange={(selected) => {
                            this.updateUserBranch(selected.value);
                          }}
                          options={branches}
                        />
                      </Col>
                    </>
                  )}
                </Form.Group>
                <Form.Group
                  as={Row}
                  style={{
                    backgroundColor: "#2463ae",
                    alignItems: "center",
                    justifyContent: "center",
                    fontSize: "16px",
                    color: "#ffffff",
                    fontWeight: "bold",
                  }}
                >
                  Emergency Contact Information
                </Form.Group>
                <Form.Group as={Row}>
                  <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                    Full Name
                  </Form.Label>
                  <Col sm={10}>
                    <Row>
                      <Col sm={4}>
                        <Form.Control
                          placeholder="First name"
                          value={emergencyInfo.name.first}
                          onChange={this.updateEmergencyInfoNameFirst.bind(
                            this
                          )}
                        />
                      </Col>
                      <Col sm={4}>
                        <Form.Control
                          placeholder="Last name"
                          value={emergencyInfo.name.last}
                          onChange={this.updateEmergencyInfoNameLast.bind(this)}
                        />
                      </Col>
                      <Col sm={4}>
                        <Form.Control
                          placeholder="Middle name"
                          value={emergencyInfo.name.middle}
                          onChange={this.updateEmergencyInfoNameMiddle.bind(
                            this
                          )}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                    Address
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Row>
                      <Form.Group as={Col} sm={4}>
                        <Form.Control
                          placeholder="Apartment/Unit #"
                          value={emergencyInfo.address.apartment}
                          onChange={this.updateEmergencyInfoAddressApartment.bind(
                            this
                          )}
                        />
                      </Form.Group>
                      <Form.Group as={Col} sm={8}>
                        <Form.Control
                          placeholder="Street Address"
                          value={emergencyInfo.address.street}
                          onChange={this.updateEmergencyInfoAddressStreet.bind(
                            this
                          )}
                        />
                      </Form.Group>
                    </Form.Row>
                    <Form.Row>
                      <Form.Group as={Col} sm={4}>
                        <Form.Control
                          placeholder="City"
                          value={emergencyInfo.address.city}
                          onChange={this.updateEmergencyInfoAddressCity.bind(
                            this
                          )}
                        />
                      </Form.Group>
                      <Form.Group as={Col} sm={4}>
                        <Form.Control
                          placeholder="State"
                          value={emergencyInfo.address.state}
                          onChange={this.updateEmergencyInfoAddressState.bind(
                            this
                          )}
                        />
                      </Form.Group>
                      <Form.Group as={Col} sm={4}>
                        <Form.Control
                          placeholder="Zip code"
                          value={emergencyInfo.address.zip}
                          onChange={this.updateEmergencyInfoAddressZip.bind(
                            this
                          )}
                        />
                      </Form.Group>
                    </Form.Row>
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                    Home Phone
                  </Form.Label>
                  <Col sm={4}>
                    <Form.Control
                      value={emergencyInfo.phone.primary}
                      onChange={this.updateEmergencyInfoPhonePrimary.bind(this)}
                    />
                  </Col>
                  <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                    Alternate Phone
                  </Form.Label>
                  <Col sm={4}>
                    <Form.Control
                      value={emergencyInfo.phone.secondary}
                      onChange={this.updateEmergencyInfoPhoneSecondary.bind(
                        this
                      )}
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                    Relationship
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      value={emergencyInfo.relation}
                      onChange={this.updateEmergencyInfoRelation.bind(this)}
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                    Guarantor
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Row>
                      <Form.Group as={Col} sm={2}>
                        <Form.Control
                          placeholder="Title"
                          value={emergencyInfo.guarantor.title}
                          onChange={this.updateEmergencyInfoGuarantorTitle.bind(
                            this
                          )}
                        />
                      </Form.Group>
                      <Form.Group as={Col} sm={5}>
                        <Form.Control
                          placeholder="Guarantor Name"
                          value={emergencyInfo.guarantor.name}
                          onChange={this.updateEmergencyInfoGuarantorName.bind(
                            this
                          )}
                        />
                      </Form.Group>
                      <Form.Group as={Col} sm={5}>
                        <Form.Control
                          placeholder="Guarantor Phone"
                          value={emergencyInfo.guarantor.phone}
                          onChange={this.updateEmergencyInfoGuarantorPhone.bind(
                            this
                          )}
                        />
                      </Form.Group>
                      <Form.Group as={Col} sm={12}>
                        <Form.Control
                          placeholder="Guarantor Address"
                          value={emergencyInfo.guarantor.address}
                          onChange={this.updateEmergencyInfoGuarantorAddress.bind(
                            this
                          )}
                        />
                      </Form.Group>
                    </Form.Row>
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                    Referee
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Row>
                      <Form.Group as={Col} sm={2}>
                        <Form.Control
                          placeholder="Title"
                          value={emergencyInfo.referee.title}
                          onChange={this.updateEmergencyInfoRefereeTitle.bind(
                            this
                          )}
                        />
                      </Form.Group>
                      <Form.Group as={Col} sm={5}>
                        <Form.Control
                          placeholder="Referee Name"
                          value={emergencyInfo.referee.name}
                          onChange={this.updateEmergencyInfoRefereeName.bind(
                            this
                          )}
                        />
                      </Form.Group>
                      <Form.Group as={Col} sm={5}>
                        <Form.Control
                          placeholder="Referee Phone"
                          value={emergencyInfo.referee.phone}
                          onChange={this.updateEmergencyInfoRefereePhone.bind(
                            this
                          )}
                        />
                      </Form.Group>
                      <Form.Group as={Col} sm={12}>
                        <Form.Control
                          placeholder="Referee Address"
                          value={emergencyInfo.referee.address}
                          onChange={this.updateEmergencyInfoRefereeAddress.bind(
                            this
                          )}
                        />
                      </Form.Group>
                    </Form.Row>
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                    Employee HMO
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      as="select"
                      value={emergencyInfo.employeeHMO}
                      onChange={this.updateEmergencyInfoEmployeeHMO.bind(this)}
                    >
                      <option value="">Select</option>
                      <option value="add_employeeHMO">Add New</option>
                      <option disabled>__________________</option>
                      {employeeHMOs?.map((employeeHMO) => {
                        return (
                          <option value={employeeHMO._id}>
                            {employeeHMO.name}
                          </option>
                        );
                      })}
                    </Form.Control>
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                    Group Life Insurance
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      as="select"
                      value={emergencyInfo.lifeInsurance}
                      onChange={this.updateEmergencyInfoLifeInsurance.bind(
                        this
                      )}
                    >
                      <option value="">Select</option>
                      <option value="add_lifeInsurance">Add New</option>
                      <option disabled>__________________</option>
                      {lifeInsurances?.map((lifeInsurance) => {
                        return (
                          <option value={lifeInsurance._id}>
                            {lifeInsurance.name}
                          </option>
                        );
                      })}
                    </Form.Control>
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                    Upload Employee Photo
                  </Form.Label>
                  <Col sm={10}>
                    <div className="file-upload-div">
                      <div className="file-preview">
                        <img
                          style={{ height: "120px", width: "110px" }}
                          src={displayImageSrc}
                        />
                      </div>

                      <div className="upload-btn-wrapper">
                        <button className="custom-upload-btn">Browse</button>
                        <input
                          type="file"
                          label="Upload File"
                          ref="emergency_image"
                          onChange={this.updateEmergencyInfoImage.bind(this)}
                        />
                      </div>
                    </div>
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                    Upload Employee Resume
                  </Form.Label>
                  <Col sm={10}>
                    <div className="file-upload-div">
                      <div className="file-preview">
                        <Document
                          file={displayResumeSrc}
                          onLoadSuccess={this.onDocumentLoadSuccess}
                          noData={
                            <div
                              style={{
                                width: "110px",
                                height: "120px",
                                border: "1px solid #bebebe",
                              }}
                            ></div>
                          }
                        >
                          <Page
                            pageNumber={pdfPageNumber}
                            height="120"
                            width="120"
                            className="change-pdf-size"
                          />
                        </Document>
                      </div>

                      <div className="upload-btn-wrapper">
                        <button className="custom-upload-btn">Browse</button>
                        <input
                          type="file"
                          label="Upload File"
                          ref="emergency_resume"
                          onChange={this.updateEmergencyInfoResume.bind(this)}
                        />
                      </div>
                    </div>
                  </Col>
                </Form.Group>
                <Form.Group
                  as={Row}
                  style={{
                    backgroundColor: "#2463ae",
                    alignItems: "center",
                    justifyContent: "center",
                    fontSize: "16px",
                    color: "#ffffff",
                    fontWeight: "bold",
                  }}
                >
                  Education Information
                </Form.Group>
                {educationInfo &&
                  educationInfo?.map((info, idx) => {
                    return (
                      <Form
                        style={{
                          border: "1px solid #e3e3e3",
                          borderRadius: "5px",
                          padding: "20px 10px",
                          margin: "10px",
                        }}
                      >
                        <Form.Group>Education Information</Form.Group>
                        <Form.Group as={Row}>
                          <Form.Label
                            style={{ fontSize: "14px" }}
                            column
                            sm={2}
                          >
                            Institution
                          </Form.Label>
                          <Col sm={4}>
                            <Form.Control
                              value={info.institution}
                              name="institution"
                              id={"institution" + idx}
                              onChange={this.handleEducationInfoField(idx)}
                            />
                          </Col>
                          <Form.Label
                            style={{ fontSize: "14px" }}
                            column
                            sm={2}
                          >
                            Course
                          </Form.Label>
                          <Col sm={4}>
                            <Form.Control
                              value={info.subject}
                              name="subject"
                              id={"subject" + idx}
                              onChange={this.handleEducationInfoField(idx)}
                            />
                          </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                          <Form.Label
                            style={{ fontSize: "14px" }}
                            column
                            sm={2}
                          >
                            Starting Date
                          </Form.Label>
                          <Col sm={4}>
                            <div classname="datePicker">
                              <i
                                class="fa fa-calendar fa-xs calendar-icon"
                                aria-hidden="true"
                              ></i>
                              <Datepicker
                                value={info.startDate}
                                name="startDate"
                                closeOnSelect={true}
                                id={"startDate" + idx}
                                onChange={this.handleEducationInfoStartField(
                                  idx
                                )}
                              />
                            </div>
                          </Col>
                          <Form.Label
                            style={{ fontSize: "14px" }}
                            column
                            sm={2}
                          >
                            Complete Date
                          </Form.Label>
                          <Col sm={4}>
                            <div classname="datePicker">
                              <i
                                class="fa fa-calendar fa-xs calendar-icon"
                                aria-hidden="true"
                              ></i>
                              <Datepicker
                                value={info.completeDate}
                                name="completeDate"
                                closeOnSelect={true}
                                id={"completeDate" + idx}
                                onChange={this.handleEducationInfoCompleteField(
                                  idx
                                )}
                              />
                            </div>
                          </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                          <Form.Label
                            style={{ fontSize: "14px" }}
                            column
                            sm={2}
                          >
                            Degree
                          </Form.Label>
                          <Col sm={4}>
                            <Form.Control
                              value={info.degree}
                              name="degree"
                              id={"degree" + idx}
                              onChange={this.handleEducationInfoField(idx)}
                            />
                          </Col>
                          <Form.Label
                            style={{ fontSize: "14px" }}
                            column
                            sm={2}
                          >
                            Grade
                          </Form.Label>
                          <Col sm={4}>
                            <Form.Control
                              value={info.grade}
                              name="grade"
                              id={"grade" + idx}
                              onChange={this.handleEducationInfoField(idx)}
                            />
                          </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                          <Form.Label
                            style={{ fontSize: "14px" }}
                            column
                            sm={2}
                          >
                            Upload Employee Certificate
                          </Form.Label>
                          <Col sm={10}>
                            <div className="file-upload-div">
                              <div className="file-preview">
                                <Document
                                  file={educationCertificateFiles[idx].file}
                                  onLoadSuccess={this.onDocumentLoadSuccess}
                                  noData={
                                    <div
                                      style={{
                                        width: "110px",
                                        height: "120px",
                                        border: "1px solid #bebebe",
                                      }}
                                    ></div>
                                  }
                                >
                                  <Page
                                    pageNumber={pdfPageNumber}
                                    height="120"
                                    width="120"
                                    className="change-pdf-size"
                                  />
                                </Document>
                              </div>

                              <div className="upload-btn-wrapper">
                                <button className="custom-upload-btn">
                                  Browse
                                </button>
                                <input
                                  type="file"
                                  label="Upload File"
                                  name="certificate"
                                  ref={"certificate_" + idx}
                                  onChange={this.handleEducationInfoFileField(
                                    idx
                                  )}
                                />
                              </div>
                            </div>
                            {/*<input type='file' label='Upload File' name='certificate' ref={'certificate_'+idx}*/}
                            {/*       onChange={this.handleEducationInfoField(idx)} />*/}
                          </Col>
                        </Form.Group>
                      </Form>
                    );
                  })}
                <div className="add-more">
                  <a
                    href="javascript:void(0);"
                    onClick={this.addMoreEducationInfo}
                  >
                    <i className="fa fa-plus-circle" /> Add More
                  </a>
                </div>
                <Form.Group
                  as={Row}
                  style={{
                    backgroundColor: "#2463ae",
                    alignItems: "center",
                    justifyContent: "center",
                    fontSize: "16px",
                    marginTop: "20px",
                    color: "#ffffff",
                    fontWeight: "bold",
                  }}
                >
                  Employee Bank Account Details
                </Form.Group>
                <Form.Group as={Row}>
                  <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                    Bank Name
                  </Form.Label>
                  <Col sm={4}>
                    <Form.Control
                      name="bankName"
                      value={bankDetails.bankName}
                      onChange={(e) => this.handleBankDetails(e)}
                    />
                  </Col>
                  <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                    Account Number
                  </Form.Label>
                  <Col sm={4}>
                    <Form.Control
                      name="accountNumber"
                      value={bankDetails.accountNumber}
                      onChange={(e) => this.handleBankDetails(e)}
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                    Account Type
                  </Form.Label>
                  <Col sm={4}>
                    <Form.Control
                      name="accountType"
                      value={bankDetails.accountType}
                      onChange={(e) => this.handleBankDetails(e)}
                    />
                  </Col>
                  <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                    Account Officer
                  </Form.Label>
                  <Col sm={4}>
                    <Form.Control
                      name="accountOfficer"
                      value={bankDetails.accountOfficer}
                      onChange={(e) => this.handleBankDetails(e)}
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                    Bank Code
                  </Form.Label>
                  <Col sm={4}>
                    <Form.Control
                      name="bankCode"
                      value={bankDetails.bankCode}
                      onChange={(e) => this.handleBankDetails(e)}
                    />
                  </Col>
                  <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                    Pension No
                  </Form.Label>
                  <Col sm={4}>
                    <Form.Control
                      name="pensionNumber"
                      value={bankDetails.pensionNumber}
                      onChange={(e) => this.handleBankDetails(e)}
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                    Pension Admin
                  </Form.Label>
                  <Col sm={4}>
                    <Form.Control
                      name="pensionAdmin"
                      onChange={(e) => this.handleBankDetails(e)}
                      value={bankDetails.pensionAdmin}
                    />
                  </Col>
                </Form.Group>
                <div>
                  <div className="submit-section">
                    <div
                      className="form-check"
                      style={{
                        height: "44px",
                        padding: "6px 0",
                        display: "inline-block",
                      }}
                    >
                      <input
                        style={{ height: "30px", width: "30px" }}
                        type="checkbox"
                        checked={grantAccess}
                        onChange={() => this.updateGrantAccess()}
                      />
                      <label
                        style={{ margin: "10px", verticalAlign: "bottom" }}
                        onClick={() => this.updateGrantAccess()}
                      >
                        Grant Login Access
                      </label>
                    </div>

                    <button className="btn add-btn">
                      {this.state.buttonText}
                    </button>
                  </div>
                </div>
              </Form>
              {this.state.show_add_job && (
                <AddJobGradeModal
                  updateJobGradesList={this.updateJobGradesList}
                  isShow={this.state.show_add_job}
                  changeShowJobState={this.changeShowJobState}
                  putJobGrade={this.putJobGrade}
                />
              )}
              {this.state.show_add_jobCategory && (
                <AddJobCategoryModal
                  updateJobCategoryList={this.updateJobCategoryList}
                  isShow={this.state.show_add_jobCategory}
                  changeShowJobState={this.changeShowJobCategoryState}
                />
              )}
              {this.state.show_add_location && (
                <AddLocationModal
                  updateLocationsList={this.updateLocationsList}
                  isShow={this.state.show_add_location}
                  changeShowLocationState={this.changeShowLocationState}
                />
              )}
              {this.state.show_add_employeeHMO && (
                <AddEmployeeHMOModal
                  updateEmployeeHMOsList={this.updateEmployeeHMOsList}
                  isShow={this.state.show_add_employeeHMO}
                  changeShowEmployeeHMOState={this.changeShowEmployeeHMOState}
                />
              )}
              {this.state.show_add_lifeInsurance && (
                <AddLifeInsuranceModal
                  updateLifeInsurancesList={this.updateLifeInsurancesList}
                  isShow={this.state.show_add_lifeInsurance}
                  changeShowLifeInsuranceState={
                    this.changeShowLifeInsuranceState
                  }
                />
              )}
              {this.state.show_add_department && (
                <AddEmployeeDepartmentModal
                  changeShowEmployeeDepartment={
                    this.changeShowEmployeeDepartment
                  }
                  isShow={this.state.show_add_department}
                  updateDepartmentsList={this.updateDepartmentsList}
                />
              )}
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}
