import React, { useEffect, useRef, useState } from "react";
// import $ from 'jquery';
import { Col, Form, Row, Modal } from "react-bootstrap";
import { Document, Page, pdfjs } from "react-pdf";
import Cookies from "universal-cookie";
import { toast } from "react-toastify";
import CurrencyInput from "react-currency-input-field";
import Select from "react-select";

import { URLS } from "../urls";
import "../../assets/css/employee.css";
import "../../assets/css/jobgrade.css";
import "../../../src/assets/css/date-picker.css";
import AddJobGradeModal from "./AddJobGradeModal";
import AddLocationModal from "./AddLocationModal";
import AddEmployeeHMOModal from "./AddEmployeeHMOModal";
import AddLifeInsuranceModal from "./AddLifeInsuranceModal";
import DatePicker from "react-datetime";
import moment from "moment";
import Multiselect from "./Multiselect";
import AddEmployeeDepartmentModal from "./AddEmployeeDepartment";
import { fetchActionsUtil, useCookie } from "../../utils";
import AddJobCategoryModal from "../job-category/AddJobCategory";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const EmployeeModal = (props) => {
  const cookies = new Cookies();
  const company_id = cookies.get("company_id");

  const branch_id = useCookie("branch_id");

  const [state, setState] = useState({
    show_add_job: false,
    show_add_location: false,
    show_add_employeeHMO: false,
    show_add_lifeInsurance: false,
    show_add_department: false,
    show_add_jobCategory: false,
    grantAccess: false,
    showBranch: false,
    _id: "",
    displayImageSrc: null,
    displayResumeSrc: null,
    pdfPageNumber: 1,
    pageHeight: 120,
    pageWidth: 120,
    buttonText: "Update",
    branch: "",
    branches: [],
    branchesLocations: [],
    isWaiting: false,
    isLineManager: false,
    personalInfo: {
      name: {
        first: "",
        last: "",
        middle: "",
      },
      address: {
        apartment: "",
        street: "",
        city: "",
        state: "",
        zip: "",
      },
      phone: {
        home: "",
        alternate: "",
      },
      email: "",
      password: "",
      birth: "",
      isAdmin: "",
      grantAccess: "",
      govtId: "",
      govtType: "",
      gender: "",
      martial: "Single",
      spouse: {
        name: "",
        employer: "",
        phone: "",
      },
      prevEmployee: {
        employee: "",
        phone: "",
      },
    },
    jobInfo: {
      category: "",
      title: "",
      employeeId: "",
      changeableId: "",
      grade: "",
      company_id: "",
      supervisor: [],
      department: "",
      workLocation: "",
      email: "",
      // password: "",
      workPhone: "",
      cellPhone: "",
      startDate: "",
      joinDate: "",
      salary: "",
      status: "",
      salaryBasis: "",
      contract: false,
      contractType: "",
      sfa_id: "",
      workLocationBranch: "",
      confirmDate: "",
      state: "",
    },
    emergencyInfo: {
      name: {
        first: "",
        last: "",
        middle: "",
      },
      address: {
        apartment: "",
        street: "",
        city: "",
        state: "",
        zip: "",
      },
      phone: {
        primary: "",
        secondary: "",
      },
      relation: "",
      guarantor: {
        title: "",
        name: "",
        phone: "",
        address: "",
      },
      referee: {
        title: "",
        name: "",
        phone: "",
        address: "",
      },
      employeeHMO: null,
      lifeInsurance: null,
      image: "",
      resume: "",
      certificate: "",
    },
    educationInfo: [
      {
        institution: "",
        subject: "",
        startDate: "",
        completeDate: "",
        degree: "",
        grade: "",
        certificate: "",
      },
    ],
    educationCertificateFiles: [
      {
        file: null,
      },
    ],
    user: {
      name: {
        first: "",
        last: "",
      },
      username: "",
      email: "",
      password: "",
      isAdmin: false,
      company_id: company_id,
      department_id: "",
      grantAccess: false,
    },

    bankDetails: {
      bankName: "",
      accountNumber: "",
      accountType: "",
      accountOfficer: "",
      bankCode: "",
      pensionNumber: "",
      pensionAdmin: "",
    },
    jobGrades: [],
    jobCategories: [],
    locations: [],
    departments: [],
    employeeHMOs: [],
    lifeInsurances: [],

    supervisors: [],
    companies: [],
    projects: [],
  });

  // const fetchAll = () => {
  //   const token = "dummy token";
  //   fetch(`${URLS.backendJobGrades}/get/${branch_id}`, {
  //     method: "GET",
  //     headers: {
  //       Accept: "application/json",
  //       "Content-Type": "application/json",
  //       Authorization: `Token ${token}`,
  //     },
  //   })
  //     .then((res) => res.json())
  //     .then((result) => {
  //       setState((el) => ({
  //         ...el,
  //         jobGrades: result.jobGrades,
  //       }));
  //     });

  //   fetch(`${URLS.backendJobCategory}/get/${branch_id}`, {
  //     method: "GET",
  //     headers: {
  //       Accept: "application/json",
  //       "Content-Type": "application/json",
  //       Authorization: `Token ${token}`,
  //     },
  //   })
  //     .then((res) => res.json())
  //     .then((result) => {
  //       setState((el) => ({
  //         ...el,
  //         jobCategories: result.jobCategories,
  //       }));
  //     });

  //   fetch(`${URLS.backendDepartments}/get/${branch_id}`, {
  //     method: "GET",
  //     headers: {
  //       Accept: "application/json",
  //       "Content-Type": "application/json",
  //       Authorization: `Token ${token}`,
  //     },
  //   })
  //     .then((res) => res.json())
  //     .then((result) => {
  //       setState((el) => ({
  //         ...el,
  //         departments: result.departments,
  //       }));
  //     });

  //   fetch(`${URLS.backendLocations}/get`, {
  //     method: "GET",
  //     headers: {
  //       Accept: "application/json",
  //       "Content-Type": "application/json",
  //       Authorization: `Token ${token}`,
  //     },
  //   })
  //     .then((res) => res.json())
  //     .then((result) => {
  //       setState((el) => ({
  //         ...el,
  //         locations: result.locations,
  //       }));
  //     });

  //   fetch(`${URLS.backendEmployeeHMOs}/get`, {
  //     method: "GET",
  //     headers: {
  //       Accept: "application/json",
  //       "Content-Type": "application/json",
  //       Authorization: `Token ${token}`,
  //     },
  //   })
  //     .then((res) => res.json())
  //     .then((result) => {
  //       setState((el) => ({
  //         ...el,
  //         employeeHMOs: result.employeeHMOs,
  //       }));
  //     });

  //   fetch(`${URLS.backendLifeInsurances}/get`, {
  //     method: "GET",
  //     headers: {
  //       Accept: "application/json",
  //       "Content-Type": "application/json",
  //       Authorization: `Token ${token}`,
  //     },
  //   })
  //     .then((res) => res.json())
  //     .then((result) => {
  //       setState((el) => ({
  //         ...el,
  //         lifeInsurances: result.lifeInsurances,
  //       }));
  //     });
  //   fetch(`${URLS.backendEmployees}/supervisors`, {
  //     method: "POST",
  //     headers: {
  //       Accept: "application/json",
  //       "Content-Type": "application/json",
  //       Authorization: `Token ${token}`,
  //     },
  //   })
  //     .then((res) => res.json())
  //     .then((result) => {
  //       if (result.supervisors) {
  //         setState((el) => ({
  //           ...el,
  //           supervisors: result.supervisors,
  //         }));
  //       }
  //     });

  //   fetch(`${URLS.backendCompanies}/get`, {
  //     method: "GET",
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: `Token ${token}`,
  //     },
  //   })
  //     .then((res) => res.json())
  //     .then((result) => {
  //       if (result.companies) {
  //         setState((el) => ({
  //           ...el,
  //           companies: result.companies,
  //         }));
  //       }
  //     });

  //   fetch(`${URLS.backendContracts}/get/${branch_id}`, {
  //     method: "GET",
  //     headers: {
  //       Accept: "application/json",
  //       "Content-Type": "application/json",
  //       Authorization: `Token ${token}`,
  //     },
  //   })
  //     .then((res) => res.json())
  //     .then((result) => {
  //       setState((el) => ({
  //         ...el,
  //         projects: result.contracts,
  //       }));
  //     });

  //   fetch(`${URLS.backendBranch}/get`, {
  //     method: "GET",
  //     headers: {
  //       Accept: "application/json",
  //       "Content-Type": "application/json",
  //       Authorization: `Token ${token}`,
  //     },
  //   })
  //     .then((res) => res.json())
  //     .then((result) => {
  //       result = result.branches.map((el) => ({
  //         value: el._id,
  //         label: el.name,
  //       }));
  //       setState((el) => ({
  //         ...el,
  //         branches: result,
  //       }));
  //     });
  // };

  const fetchAll = async () => {
    const jobGradesData = fetchActionsUtil(
      `${URLS.backendJobGrades}/get/${branch_id}`
    );

    const jobCategoriesData = fetchActionsUtil(
      `${URLS.backendJobCategory}/get/${branch_id}`
    );

    const departmentsData = fetchActionsUtil(
      `${URLS.backendDepartments}/get/${branch_id}`
    );

    const locationsData = fetchActionsUtil(`${URLS.backendLocations}/get`);

    const employeeHMOsData = fetchActionsUtil(
      `${URLS.backendEmployeeHMOs}/get`
    );

    const lifeInsurancesData = fetchActionsUtil(
      `${URLS.backendLifeInsurances}/get`
    );

    const supervisorsData = fetchActionsUtil(
      `${URLS.backendEmployees}/supervisors`,
      "POST"
    );

    const companiesData = fetchActionsUtil(`${URLS.backendCompanies}/get`);

    const projectsData = fetchActionsUtil(
      `${URLS.backendContracts}/get/${branch_id}`
    );

    const branchesData = fetchActionsUtil(`${URLS.backendBranch}/get`);

    const [
      jobGradesResult,
      jobCategoriesResult,
      departmentsResult,
      locationsResult,
      employeeHMOsResult,
      lifeInsurancesResult,
      supervisorsResult,
      companiesResult,
      projectsResult,
      branchesResult,
    ] = await Promise.all([
      jobGradesData,
      jobCategoriesData,
      departmentsData,
      locationsData,
      employeeHMOsData,
      lifeInsurancesData,
      supervisorsData,
      companiesData,
      projectsData,
      branchesData,
    ]);

    const mapBranches = branchesResult?.branches.map((el) => ({
      value: el._id,
      label: el.name,
    }));

    setState((el) => ({
      ...el,
      jobGrades: jobGradesResult.jobGrades,
      jobCategories: jobCategoriesResult.jobCategories,
      departments: departmentsResult.departments,
      locations: locationsResult.locations,
      employeeHMOs: employeeHMOsResult.employeeHMOs,
      lifeInsurances: lifeInsurancesResult.lifeInsurances,
      supervisors: supervisorsResult.supervisors,
      companies: companiesResult.companies,
      projects: projectsResult.contracts,
      branches: mapBranches,
    }));
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      fetchAll();
      UpdateEmployeeDetails();
    }

    return () => {
      isMounted = false;
    };
  }, [props.employee, props.user]);

  const UpdateEmployeeDetails = () => {
    if (Object.keys(props.employee).length) {
      const br = state.locations?.find(
        (el) => el._id === props?.employee?.jobInfo?.workLocation?._id
      );

      setState((el) => ({
        ...el,
        isLineManager: props.employee?.isLineManager,
        personalInfo: {
          ...state.personalInfo,
          ...props.employee.personalInfo,
        },
        jobInfo: {
          ...state.jobInfo,
          ...props.employee.jobInfo,
          grade: props.employee?.jobInfo?.grade?._id || "",
          workLocation: props?.employee?.jobInfo?.workLocation?._id,
          department: props.employee.jobInfo?.department?._id,
        },
        emergencyInfo: props.employee?.emergencyInfo
          ? props.employee.emergencyInfo
          : state.emergencyInfo,
        educationInfo: props.employee.educationInfo,
        _id: props.employee._id,
        bankDetails: props.employee.bankDetails
          ? props.employee.bankDetails
          : state.bankDetails,
        branch: props.employee.branch,
        branchesLocations: br?.branches,
      }));

      const educationInfo = props.employee.educationInfo;
      const educationInfoLength = educationInfo.length;
      let newEducationCertificateFiles = [];
      for (let index = 0; index < educationInfoLength; index++) {
        newEducationCertificateFiles.push({ file: null });
      }
      setState((el) => ({
        ...el,
        educationCertificateFiles: newEducationCertificateFiles,
      }));
    }

    if (props.user && Object.keys(props.user).length) {
      const user = props.user;
      const isAdmin = user.isAdmin;
      const isSuperAdmin = user.isSuperAdmin;
      const grantAccess = user.grantAccess;
      setState((el) => ({ ...el, isAdmin, isSuperAdmin, user, grantAccess }));
    }
  };

  const updateUserBranch = (branch) => {
    setState((el) => ({
      ...el,
      branch,
    }));
  };

  const changeShowJobState = () => {
    const { show_add_job } = state;
    setState((el) => ({
      ...el,
      show_add_job: !show_add_job,
    }));
  };

  const changeShowJobCategoryState = () => {
    const { show_add_jobCategory } = state;
    setState((el) => ({
      ...el,
      show_add_jobCategory: !show_add_jobCategory,
    }));
  };

  const changeShowLocationState = () => {
    const { show_add_location } = state;
    setState((el) => ({
      ...el,
      show_add_location: !show_add_location,
    }));
  };
  const changeShowEmployeeHMOState = () => {
    const { show_add_employeeHMO } = state;
    setState((el) => ({
      ...el,
      show_add_employeeHMO: !show_add_employeeHMO,
    }));
  };
  const changeShowLifeInsuranceState = () => {
    const { show_add_lifeInsurance } = state;
    setState((el) => ({
      ...el,
      show_add_lifeInsurance: !show_add_lifeInsurance,
    }));
  };

  const changeShowEmployeeDepartment = () => {
    const { show_add_department } = state;
    setState((el) => ({
      ...el,
      show_add_department: !show_add_department,
    }));
  };

  const updateJobGradesList = (item) => {
    const { jobGrades, jobInfo } = state;
    jobGrades.push(item);
    jobInfo.grade = item._id;
    setState((el) => ({
      ...el,
      jobGrades,
      jobInfo,
    }));
  };

  const updateJobCategoryList = (item) => {
    const { jobInfo, jobCategories } = state;
    jobCategories.push(item);
    jobInfo.category = item._id;
    setState((el) => ({
      ...el,
      jobInfo,
      jobCategories,
    }));
    updateIsLineMangerToTrue(item.name);
  };

  const updateIsLineMangerToTrue = (item) => {
    let { isLineManager } = state;

    if (item?.toLowerCase() === "expatriate") {
      isLineManager = true;
    }

    setState((el) => ({
      ...el,
      isLineManager,
    }));
  };

  const updateLocationsList = (item) => {
    const { locations, jobInfo } = state;
    locations.push(item);
    jobInfo.workLocation = item._id;
    const bra = item.branches;
    setState((el) => ({
      ...el,
      locations,
      branchesLocations: bra,
    }));
  };

  const updateDepartmentsList = (item) => {
    const { departments, jobInfo } = state;
    departments.push(item);
    jobInfo.department = item._id;
    setState((el) => ({
      ...el,
      departments,
      jobInfo,
    }));
  };

  const updateEmployeeHMOsList = (item) => {
    const { employeeHMOs, emergencyInfo } = state;
    employeeHMOs.push(item);
    emergencyInfo.employeeHMO = item._id;
    setState((el) => ({
      ...el,
      employeeHMOs,
      emergencyInfo,
    }));
  };
  const updateLifeInsurancesList = (item) => {
    const { lifeInsurances, emergencyInfo } = state;
    lifeInsurances.push(item);
    emergencyInfo.lifeInsurance = item._id;
    setState((el) => ({
      ...el,
      lifeInsurances,
      emergencyInfo,
    }));
  };

  const handleEducationInfoField = (idx, evt) => {
    const newEducationInfo = state.educationInfo.map((info, eIdx) => {
      if (idx !== eIdx) return info;
      let tName = evt.target.name;
      const value = evt.target.value;
      return { ...info, [tName]: value };
    });
    setState((el) => ({ ...el, educationInfo: newEducationInfo }));
  };

  const handleEducationInfoCompleteField = (idx, evt) => {
    const newEducationInfo = state.educationInfo.map((info, eIdx) => {
      if (idx !== eIdx) return info;
      let tName = "completeDate";
      const value = evt.target
        ? evt.target.value
        : moment(evt).format("DD/MM/YYYY");
      return { ...info, [tName]: value };
    });
    setState((el) => ({ ...el, educationInfo: newEducationInfo }));
  };

  const handleEducationInfoStartField = (idx, evt) => {
    const newEducationInfo = state.educationInfo.map((info, eIdx) => {
      if (idx !== eIdx) return info;
      let tName = "startDate";
      const value = evt.target
        ? evt.target.value
        : moment(evt).format("DD/MM/YYYY");
      return { ...info, [tName]: value };
    });
    setState((el) => ({ ...el, educationInfo: newEducationInfo }));
  };

  const eduRef = useRef();

  const handleEducationInfoFileField = (idx) => {
    const file = eduRef.current?.files[0] ? eduRef.current?.files[0] : null;

    if (file !== null) {
      const { educationInfo, educationCertificateFiles } = state;
      let currentDate = new Date();
      currentDate = currentDate.getTime();
      educationInfo[idx].certificate = currentDate + ".pdf";
      educationCertificateFiles[idx].file = file;
      setState((el) => ({
        ...el,
        educationInfo: educationInfo,
        educationCertificateFiles: educationCertificateFiles,
      }));
    }
  };
  const addMoreEducationInfo = () => {
    setState((el) => ({
      ...el,
      educationInfo: state.educationInfo.concat({
        institution: "",
        subject: "",
        startDate: "",
        completeDate: "",
        degree: "",
        grade: "",
        certificate: "",
      }),
      educationCertificateFiles: state.educationCertificateFiles.concat({
        file: null,
      }),
    }));
  };

  const updatePersonalInfoFirstName = (event) => {
    const { personalInfo, user } = state;
    personalInfo.name.first = event.target.value;
    user.name.first = event.target.value;
    setState((el) => ({
      ...el,
      personalInfo,
      user,
    }));
  };
  const updatePersonalInfoLastName = (event) => {
    const { personalInfo, user } = state;
    personalInfo.name.last = event.target.value;
    user.name.last = event.target.value;
    setState((el) => ({
      ...el,
      personalInfo,
      user,
    }));
  };
  const updatePersonalInfoMiddleName = (event) => {
    const { personalInfo } = state;
    personalInfo.name.middle = event.target.value;
    setState((el) => ({
      ...el,
      personalInfo,
    }));
  };

  const updatePersonalInfoAddressApartment = (event) => {
    const { personalInfo } = state;
    personalInfo.address.apartment = event.target.value;
    setState((el) => ({
      ...el,
      personalInfo,
    }));
  };
  const updatePersonalInfoAddressStreet = (event) => {
    const { personalInfo } = state;
    personalInfo.address.street = event.target.value;
    setState((el) => ({
      ...el,
      personalInfo,
    }));
  };
  const updatePersonalInfoAddressCity = (event) => {
    const { personalInfo } = state;
    personalInfo.address.city = event.target.value;
    setState((el) => ({
      ...el,
      personalInfo,
    }));
  };
  const updatePersonalInfoAddressState = (event) => {
    const { personalInfo } = state;
    personalInfo.address.state = event.target.value;
    setState((el) => ({
      ...el,
      personalInfo,
    }));
  };
  const updatePersonalInfoAddressZip = (event) => {
    const { personalInfo } = state;
    personalInfo.address.zip = event.target.value;
    setState((el) => ({
      ...el,
      personalInfo,
    }));
  };
  const updatePersonalInfoPhoneHome = (event) => {
    const { personalInfo } = state;
    // personalInfo.phone.home = event?.target?.value;
    personalInfo.phone = { home: event?.target?.value };
    setState((el) => ({
      ...el,
      personalInfo,
    }));
  };
  const updatePersonalInfoPhoneAlternate = (event) => {
    const { personalInfo } = state;
    personalInfo.phone.alternate = event.target.value;
    setState((el) => ({
      ...el,
      personalInfo,
    }));
  };
  const updatePersonalInfoEmail = (event) => {
    const { personalInfo } = state;
    personalInfo.email = event.target.value;
    setState((el) => ({
      ...el,
      personalInfo,
    }));
  };
  // const updatePersonalInfoPassword = (event) => {
  //   const { personalInfo } = state;
  //   personalInfo.password = event.target.value;
  //   setState((el) => ({
  //     ...el,
  //     personalInfo,
  //   }));
  // };
  const updatePersonalInfoIsAdmin = (type) => {
    let { personalInfo, user, isAdmin, isSuperAdmin } = state;
    let admin = isAdmin;
    let SuperAdmin = isSuperAdmin;
    if (type === "admin") {
      // isSuperAdmin = false;
      admin = !isAdmin;
      user.isAdmin = !isAdmin;
      // user.isSuperAdmin = isSuperAdmin;
      personalInfo.isAdmin = !isAdmin;
    } else if (type === "superAdmin") {
      // isAdmin = false;
      SuperAdmin = !isSuperAdmin;
      user.isSuperAdmin = !isSuperAdmin;
      // user.isAdmin = isAdmin;
      // personalInfo.isAdmin = !isSuperAdmin;
      personalInfo.isSuperAdmin = !isSuperAdmin;
    }

    setState((el) => ({
      ...el,
      personalInfo,
      user,
      isAdmin: admin,
      isSuperAdmin: SuperAdmin,
    }));
  };
  const updatePersonalInfoGovtId = (event) => {
    const { personalInfo } = state;
    personalInfo.govtId = event.target.value;
    setState((el) => ({
      ...el,
      personalInfo,
    }));
  };

  const updatePersonalInfoGovtType = (event) => {
    const { personalInfo } = state;
    personalInfo.govtType = event.target.value;
    setState((el) => ({
      ...el,
      personalInfo,
    }));
  };

  const updatePersonalGender = (event) => {
    const { personalInfo } = state;
    personalInfo.gender = event.target.value;
    setState((el) => ({
      ...el,
      personalInfo,
    }));
  };
  const updatePersonalInfoBirth = (event) => {
    const { personalInfo } = state;
    personalInfo.birth = event.target
      ? event.target.value
      : moment(event).format("DD/MM/YYYY");
    setState((el) => ({
      ...el,
      personalInfo,
    }));
  };
  const updatePersonalInfoMartial = (event) => {
    const { personalInfo } = state;
    personalInfo.martial = event.target.value;
    setState((el) => ({
      ...el,
      personalInfo,
    }));
  };
  const updatePersonalInfoSpouseName = (event) => {
    const { personalInfo } = state;
    personalInfo.spouse.name = event.target.value;
    setState((el) => ({
      ...el,
      personalInfo,
    }));
  };
  const updatePersonalInfoSpouseEmployer = (event) => {
    const { personalInfo } = state;
    personalInfo.spouse.employer = event.target.value;
    setState((el) => ({
      ...el,
      personalInfo,
    }));
  };
  const updatePersonalInfoSpousePhone = (event) => {
    const { personalInfo } = state;
    personalInfo.spouse.phone = event.target.value;
    setState((el) => ({
      ...el,
      personalInfo,
    }));
  };
  const updatePersonalInfoPrevEmployeeEmployee = (event) => {
    const { personalInfo } = state;
    personalInfo.prevEmployee.employee = event.target.value;
    setState((el) => ({
      ...el,
      personalInfo,
    }));
  };
  const updatePersonalInfoPrevEmployeePhone = (event) => {
    const { personalInfo } = state;
    personalInfo.prevEmployee.phone = event.target.value;
    setState((el) => ({
      ...el,
      personalInfo,
    }));
  };
  const updateJobInfoTitle = (event) => {
    const { jobInfo } = state;
    jobInfo.title = event.target.value;
    setState((el) => ({
      ...el,
      jobInfo,
    }));
  };
  const updateJobInfoEmployeeId = (event) => {
    const { jobInfo } = state;
    // jobInfo.employeeId = event.target.value;
    jobInfo.changeableId = event.target.value;
    setState((el) => ({
      ...el,
      jobInfo,
    }));
  };
  const updateJobInfoGrade = (event) => {
    const { jobInfo, jobGrades } = state;
    const value = event.target.value;
    if (value === "add_job") {
      // $('#add_job').show();
      const { show_add_job } = state;
      setState((el) => ({
        ...el,
        show_add_job: !show_add_job,
      }));
    } else {
      const grade = jobGrades.filter((grades) => grades._id === value);
      const gradetotal = Object.entries(grade[0].salary).reduce(
        (c, p) => c + p[1],
        0
      );
      const totalcustom =
        grade[0]?.custom?.length > 0
          ? grade[0]?.custom?.reduce(
              (c, p) => parseFloat(c) + parseFloat(p?.value),
              0
            )
          : 0;
      // console.log(grade);

      jobInfo.salary = gradetotal + totalcustom;
      jobInfo.grade = value;
      setState((el) => ({
        ...el,
        jobInfo,
      }));
    }
  };

  const updateJobInfoCategory = (event) => {
    const { jobInfo } = state;
    let value = event.target.value;

    if (value === "add_jobCategory") {
      const { show_add_jobCategory } = state;
      setState((el) => ({
        ...el,
        show_add_jobCategory: !show_add_jobCategory,
      }));
    } else {
      jobInfo.category = value;

      setState((el) => ({
        ...el,
        jobInfo,
      }));

      // updateIsLineMangerToTrue(value?.name);
    }
  };

  const updateIsLineManger = () => {
    const { isLineManager } = state;
    setState((el) => ({
      ...el,
      isLineManager: !isLineManager,
    }));
  };

  const updateJobInfoCompanyId = (event) => {
    const { jobInfo } = state;
    jobInfo.company_id = event.target.value;
    setState((el) => ({
      ...el,
      jobInfo,
    }));
  };
  const updateJobInfoSupervisor = (params) => {
    const { jobInfo } = state;
    jobInfo.supervisor = params.map((data) => {
      return data.id;
    });
    setState((el) => ({
      ...el,
      jobInfo,
    }));
  };
  const updateJobInfoDepartment = (event) => {
    const { jobInfo, show_add_department } = state;
    const value = event.target.value;
    if (value === "add_department") {
      setState((el) => ({
        ...el,
        show_add_department: !show_add_department,
      }));
    } else {
      jobInfo.department = event.target.value;
      setState((el) => ({
        ...el,
        jobInfo,
      }));
    }
  };
  const updateJobInfoWorkLocation = (event) => {
    const { jobInfo, locations } = state;
    const value = event.target.value;
    if (value === "add_location") {
      const { show_add_location } = state;
      setState((el) => ({
        ...el,
        show_add_location: !show_add_location,
      }));
    } else {
      let branchesLocations = locations.find((el) => el._id === value);
      branchesLocations = branchesLocations?.branches;
      jobInfo.workLocation = value;
      jobInfo.workLocationBranch = "";
      setState((el) => ({
        ...el,
        jobInfo,
        branchesLocations,
      }));
    }
  };

  const updateJobInfoWorkLocationBranch = (event) => {
    const { jobInfo, branchesLocations } = state;
    const value = event.target.value;
    jobInfo.workLocationBranch = value;
    let theState = branchesLocations.find((el) => el.name === value);
    theState = theState?.state;
    jobInfo.state = theState;
    setState((el) => ({
      ...el,
      jobInfo,
    }));
  };

  const updateJobInfoEmail = (event) => {
    const { jobInfo } = state;
    jobInfo.email = event.target.value;
    setState((el) => ({
      ...el,
      jobInfo,
    }));
  };
  const updateJobInfoPassword = (event) => {
    const { user } = state;
    // jobInfo.password = event.target.value;
    user.password = event.target.value;
    setState((el) => ({ ...el, user }));
  };
  const updateJobInfoWorkPhone = (event) => {
    const { jobInfo } = state;
    jobInfo.workPhone = event.target.value;
    setState((el) => ({
      ...el,
      jobInfo,
    }));
  };
  const updateJobInfoCellPhone = (event) => {
    const { jobInfo } = state;
    jobInfo.cellPhone = event.target.value;
    setState((el) => ({
      ...el,
      jobInfo,
    }));
  };
  const updateSFAID = (event) => {
    const { jobInfo } = state;
    jobInfo.sfa_id = event.target.value;
    setState((el) => ({
      ...el,
      jobInfo,
    }));
  };
  const updateJobInfoStartDate = (event) => {
    const { jobInfo } = state;
    jobInfo.startDate = event.target
      ? event.target.value
      : moment(event).format("DD/MM/YYYY");
    setState((el) => ({
      ...el,
      jobInfo,
    }));
  };

  const updateJobInfoJoinDate = (event) => {
    const { jobInfo } = state;
    jobInfo.joinDate = event.target
      ? event.target.value
      : moment(event).format("DD/MM/YYYY");
    setState((el) => ({
      ...el,
      jobInfo,
    }));
  };

  const validationEndDate = (currentDate) => {
    const { jobInfo } = state;
    return currentDate.isAfter(
      moment(jobInfo.joinDate, "DD/MM/YYYY").add(-1, "days")
    );
  };

  const updateJobInfoSalary = (value) => {
    const { jobInfo } = state;
    jobInfo.salary = value;
    setState((el) => ({
      ...el,
      jobInfo,
    }));
  };

  const putJobGrade = (item) => {
    const { jobInfo } = state;
    jobInfo.salary = item;
    setState((el) => ({
      ...el,
      jobInfo,
    }));
  };

  const updateJobInfoStatus = (event) => {
    const { jobInfo } = state;
    jobInfo.status = event.target.value;
    setState((el) => ({
      ...el,
      jobInfo,
    }));
  };
  const updateJobInfoSalaryBasis = (event) => {
    const { jobInfo } = state;
    jobInfo.salaryBasis = event.target.value;
    setState((el) => ({
      ...el,
      jobInfo,
    }));
  };
  const updateJobInfoContract = (event) => {
    const { jobInfo } = state;
    jobInfo.contract = event.target.checked;
    setState((el) => ({
      ...el,
      jobInfo,
    }));
  };
  const updateJobInfoContractType = (event) => {
    const { jobInfo } = state;
    jobInfo.contractType = event.target.value;
    setState((el) => ({
      ...el,
      jobInfo,
    }));
  };
  const updateEmergencyInfoNameFirst = (event) => {
    const { emergencyInfo } = state;
    emergencyInfo.name.first = event.target.value;
    setState((el) => ({
      ...el,
      emergencyInfo,
    }));
  };
  const updateEmergencyInfoNameLast = (event) => {
    const { emergencyInfo } = state;
    emergencyInfo.name.last = event.target.value;
    setState((el) => ({
      ...el,
      emergencyInfo,
    }));
  };
  const updateEmergencyInfoNameMiddle = (event) => {
    const { emergencyInfo } = state;
    emergencyInfo.name.middle = event.target.value;
    setState((el) => ({
      ...el,
      emergencyInfo,
    }));
  };
  const updateEmergencyInfoAddressApartment = (event) => {
    const { emergencyInfo } = state;
    emergencyInfo.address.apartment = event.target.value;
    setState((el) => ({
      ...el,
      emergencyInfo,
    }));
  };
  const updateEmergencyInfoAddressStreet = (event) => {
    const { emergencyInfo } = state;
    emergencyInfo.address.street = event.target.value;
    setState((el) => ({
      ...el,
      emergencyInfo,
    }));
  };
  const updateEmergencyInfoAddressCity = (event) => {
    const { emergencyInfo } = state;
    emergencyInfo.address.city = event.target.value;
    setState((el) => ({
      ...el,
      emergencyInfo,
    }));
  };
  const updateEmergencyInfoAddressState = (event) => {
    const { emergencyInfo } = state;
    emergencyInfo.address.state = event.target.value;
    setState((el) => ({
      ...el,
      emergencyInfo,
    }));
  };
  const updateEmergencyInfoAddressZip = (event) => {
    const { emergencyInfo } = state;
    emergencyInfo.address.zip = event.target.value;
    setState((el) => ({
      ...el,
      emergencyInfo,
    }));
  };
  const updateEmergencyInfoPhonePrimary = (event) => {
    const { emergencyInfo } = state;
    emergencyInfo.phone.primary = event.target.value;
    setState((el) => ({
      ...el,
      emergencyInfo,
    }));
  };
  const updateEmergencyInfoPhoneSecondary = (event) => {
    const { emergencyInfo } = state;
    emergencyInfo.phone.secondary = event.target.value;
    setState((el) => ({
      ...el,
      emergencyInfo,
    }));
  };
  const updateEmergencyInfoRelation = (event) => {
    const { emergencyInfo } = state;
    emergencyInfo.relation = event.target.value;
    setState((el) => ({
      ...el,
      emergencyInfo,
    }));
  };
  const updateEmergencyInfoGuarantorTitle = (event) => {
    const { emergencyInfo } = state;
    emergencyInfo.guarantor.title = event.target.value;
    setState((el) => ({
      ...el,
      emergencyInfo,
    }));
  };
  const updateEmergencyInfoGuarantorName = (event) => {
    const { emergencyInfo } = state;
    emergencyInfo.guarantor.name = event.target.value;
    setState((el) => ({
      ...el,
      emergencyInfo,
    }));
  };
  const updateEmergencyInfoGuarantorPhone = (event) => {
    const { emergencyInfo } = state;
    emergencyInfo.guarantor.phone = event.target.value;
    setState((el) => ({
      ...el,
      emergencyInfo,
    }));
  };
  const updateEmergencyInfoGuarantorAddress = (event) => {
    const { emergencyInfo } = state;
    emergencyInfo.guarantor.address = event.target.value;
    setState((el) => ({
      ...el,
      emergencyInfo,
    }));
  };
  const updateEmergencyInfoRefereeTitle = (event) => {
    const { emergencyInfo } = state;
    emergencyInfo.referee.title = event.target.value;
    setState((el) => ({
      ...el,
      emergencyInfo,
    }));
  };
  const updateEmergencyInfoRefereeName = (event) => {
    const { emergencyInfo } = state;
    emergencyInfo.referee.name = event.target.value;
    setState((el) => ({
      ...el,
      emergencyInfo,
    }));
  };
  const updateEmergencyInfoRefereePhone = (event) => {
    const { emergencyInfo } = state;
    emergencyInfo.referee.phone = event.target.value;
    setState((el) => ({
      ...el,
      emergencyInfo,
    }));
  };
  const updateEmergencyInfoRefereeAddress = (event) => {
    const { emergencyInfo } = state;
    emergencyInfo.referee.address = event.target.value;
    setState((el) => ({
      ...el,
      emergencyInfo,
    }));
  };
  const updateEmergencyInfoEmployeeHMO = (event) => {
    const { emergencyInfo } = state;
    const value = event.target.value;
    if (value === "add_employeeHMO") {
      const { show_add_employeeHMO } = state;
      setState((el) => ({
        ...el,
        show_add_employeeHMO: !show_add_employeeHMO,
      }));
    } else {
      emergencyInfo.employeeHMO = value;
      setState((el) => ({
        ...el,
        emergencyInfo,
      }));
    }
  };
  const updateEmergencyInfoLifeInsurance = (event) => {
    const { emergencyInfo } = state;
    const value = event.target.value;
    if (value === "add_lifeInsurance") {
      const { show_add_lifeInsurance } = state;
      setState((el) => ({
        ...el,
        show_add_lifeInsurance: !show_add_lifeInsurance,
      }));
    } else {
      emergencyInfo.lifeInsurance = value;
      setState((el) => ({
        ...el,
        emergencyInfo,
      }));
    }
  };

  const emergencyRef = useRef();
  const updateEmergencyInfoImage = (event) => {
    const { emergencyInfo } = state;
    let currentDate = new Date();
    currentDate = currentDate.getTime();
    //currentDate = currentDate.toUTCString();
    // currentDate = currentDate.replace(/ /g, '_');
    // if (emergencyInfo?.image) {
    emergencyInfo.image = currentDate + ".png";
    // }

    setState((el) => ({
      ...el,
      emergencyInfo,
    }));
    // let file = this.refs.emergency_image.files[0];
    let file = emergencyRef.current?.emergency_image.files[0];
    let reader = new FileReader();
    let url = reader.readAsDataURL(file);

    reader.onloadend = function () {
      console.log([reader.result]);
      setState((el) => ({
        ...el,
        displayImageSrc: [reader.result],
      }));
    };
  };
  const updateEmergencyInfoResume = (event) => {
    const { emergencyInfo } = state;
    let currentDate = new Date();
    currentDate = currentDate.getTime();
    emergencyInfo.resume = currentDate + ".pdf";
    setState((el) => ({
      ...el,
      emergencyInfo,
    }));
    setState((el) => ({
      ...el,
      displayResumeSrc: event.target.files[0],
    }));
  };
  const onDocumentLoadSuccess = (e) => {
    console.log("success", e);
  };
  const updateEmergencyInfoCertificate = (event) => {
    const { emergencyInfo } = state;
    let currentDate = new Date();
    currentDate = currentDate.toUTCString();
    currentDate = currentDate.replace(/ /g, "_");
    emergencyInfo.certificate = currentDate + ".pdf";
    setState((el) => ({
      ...el,
      emergencyInfo,
    }));
  };

  const updateJobInfoConfirmDate = (event) => {
    const { jobInfo } = state;
    jobInfo.confirmDate = event.target
      ? event.target.value
      : moment(event).format("DD/MM/YYYY");
    setState((el) => ({
      ...el,
      jobInfo,
    }));
  };

  const resumeRef = useRef();

  const submit = (e) => {
    setState((el) => ({
      ...el,
      buttonText: "Submitting ...",
      isWaiting: true,
    }));
    e.preventDefault();
    const {
      educationInfo,
      personalInfo,
      jobInfo,
      emergencyInfo,
      _id,
      educationCertificateFiles,
      user,
      bankDetails,
      isAdmin,
      isSuperAdmin,
      grantAccess,
      branch,
      isLineManager,
      departments,
    } = state;

    user.workEmail = jobInfo.email;
    user.Staff_ID = jobInfo.employeeId;
    user.branch = branch;
    user.email = jobInfo.email;
    if (jobInfo.startDate === "" || jobInfo.startDate === undefined) {
      setState((el) => ({ ...el, buttonText: "Update", isWaiting: false }));
      alert("Start Date is needed");
      return;
    }

    if (jobInfo.joinDate === "" || jobInfo.joinDate === undefined) {
      setState((el) => ({ ...el, buttonText: "Submit", isWaiting: false }));
      alert("Joint Date is needed");
      return;
    }

    if (
      jobInfo.grade === null ||
      jobInfo.grade === "" ||
      jobInfo.grade === undefined
    ) {
      setState((el) => ({ ...el, buttonText: "Submit", isWaiting: false }));
      alert("Job Grade is needed");
      return;
    }

    if (jobInfo.status === "Confirmed" && jobInfo.confirmDate === "") {
      setState((el) => ({ ...el, buttonText: "Submit", isWaiting: false }));
      alert("Confirm Date is needed");
      return;
    }

    const mainEmployee = {
      EmployeeID: jobInfo?.employeeId,
      changeableId: jobInfo?.changeableId || jobInfo?.employeeId,
      LastName: personalInfo?.name?.last,
      FirstName: personalInfo?.name?.first,
      MiddleName: personalInfo?.name?.middle,
      ContactAddress: `${personalInfo?.address?.apartment}, ${personalInfo?.address?.city}, ${personalInfo?.address?.state}`,
      PhoneNumber: personalInfo?.phone?.home,
      Email: jobInfo.email,
      Title: jobInfo?.title,
      BirthDate: personalInfo?.birth,
      MaritalStatus: personalInfo?.martial,
      Gender: personalInfo?.gender,
      Nationality: "",
      StateofOrigin: "",
      LGA: "",
      HireDate: jobInfo.startDate,
      SalaryID: "",
      Department:
        departments?.find((ele) => ele?._id === jobInfo?.department)?.name ||
        "",
      Photo: "",
      Resume: "",
      ModifiedDate: "",
      Status: "Active",
      isAdmin,
      isSuperAdmin,
      userName: user?.username,
      password: user?.password,
      grantAccess,
    };

    if (process.env.REACT_APP_HAS_INVEX === "true") {
      fetch(`${URLS.mainSiteBackend}/users/edit-employee`, {
        method: "PUT",
        headers: {
          Accept: "application/json",
          // Authorization: `Token ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ ...mainEmployee }),
      }).then((res) => {
        if (res.ok) {
          // console.log(res);
          let formData = new FormData();

          jobInfo.changeableId = jobInfo?.changeableId || jobInfo?.employeeId;

          const employee = {
            _id,
            personalInfo,
            jobInfo,
            emergencyInfo,
            educationInfo,
            bankDetails,
            branch,
            Staff_ID: jobInfo.employeeId,
            isLineManager,
          };
          // employee.emergencyInfo.name = imageName;
          // employee.emergencyInfo.resume = resumeName;
          // employee.emergencyInfo.certificate = certificateName;
          formData.append("employee", JSON.stringify(employee));
          if (
            emergencyRef?.current?.emergency_image?.files &&
            emergencyRef?.current?.emergency_image?.files[0]
          )
            formData.append(
              "file",
              emergencyRef.current.emergency_image.files[0],
              emergencyInfo.image
            );
          if (
            resumeRef?.current?.emergency_resume?.files &&
            resumeRef?.current?.emergency_resume?.files[0]
          )
            formData.append(
              "file",
              resumeRef.current.emergency_resume.files[0],
              emergencyInfo.resume
            );

          for (let i = 0; i < educationInfo?.length; i++) {
            if (educationCertificateFiles[i]?.file)
              formData.append(
                "file",
                educationCertificateFiles[i]?.file,
                educationInfo[i]?.certificate
              );
          }

          const token = "dummy token";
          user.branch = branch;
          fetch(`${URLS.backendUsers}/update`, {
            method: "POST",
            headers: {
              Accept: "application/json",
              Authorization: `Token ${token}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ user: user }),
          })
            .then((res) => res.json())
            .then((result) => {});

          fetch(`${URLS.backendEmployees}/update`, {
            method: "POST",
            headers: {
              Accept: "application/json",
              Authorization: `Token ${token}`,
            },
            body: formData,
          })
            .then((res) => res.json())
            .then((result) => {
              setTimeout(() => {
                toast.success("Updated Successful", {
                  position: "top-right",
                });
                setState((el) => ({
                  ...el,
                  buttonText: "Submit",
                  isWaiting: false,
                }));
                props.hindeIt();
              }, 500);
              props.updateEmployeesList(result.employee);
            });
        } else {
          res.json().then((responseJson) => {
            console.log(responseJson);
            toast.error(responseJson.message, {
              position: "top-right",
            });
            setState((el) => ({
              ...el,
              buttonText: "Submit",
              isWaiting: false,
            }));
          });
        }
      });
    } else {
      let formData = new FormData();

      jobInfo.changeableId = jobInfo?.changeableId || jobInfo?.employeeId;

      const employee = {
        _id,
        personalInfo,
        jobInfo,
        emergencyInfo,
        educationInfo,
        bankDetails,
        branch,
        Staff_ID: jobInfo.employeeId,
      };
      // employee.emergencyInfo.name = imageName;
      // employee.emergencyInfo.resume = resumeName;
      // employee.emergencyInfo.certificate = certificateName;

      formData.append("employee", JSON.stringify(employee));
      if (
        emergencyRef?.current?.emergency_image?.files &&
        emergencyRef?.current?.emergency_image?.files[0]
      )
        formData.append(
          "file",
          emergencyRef?.current?.emergency_image?.files[0],
          emergencyInfo?.image
        );
      if (
        resumeRef?.current.emergency_resume.files &&
        resumeRef.current.emergency_resume.files[0]
      )
        formData.append(
          "file",
          resumeRef.current.emergency_resume.files[0],
          emergencyInfo.resume
        );

      for (let i = 0; i < educationInfo?.length; i++) {
        if (educationCertificateFiles[i].file)
          formData.append(
            "file",
            educationCertificateFiles[i]?.file,
            educationInfo[i]?.certificate
          );
      }

      const token = "dummy token";
      user.branch = branch;
      fetch(`${URLS.backendUsers}/update`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          Authorization: `Token ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ user: user }),
      })
        .then((res) => res.json())
        .then((result) => {});

      fetch(`${URLS.backendEmployees}/update`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          Authorization: `Token ${token}`,
        },
        body: formData,
      })
        .then((res) => res.json())
        .then((result) => {
          setTimeout(() => {
            toast.success("Updated Successful", {
              position: "top-right",
            });
            setState((el) => ({
              ...el,
              buttonText: "Submit",
              isWaiting: false,
            }));
            props.hindeIt();
          }, 500);
          props.updateEmployeesList(result.employee);
        });
    }
  };

  const handleBankDetails = (e) => {
    const { bankDetails } = state;
    bankDetails[e.target.name] = e.target.value;
    setState((el) => ({
      ...el,
      bankDetails,
    }));
  };

  const updateGrantAccess = () => {
    let { personalInfo, user, grantAccess } = state;
    personalInfo.grantAccess = !grantAccess;
    user.grantAccess = !grantAccess;
    grantAccess = !grantAccess;

    setState((el) => ({
      ...el,
      personalInfo,
      user,
      grantAccess,
    }));
  };

  const { mode, hindeIt } = props;
  const id = mode === "edit" ? "edit_employee" : "add_empolyee";
  const title = mode === "edit" ? "Edit Employee" : "Add Employee";
  const {
    personalInfo,
    jobInfo,
    emergencyInfo,
    educationInfo,
    jobGrades,
    departments,
    locations,
    educationCertificateFiles,
    displayImageSrc,
    displayResumeSrc,
    pdfPageNumber,
    employeeHMOs,
    lifeInsurances,
    supervisors,
    projects,
    isAdmin,
    isSuperAdmin,
    bankDetails,
    grantAccess,
    branches,
    branch,
    showBranch,
    branchesLocations,
    jobCategories,
    isLineManager,
  } = state;
  const displayImage = displayImageSrc
    ? displayImageSrc
    : URLS.backendStatic + "/" + emergencyInfo?.image;
  const displayResume = displayResumeSrc
    ? displayResumeSrc
    : URLS.backendStatic + "/" + emergencyInfo?.resume;
  let displayEducationCertificates = [];
  for (let i = 0; i < educationInfo?.length; i++) {
    let sFileUrl = URLS.backendStatic + "/" + educationInfo[i]?.certificate;
    let fff = educationCertificateFiles[i]?.file;
    if (fff) {
      displayEducationCertificates.push(fff);
    } else {
      displayEducationCertificates.push(sFileUrl);
    }
  }

  return (
    <>
      <Modal
        className="custom-modal"
        show={props.showEdit}
        centered
        size="lg"
        onHide={props.hindeIt}
      >
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">{title}</h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => hindeIt()}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <Form onSubmit={submit}>
              <Form.Group as={Row}>
                {/*<Col xs={6}>*/}
                {/*    <Image src={state.displayImageSrc} alt='Image'*/}
                {/*        style={{ height: '100px', width: '80px' }} rounded />*/}
                {/*</Col>*/}
                <Col
                  xs={{ span: 6, offset: 6 }}
                  style={{
                    textAlignLast: "end",
                    justifyContent: "right",
                  }}
                >
                  {/* Company Name */}
                </Col>
              </Form.Group>
              <Form.Group
                as={Row}
                style={{
                  backgroundColor: "#00c5fb",
                  alignItems: "center",
                  justifyContent: "center",
                  fontSize: "16px",
                  color: "#ffffff",
                  fontWeight: "bold",
                }}
              >
                Personal Information
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                  Full Name
                </Form.Label>
                <Col sm={10}>
                  <Row>
                    <Col sm={4}>
                      <Form.Control
                        placeholder="First name"
                        value={personalInfo.name.first}
                        onChange={updatePersonalInfoFirstName}
                        required
                      />
                    </Col>
                    <Col sm={4}>
                      <Form.Control
                        placeholder="Last name"
                        value={personalInfo.name.last}
                        onChange={updatePersonalInfoLastName}
                        required
                      />
                    </Col>
                    <Col sm={4}>
                      <Form.Control
                        placeholder="Middle Initial"
                        value={personalInfo.name.middle}
                        onChange={updatePersonalInfoMiddleName}
                      />
                    </Col>
                  </Row>
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                  Address
                </Form.Label>
                <Col sm={10}>
                  <Form.Row>
                    <Form.Group as={Col} sm={4}>
                      <Form.Control
                        placeholder="Apartment/Unit #"
                        value={personalInfo?.address?.apartment}
                        onChange={updatePersonalInfoAddressApartment}
                      />
                    </Form.Group>
                    <Form.Group as={Col} sm={8}>
                      <Form.Control
                        placeholder="Street Address"
                        value={personalInfo?.address?.street}
                        onChange={updatePersonalInfoAddressStreet}
                      />
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} sm={4}>
                      <Form.Control
                        placeholder="City"
                        value={personalInfo?.address?.city}
                        onChange={updatePersonalInfoAddressCity}
                      />
                    </Form.Group>
                    <Form.Group as={Col} sm={4}>
                      <Form.Control
                        placeholder="State"
                        value={personalInfo?.address?.state}
                        onChange={updatePersonalInfoAddressState}
                      />
                    </Form.Group>
                    <Form.Group as={Col} sm={4}>
                      <Form.Control
                        placeholder="Zip code"
                        value={personalInfo?.address?.zip}
                        onChange={updatePersonalInfoAddressZip}
                      />
                    </Form.Group>
                  </Form.Row>
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                  Home Phone
                </Form.Label>
                <Col sm={4}>
                  <Form.Control
                    value={personalInfo?.phone?.home}
                    onChange={updatePersonalInfoPhoneHome}
                  />
                </Col>
                <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                  Alternate Phone
                </Form.Label>
                <Col sm={4}>
                  <Form.Control
                    value={personalInfo?.phone?.alternate}
                    onChange={updatePersonalInfoPhoneAlternate}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                  Email
                </Form.Label>
                <Col sm={4}>
                  <Form.Control
                    placeholder="Email"
                    value={personalInfo?.email}
                    onChange={updatePersonalInfoEmail}
                    required
                  />
                </Col>
                {/* <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                    Is Admin
                  </Form.Label> */}
                <Col sm={3}>
                  <div
                    className="form-check"
                    style={{
                      height: "44px",
                      padding: "6px 0",
                      display: "inline-block",
                    }}
                  >
                    <input
                      style={{ height: "30px", width: "30px" }}
                      type="checkbox"
                      checked={isAdmin}
                      onChange={() => updatePersonalInfoIsAdmin("admin")}
                    />
                    <label
                      style={{ margin: "10px", verticalAlign: "bottom" }}
                      onClick={() => updatePersonalInfoIsAdmin("admin")}
                    >
                      Admin
                    </label>
                  </div>
                </Col>
                <Col sm={3}>
                  <div
                    className="form-check"
                    style={{
                      height: "44px",
                      padding: "6px 0",
                      display: "inline-block",
                    }}
                  >
                    <input
                      style={{ height: "30px", width: "30px" }}
                      type="checkbox"
                      checked={isSuperAdmin}
                      onChange={() => updatePersonalInfoIsAdmin("superAdmin")}
                    />
                    <label
                      style={{ margin: "10px", verticalAlign: "bottom" }}
                      onClick={() => updatePersonalInfoIsAdmin("superAdmin")}
                    >
                      Super Admin
                    </label>
                  </div>
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                  Govt ID Type:
                </Form.Label>
                <Col sm={4}>
                  <Form.Control
                    as="select"
                    value={personalInfo.govtType}
                    onChange={updatePersonalInfoGovtType}
                  >
                    <option>Choose...</option>
                    <option value="Voter's Card">Voter's Card</option>
                    <option value="Driver's Licence">Driver's Licence</option>
                    <option value="National ID">National ID</option>
                    <option value="International ID">International ID</option>
                  </Form.Control>
                </Col>

                <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                  Govt ID:
                </Form.Label>
                <Col sm={4}>
                  <Form.Control
                    placeholder="Gov’t ID:"
                    value={personalInfo?.govtId}
                    onChange={updatePersonalInfoGovtId}
                  />
                </Col>
                {/* <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                    Is Super Admin
                  </Form.Label> */}
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                  Gender:
                </Form.Label>
                <Col sm={4}>
                  <Form.Control
                    as="select"
                    value={personalInfo.gender}
                    onChange={updatePersonalGender}
                  >
                    <option>Choose...</option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                    <option value="Others">Others</option>
                  </Form.Control>
                </Col>

                <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                  Birth Date
                </Form.Label>
                <Col sm={4}>
                  <div classname="datePicker">
                    <i
                      class="fa fa-calendar fa-xs calendar-icon"
                      aria-hidden="true"
                    ></i>
                    <DatePicker
                      value={personalInfo.birth}
                      closeOnSelect={true}
                      onChange={updatePersonalInfoBirth}
                    />
                  </div>
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                  Marital Status
                </Form.Label>
                <Col sm={4}>
                  <Form.Control
                    as="select"
                    value={personalInfo.martial}
                    onChange={updatePersonalInfoMartial}
                  >
                    <option value="Single">Single</option>
                    <option value="Married">Married</option>
                    <option value="Divorce">Divorce</option>
                    <option value="Widow">Widow</option>
                    <option value="Widower">Widower</option>
                  </Form.Control>
                </Col>

                {personalInfo.martial !== "Single" && (
                  <Form.Group as={Row}>
                    <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                      Spouse’s Name
                    </Form.Label>
                    <Col sm={10}>
                      <Form.Control
                        value={personalInfo?.spouse?.name}
                        onChange={updatePersonalInfoSpouseName}
                      />
                    </Col>
                  </Form.Group>
                )}
              </Form.Group>

              {personalInfo.martial !== "Single" && (
                <Form.Group as={Row}>
                  <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                    Spouse’s Employer
                  </Form.Label>
                  <Col sm={4}>
                    <Form.Control
                      placeholder="Spouse’s Employer"
                      value={personalInfo?.spouse?.employer}
                      onChange={updatePersonalInfoSpouseEmployer}
                    />
                  </Col>
                  <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                    Spouse’s Work Phone
                  </Form.Label>
                  <Col sm={4}>
                    <Form.Control
                      placeholder="Spouse’s Work Phone"
                      value={personalInfo?.spouse?.phone}
                      onChange={updatePersonalInfoSpousePhone}
                    />
                  </Col>
                </Form.Group>
              )}
              <Form.Group as={Row}>
                <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                  Previous Employer
                </Form.Label>
                <Col sm={4}>
                  <Form.Control
                    placeholder="Previous Employee"
                    value={personalInfo?.prevEmployee?.employee}
                    onChange={updatePersonalInfoPrevEmployeeEmployee}
                  />
                </Col>
                <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                  Previous Employer Phone
                </Form.Label>
                <Col sm={4}>
                  <Form.Control
                    placeholder="Previous Employer Phone"
                    value={personalInfo?.prevEmployee?.phone}
                    onChange={updatePersonalInfoPrevEmployeePhone}
                  />
                </Col>
              </Form.Group>

              <Form.Group
                as={Row}
                style={{
                  backgroundColor: "#00c5fb",
                  alignItems: "center",
                  justifyContent: "center",
                  fontSize: "16px",
                  color: "#ffffff",
                  fontWeight: "bold",
                }}
              >
                Job Information
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                  Job Title
                </Form.Label>
                <Col sm={4}>
                  <Form.Control
                    value={jobInfo.title}
                    onChange={updateJobInfoTitle}
                  />
                </Col>
                <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                  Employee ID <span style={{ color: "red" }}>*</span>
                </Form.Label>
                <Col sm={4}>
                  <Form.Control
                    value={jobInfo.changeableId}
                    onChange={updateJobInfoEmployeeId}
                    // disabled
                    required
                  />
                </Col>
              </Form.Group>

              <Form.Group as={Row}>
                <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                  Employee Category
                </Form.Label>
                <Col sm={4}>
                  <Form.Control
                    as="select"
                    value={jobInfo.category}
                    onChange={updateJobInfoCategory}
                    required
                  >
                    <option>Select</option>
                    <option value="add_jobCategory">Add New</option>
                    <option disabled>__________________</option>
                    {jobCategories?.map((jobCategory) => {
                      return (
                        <option value={jobCategory._id}>
                          {jobCategory.name}
                        </option>
                      );
                    })}
                  </Form.Control>
                </Col>
                <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                  Line Manager
                </Form.Label>
                <Col sm={4}>
                  <div className="d-flex justify-content-start">
                    <div className="">
                      <div
                        className="onoffswitch ml-0"
                        onClick={updateIsLineManger}
                      >
                        <input
                          type="checkbox"
                          name="onoffswitch"
                          className="onoffswitch-checkbox"
                          checked={isLineManager}
                        />
                        <label className="onoffswitch-label">
                          <span className="onoffswitch-inner"></span>
                          <span className="onoffswitch-switch"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                </Col>
              </Form.Group>

              <Form.Group as={Row}>
                <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                  Job Grade
                </Form.Label>
                <Col sm={4}>
                  <Form.Control
                    as="select"
                    value={jobInfo.grade}
                    onChange={updateJobInfoGrade}
                    required
                  >
                    <option value="">Select</option>
                    <option value="add_job">Add New</option>
                    <option disabled>__________________</option>
                    {jobGrades?.map((jobGrade) => {
                      return (
                        <option value={jobGrade._id}>{jobGrade.name}</option>
                      );
                    })}
                  </Form.Control>
                </Col>
                <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                  Work Location
                </Form.Label>
                <Col sm={4}>
                  <Form.Control
                    as="select"
                    value={jobInfo.workLocation}
                    onChange={updateJobInfoWorkLocation}
                  >
                    <option value="">Select</option>
                    <option value="add_location">Add New</option>
                    <option disabled>__________________</option>
                    {locations?.map((location) => {
                      return (
                        <option value={location._id}>
                          {location.location}
                        </option>
                      );
                    })}
                  </Form.Control>
                </Col>
              </Form.Group>

              {branchesLocations?.length > 0 && (
                <Form.Group as={Row}>
                  <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                    Branch
                  </Form.Label>
                  <Col sm={4}>
                    <Form.Control
                      as="select"
                      value={jobInfo.workLocationBranch}
                      onChange={updateJobInfoWorkLocationBranch}
                    >
                      <option value="">Select</option>
                      {branchesLocations.map((location) => {
                        return (
                          <option value={location.name}>{location.name}</option>
                        );
                      })}
                    </Form.Control>
                  </Col>
                  <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                    State
                  </Form.Label>
                  <Col sm={4}>
                    <Form.Control value={jobInfo.state} type="text" />
                  </Col>
                </Form.Group>
              )}

              <Form.Group as={Row}>
                <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                  Reporting To
                </Form.Label>
                <Col sm={4}>
                  {/* {supervisors.length && ( */}
                  <Multiselect
                    options={supervisors}
                    onSelectOptions={updateJobInfoSupervisor}
                    selectedOptions={jobInfo.supervisor}
                  />
                  {/* )} */}
                </Col>
                <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                  Department
                </Form.Label>
                <Col sm={4}>
                  <Form.Control
                    as="select"
                    value={jobInfo.department}
                    onChange={updateJobInfoDepartment}
                    // required
                  >
                    <option value="">Select</option>
                    <option value="add_department">Add New</option>
                    <option disabled>__________________</option>
                    {departments?.map((dep) => {
                      return <option value={dep._id}>{dep.name}</option>;
                    })}
                  </Form.Control>
                </Col>
              </Form.Group>

              <Form.Group as={Row}>
                <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                  Work Email
                </Form.Label>
                <Col sm={4}>
                  <Form.Control
                    value={jobInfo.email}
                    type="email"
                    onChange={updateJobInfoEmail}
                    required
                  />
                </Col>
                {/* <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                  Password <span style={{ color: "red" }}>*</span>
                </Form.Label> */}
                {/* <Col sm={4}>
                  <Form.Control
                    value={jobInfo.password}
                    type="password"
                    // onChange={updateJobInfoPassword}
                    required
                    disabled
                  />
                </Col> */}
                <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                  Select Company
                </Form.Label>
                <Col sm={4}>
                  <Select
                    id="company"
                    placeholder="Select"
                    menuShouldScrollIntoView={false}
                    isSearchable={false}
                    value={branches.find((el) => el.value === branch)}
                    onChange={(selected) => {
                      updateUserBranch(selected.value);
                    }}
                    options={branches}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                  Work Phone
                </Form.Label>
                <Col sm={4}>
                  <Form.Control
                    value={jobInfo.workPhone}
                    onChange={updateJobInfoWorkPhone}
                  />
                </Col>
                <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                  Cell Phone
                </Form.Label>
                <Col sm={4}>
                  <Form.Control
                    value={jobInfo.cellPhone}
                    onChange={updateJobInfoCellPhone}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                  Join Date <span style={{ color: "red" }}>*</span>
                </Form.Label>
                <Col sm={4}>
                  <div classname="datePicker">
                    <i
                      class="fa fa-calendar fa-xs calendar-icon"
                      aria-hidden="true"
                    ></i>
                    <DatePicker
                      value={jobInfo.joinDate}
                      closeOnSelect={true}
                      onChange={updateJobInfoJoinDate}
                    />
                  </div>
                </Col>

                <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                  Salary
                </Form.Label>
                <Col sm={4}>
                  <CurrencyInput
                    className="form-control"
                    value={jobInfo.salary}
                    allowNegativeValue={false}
                    // allowDecimals={false}
                    onValueChange={(value, name) => updateJobInfoSalary(value)}
                  />
                </Col>
              </Form.Group>

              <Form.Group as={Row}>
                <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                  Start Date <span style={{ color: "red" }}>*</span>
                </Form.Label>
                <Col sm={4}>
                  <div classname="datePicker">
                    <i
                      class="fa fa-calendar fa-xs calendar-icon"
                      aria-hidden="true"
                    ></i>
                    <DatePicker
                      value={jobInfo.startDate}
                      closeOnSelect={true}
                      onChange={updateJobInfoStartDate}
                      isValidDate={validationEndDate}
                    />
                  </div>
                </Col>

                <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                  SFA ID
                </Form.Label>
                <Col sm={4}>
                  <Form.Control value={jobInfo.sfa_id} onChange={updateSFAID} />
                </Col>
              </Form.Group>

              <Form.Group as={Row}>
                <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                  Status
                </Form.Label>
                <Col sm={4}>
                  <Form.Control
                    as="select"
                    value={jobInfo.status}
                    onChange={updateJobInfoStatus}
                    required
                  >
                    <option>Choose...</option>
                    <option value="Internship">Internship</option>
                    <option value="Probation">Probation</option>
                    <option value="Trainee">Trainee</option>
                    <option value="New hire">New hire</option>
                    <option value="Confirmed">Confirmed</option>
                  </Form.Control>
                </Col>
                <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                  Salary Basis
                </Form.Label>
                <Col sm={4}>
                  <Form.Control
                    as="select"
                    value={jobInfo.salaryBasis}
                    onChange={updateJobInfoSalaryBasis}
                  >
                    <option>Choose...</option>
                    <option value="Hourly">Hourly</option>
                    <option value="Daily">Daily</option>
                    <option value="Daily">Weekly</option>
                    <option value="Monthly">Monthly</option>
                  </Form.Control>
                </Col>
              </Form.Group>

              {jobInfo.status === "Confirmed" && (
                <Form.Group as={Row}>
                  <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                    Confirm Date <span style={{ color: "red" }}>*</span>
                  </Form.Label>
                  <Col sm={4}>
                    <div classname="datePicker">
                      <i
                        class="fa fa-calendar fa-xs calendar-icon"
                        aria-hidden="true"
                      ></i>
                      <DatePicker
                        value={jobInfo.confirmDate}
                        closeOnSelect={true}
                        onChange={updateJobInfoConfirmDate}
                      />
                    </div>
                  </Col>
                </Form.Group>
              )}

              <Form.Group as={Row}>
                <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                  Contractor
                </Form.Label>
                <Col sm={4}>
                  <div
                    className="form-check"
                    style={{
                      height: "44px",
                      padding: "6px 0",
                      display: "inline-block",
                    }}
                  >
                    <input
                      style={{ height: "30px", width: "30px" }}
                      type="checkbox"
                      checked={jobInfo.contract}
                      onChange={updateJobInfoContract}
                    />
                    <label style={{ margin: "10px", verticalAlign: "bottom" }}>
                      Check if contract based
                    </label>
                  </div>
                </Col>
                {jobInfo?.contract && (
                  <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                    Contract Type
                  </Form.Label>
                )}
                {jobInfo?.contract && (
                  <Col sm={4}>
                    <Form.Control
                      as="select"
                      value={jobInfo.contractType}
                      onChange={updateJobInfoContractType}
                    >
                      <option value="">Choose...</option>
                      {projects?.length ? (
                        projects?.map((project) => {
                          return (
                            <option value={project._id}>
                              {project.contractName}
                            </option>
                          );
                        })
                      ) : (
                        <option value="">Choose...</option>
                      )}
                    </Form.Control>
                  </Col>
                )}

                {/* {showBranch && (
                  <> */}

                {/* </>
                )} */}
              </Form.Group>

              <Form.Group
                as={Row}
                style={{
                  backgroundColor: "#00c5fb",
                  alignItems: "center",
                  justifyContent: "center",
                  fontSize: "16px",
                  color: "#ffffff",
                  fontWeight: "bold",
                }}
              >
                Emergency Contact Information
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                  Full Name
                </Form.Label>
                <Col sm={10}>
                  <Row>
                    <Col sm={4}>
                      <Form.Control
                        placeholder="First name"
                        value={emergencyInfo?.name?.first}
                        onChange={updateEmergencyInfoNameFirst}
                      />
                    </Col>
                    <Col sm={4}>
                      <Form.Control
                        placeholder="Last name"
                        value={emergencyInfo?.name?.last}
                        onChange={updateEmergencyInfoNameLast}
                      />
                    </Col>
                    <Col sm={4}>
                      <Form.Control
                        placeholder="Middle Initial"
                        value={emergencyInfo?.name?.middle}
                        onChange={updateEmergencyInfoNameMiddle}
                      />
                    </Col>
                  </Row>
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                  Address
                </Form.Label>
                <Col sm={10}>
                  <Form.Row>
                    <Form.Group as={Col} sm={4}>
                      <Form.Control
                        placeholder="Apartment/Unit #"
                        value={emergencyInfo?.address?.apartment}
                        onChange={updateEmergencyInfoAddressApartment}
                      />
                    </Form.Group>
                    <Form.Group as={Col} sm={8}>
                      <Form.Control
                        placeholder="Street Address"
                        value={emergencyInfo?.address?.street}
                        onChange={updateEmergencyInfoAddressStreet}
                      />
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} sm={4}>
                      <Form.Control
                        placeholder="City"
                        value={emergencyInfo?.address.city}
                        onChange={updateEmergencyInfoAddressCity}
                      />
                    </Form.Group>
                    <Form.Group as={Col} sm={4}>
                      <Form.Control
                        placeholder="State"
                        value={emergencyInfo?.address?.state}
                        onChange={updateEmergencyInfoAddressState}
                      />
                    </Form.Group>
                    <Form.Group as={Col} sm={4}>
                      <Form.Control
                        placeholder="Zip code"
                        value={emergencyInfo?.address?.zip}
                        onChange={updateEmergencyInfoAddressZip}
                      />
                    </Form.Group>
                  </Form.Row>
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                  Home Phone
                </Form.Label>
                <Col sm={4}>
                  <Form.Control
                    value={emergencyInfo?.phone?.primary}
                    onChange={updateEmergencyInfoPhonePrimary}
                  />
                </Col>
                <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                  Alternate Phone
                </Form.Label>
                <Col sm={4}>
                  <Form.Control
                    value={emergencyInfo?.phone?.secondary}
                    onChange={updateEmergencyInfoPhoneSecondary}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                  Relationship
                </Form.Label>
                <Col sm={10}>
                  <Form.Control
                    value={emergencyInfo?.relation}
                    onChange={updateEmergencyInfoRelation}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                  Guarantor
                </Form.Label>
                <Col sm={10}>
                  <Form.Row>
                    <Form.Group as={Col} sm={2}>
                      <Form.Control
                        placeholder="Title"
                        value={emergencyInfo?.guarantor?.title}
                        onChange={updateEmergencyInfoGuarantorTitle}
                      />
                    </Form.Group>
                    <Form.Group as={Col} sm={5}>
                      <Form.Control
                        placeholder="Guarantor Name"
                        value={emergencyInfo?.guarantor?.name}
                        onChange={updateEmergencyInfoGuarantorName}
                      />
                    </Form.Group>
                    <Form.Group as={Col} sm={5}>
                      <Form.Control
                        placeholder="Guarantor Phone"
                        value={emergencyInfo?.guarantor?.phone}
                        onChange={updateEmergencyInfoGuarantorPhone}
                      />
                    </Form.Group>
                    <Form.Group as={Col} sm={12}>
                      <Form.Control
                        placeholder="Guarantor Address"
                        value={emergencyInfo?.guarantor?.address}
                        onChange={updateEmergencyInfoGuarantorAddress}
                      />
                    </Form.Group>
                  </Form.Row>
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                  Referee
                </Form.Label>
                <Col sm={10}>
                  <Form.Row>
                    <Form.Group as={Col} sm={2}>
                      <Form.Control
                        placeholder="Title"
                        value={emergencyInfo?.referee?.title}
                        onChange={updateEmergencyInfoRefereeTitle}
                      />
                    </Form.Group>
                    <Form.Group as={Col} sm={5}>
                      <Form.Control
                        placeholder="Referee Name"
                        value={emergencyInfo?.referee?.name}
                        onChange={updateEmergencyInfoRefereeName}
                      />
                    </Form.Group>
                    <Form.Group as={Col} sm={5}>
                      <Form.Control
                        placeholder="Referee Phone"
                        value={emergencyInfo?.referee?.phone}
                        onChange={updateEmergencyInfoRefereePhone}
                      />
                    </Form.Group>
                    <Form.Group as={Col} sm={12}>
                      <Form.Control
                        placeholder="Referee Address"
                        value={emergencyInfo?.referee?.address}
                        onChange={updateEmergencyInfoRefereeAddress}
                      />
                    </Form.Group>
                  </Form.Row>
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                  Employee HMO
                </Form.Label>
                <Col sm={10}>
                  <Form.Control
                    as="select"
                    value={emergencyInfo?.employeeHMO}
                    onChange={updateEmergencyInfoEmployeeHMO}
                  >
                    <option value="">Select</option>
                    <option value="add_employeeHMO">Add New</option>
                    <option disabled>__________________</option>
                    {employeeHMOs?.map((location) => {
                      return (
                        <option value={location?._id}>{location?.name}</option>
                      );
                    })}
                  </Form.Control>
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                  Group Life Insurance
                </Form.Label>
                <Col sm={10}>
                  <Form.Control
                    as="select"
                    value={emergencyInfo?.lifeInsurance}
                    onChange={() => updateEmergencyInfoLifeInsurance}
                  >
                    <option value="">Select</option>
                    <option value="add_lifeInsurance">Add New</option>
                    <option disabled>__________________</option>
                    {lifeInsurances?.map((lifeInsurance) => {
                      return (
                        <option value={lifeInsurance?._id}>
                          {lifeInsurance?.name}
                        </option>
                      );
                    })}
                  </Form.Control>
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                  Upload Employee Photo
                </Form.Label>
                <Col sm={10}>
                  <div className="file-upload-div">
                    <div className="file-preview">
                      <img
                        style={{ height: "120px", width: "110px" }}
                        src={displayImage}
                        alt=""
                      />
                    </div>

                    <div className="upload-btn-wrapper">
                      <button className="custom-upload-btn">Browse</button>
                      <input
                        type="file"
                        label="Upload File"
                        ref={emergencyRef}
                        onChange={updateEmergencyInfoImage}
                      />
                    </div>
                  </div>
                </Col>
              </Form.Group>
              <Form.Group as={Row}>
                <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                  Upload Employee Resume
                </Form.Label>
                <Col sm={10}>
                  <div className="file-upload-div">
                    <div className="file-preview">
                      <Document
                        file={displayResume}
                        onLoadSuccess={() => onDocumentLoadSuccess}
                        noData={
                          <div
                            style={{
                              width: "110px",
                              height: "120px",
                              border: "1px solid #bebebe",
                            }}
                          ></div>
                        }
                      >
                        <Page
                          pageNumber={pdfPageNumber}
                          height={state.pageHeight}
                          width={state.pageWidth}
                          className="change-pdf-size"
                        />
                      </Document>
                    </div>

                    <div className="upload-btn-wrapper">
                      <button className="custom-upload-btn">Browse</button>
                      <input
                        type="file"
                        label="Upload File"
                        ref={resumeRef}
                        onChange={updateEmergencyInfoResume}
                      />
                    </div>
                  </div>
                </Col>
              </Form.Group>

              <Form.Group
                as={Row}
                style={{
                  backgroundColor: "#00c5fb",
                  alignItems: "center",
                  justifyContent: "center",
                  fontSize: "16px",
                  color: "#ffffff",
                  fontWeight: "bold",
                }}
              >
                Education Information
              </Form.Group>
              {educationInfo.map((info, idx) => {
                return (
                  <Form
                    style={{
                      border: "1px solid #e3e3e3",
                      borderRadius: "5px",
                      padding: "20px 10px",
                      margin: "10px",
                    }}
                  >
                    <Form.Group>Education Information</Form.Group>
                    <Form.Group as={Row}>
                      <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                        Institution
                      </Form.Label>
                      <Col sm={4}>
                        <Form.Control
                          value={info.institution}
                          name="institution"
                          id={"institution" + idx}
                          onChange={(e) => handleEducationInfoField(idx, e)}
                        />
                      </Col>
                      <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                        Subject
                      </Form.Label>
                      <Col sm={4}>
                        <Form.Control
                          value={info.subject}
                          name="subject"
                          id={"subject" + idx}
                          onChange={(e) => handleEducationInfoField(idx, e)}
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                      <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                        Starting Date
                      </Form.Label>
                      <Col sm={4}>
                        <div classname="datePicker">
                          <i
                            class="fa fa-calendar fa-xs calendar-icon"
                            aria-hidden="true"
                          ></i>
                          <DatePicker
                            value={info.startDate}
                            name="startDate"
                            id={"startDate" + idx}
                            closeOnSelect={true}
                            onChange={(e) =>
                              handleEducationInfoStartField(idx, e)
                            }
                          />
                        </div>
                      </Col>
                      <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                        Complete Date
                      </Form.Label>
                      <Col sm={4}>
                        <div classname="datePicker">
                          <i
                            class="fa fa-calendar fa-xs calendar-icon"
                            aria-hidden="true"
                          ></i>
                          <DatePicker
                            datepicker
                            value={info.completeDate}
                            name="completeDate"
                            id={"completeDate" + idx}
                            closeOnSelect={true}
                            onChange={(e) =>
                              handleEducationInfoCompleteField(idx, e)
                            }
                          />
                        </div>
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                      <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                        Degree
                      </Form.Label>
                      <Col sm={4}>
                        <Form.Control
                          value={info.degree}
                          name="degree"
                          id={"degree" + idx}
                          onChange={(e) => handleEducationInfoField(idx, e)}
                        />
                      </Col>
                      <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                        Grade
                      </Form.Label>
                      <Col sm={4}>
                        <Form.Control
                          value={info.grade}
                          name="grade"
                          id={"grade" + idx}
                          onChange={(e) => handleEducationInfoField(idx, e)}
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                      <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                        Upload Employee Certificate
                      </Form.Label>
                      <Col sm={10}>
                        <div className="file-upload-div">
                          <div className="file-preview">
                            <Document
                              file={displayEducationCertificates[idx]}
                              onLoadSuccess={onDocumentLoadSuccess}
                              noData={
                                <div
                                  style={{
                                    width: "110px",
                                    height: "120px",
                                    border: "1px solid #bebebe",
                                  }}
                                ></div>
                              }
                            >
                              <Page
                                pageNumber={pdfPageNumber}
                                height={state.pageHeight}
                                width={state.pageWidth}
                                className="change-pdf-size"
                              />
                            </Document>
                          </div>

                          <div className="upload-btn-wrapper">
                            <button className="custom-upload-btn">
                              Browse
                            </button>
                            <input
                              type="file"
                              label="Upload File"
                              name="certificate"
                              ref={eduRef}
                              onChange={(e) =>
                                handleEducationInfoFileField(idx)
                              }
                            />
                          </div>
                        </div>
                      </Col>
                    </Form.Group>
                  </Form>
                );
              })}
              <div className="add-more">
                <a href="javascript:void(0);" onClick={addMoreEducationInfo}>
                  <i className="fa fa-plus-circle" /> Add More
                </a>
              </div>

              <Form.Group
                as={Row}
                style={{
                  backgroundColor: "#00c5fb",
                  alignItems: "center",
                  justifyContent: "center",
                  fontSize: "16px",
                  marginTop: "20px",
                  color: "#ffffff",
                  fontWeight: "bold",
                }}
              >
                Employee Bank Account Details
              </Form.Group>

              <Form.Group as={Row}>
                <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                  Bank Name
                </Form.Label>
                <Col sm={4}>
                  <Form.Control
                    name="bankName"
                    value={bankDetails?.bankName}
                    onChange={(e) => handleBankDetails(e)}
                  />
                </Col>
                <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                  Account Number
                </Form.Label>
                <Col sm={4}>
                  <Form.Control
                    name="accountNumber"
                    value={bankDetails?.accountNumber}
                    onChange={(e) => handleBankDetails(e)}
                  />
                </Col>
              </Form.Group>

              <Form.Group as={Row}>
                <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                  Account Type
                </Form.Label>
                <Col sm={4}>
                  <Form.Control
                    name="accountType"
                    value={bankDetails?.accountType}
                    onChange={(e) => handleBankDetails(e)}
                  />
                </Col>
                <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                  Account Officer
                </Form.Label>
                <Col sm={4}>
                  <Form.Control
                    name="accountOfficer"
                    value={bankDetails?.accountOfficer}
                    onChange={(e) => handleBankDetails(e)}
                  />
                </Col>
              </Form.Group>

              <Form.Group as={Row}>
                <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                  Bank Code
                </Form.Label>
                <Col sm={4}>
                  <Form.Control
                    name="bankCode"
                    value={bankDetails?.bankCode}
                    onChange={(e) => handleBankDetails(e)}
                  />
                </Col>
                <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                  Pension No
                </Form.Label>
                <Col sm={4}>
                  <Form.Control
                    name="pensionNumber"
                    value={bankDetails?.pensionNumber}
                    onChange={(e) => handleBankDetails(e)}
                  />
                </Col>
              </Form.Group>

              <Form.Group as={Row}>
                <Form.Label style={{ fontSize: "14px" }} column sm={2}>
                  Pension Admin
                </Form.Label>
                <Col sm={4}>
                  <Form.Control
                    name="pensionAdmin"
                    onChange={(e) => handleBankDetails(e)}
                    value={bankDetails?.pensionAdmin}
                  />
                </Col>
              </Form.Group>

              <div className="submit-section ">
                <div
                  className="form-check"
                  style={{
                    height: "44px",
                    padding: "6px 0",
                    display: "inline-block",
                  }}
                >
                  <input
                    style={{ height: "30px", width: "30px" }}
                    type="checkbox"
                    checked={grantAccess}
                    onChange={() => updateGrantAccess()}
                  />
                  <label
                    style={{ margin: "10px", verticalAlign: "bottom" }}
                    onClick={() => updateGrantAccess()}
                  >
                    Grant Login Access
                  </label>
                </div>

                <button className="btn add-btn">{state.buttonText}</button>
              </div>
            </Form>
            {state.show_add_location && (
              <AddLocationModal
                updateLocationsList={updateLocationsList}
                isShow={state.show_add_location}
                changeShowLocationState={changeShowLocationState}
              />
            )}

            {state.show_add_job && (
              <AddJobGradeModal
                updateJobGradesList={updateJobGradesList}
                isShow={state.show_add_job}
                changeShowJobState={changeShowJobState}
                putJobGrade={putJobGrade}
              />
            )}

            {state.show_add_jobCategory && (
              <AddJobCategoryModal
                updateJobCategoryList={updateJobCategoryList}
                isShow={state.show_add_jobCategory}
                changeShowJobState={changeShowJobCategoryState}
              />
            )}

            {state.show_add_employeeHMO && (
              <AddEmployeeHMOModal
                updateEmployeeHMOsList={updateEmployeeHMOsList}
                isShow={state.show_add_employeeHMO}
                changeShowEmployeeHMOState={changeShowEmployeeHMOState}
              />
            )}
            {state.show_add_lifeInsurance && (
              <AddLifeInsuranceModal
                updateLifeInsurancesList={updateLifeInsurancesList}
                isShow={state.show_add_lifeInsurance}
                changeShowLifeInsuranceState={changeShowLifeInsuranceState}
              />
            )}
            {state.show_add_department && (
              <AddEmployeeDepartmentModal
                changeShowEmployeeDepartment={changeShowEmployeeDepartment}
                isShow={state.show_add_department}
                updateDepartmentsList={updateDepartmentsList}
              />
            )}
          </div>
        </div>
      </Modal>
    </>
  );
};

export default React.memo(EmployeeModal);
