import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { URLS } from "../../urls";
import { Helmet } from "react-helmet";
import Cookies from "universal-cookie";
import UserTopbar from "../user-topbar/UserTopbar";
import UserSidebar from "../user-sidebar/UserSidebar";
import ApplyLoanModal from "./ApplyLoanModal";
import RsDateRangePicker from "../../RsDateRangePicker";

import LoanDetails from "./LoanDetails";
import LoanHistory from "./LoanHistory";
const allMonths = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
class UserDashBoardLoan extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      appliedLoans: [],
      openLoanDetails: false,
      openLoanHistory: false,
      staff: "",
      requestid: "",
      startDate: "",
      endDate: "",
      status: "",
      btnSummryActive: false,
      stats: {},
    };
  }
  componentDidMount() {
    const token = "dummy token";
    const cookies = new Cookies();
    const user = cookies.get("employee");
    this.sortLoan("", "", "");

    fetch(`${URLS.backendLoan}/userTotals/${user.id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        // console.log("result => ", data);
        this.setState({
          stats: data,
        });
      });
  }

  componentDidUpdate(prevProps, prevState) {
    const { startDate, endDate, status } = this.state;

    if (prevState.startDate !== startDate || prevState.endDate !== endDate) {
      this.sortLoan("", startDate, endDate);
    }

    if (prevState.status !== status) {
      this.sortLoan(status, "", "");
    }
  }

  showDateInFormat = (date) => {
    date = date.split("/");
    return (
      ("0" + date[0]).slice(-2) + " " + allMonths[date[1] - 1] + " " + date[2]
    );
  };

  sortLoan = (sta, startDate, endDate) => {
    const token = "dummy token";
    const cookies = new Cookies();
    const user = cookies.get("employee");

    fetch(
      `${URLS.backendLoan}/${user.id}?stat=${sta}&startDate=${startDate}&endDate=${endDate}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      }
    )
      .then((res) => res.json())
      .then((result) => {
        // console.log("result => ", result);
        this.setState({
          appliedLoans: result.loan,
        });
      });
  };

  changeByStatus = (sta) => {
    this.setState({
      status: sta,
    });
  };

  clearDateRange = (event, picker) => {
    this.setState({
      startDate: "",
      endDate: "",
    });

    picker.element.val("");
  };

  filterByDateRange = (event, picker) => {
    this.setState({
      startDate: picker.startDate.format("DD/MM/yyyy"),
      endDate: picker.endDate.format("DD/MM/yyyy"),
    });
    picker.element.val(
      picker.startDate.format("DD/MM/yyyy") +
        " - " +
        picker.endDate.format("DD/MM/yyyy")
    );
  };

  render() {
    const {
      appliedLoans,
      loggedEmployee,
      openLoanDetails,
      openLoanHistory,
      staff,
      requestid,
      btnSummryActive,
      stats,
    } = this.state;
    return (
      <body>
        <Helmet>
          <meta charSet="utf-8" />
          <meta name="robots" content="noindex, nofollow"></meta>
          <title>InvexERP</title>
        </Helmet>
        <div className="main-wrapper">
          <UserTopbar />
          <UserSidebar />
          {/*<!-- Page Wrapper -->*/}
          <div className="page-wrapper">
            {/*<!-- Page Content -->*/}
            <div className="content container-fluid">
              {/*<!-- Page Title -->*/}
              <div className="d-flex justify-content-between align-item-baseline mb-4 mt-2">
                <div className="d-flex align-item-baseline">
                  <h4 className="page-title">Loan</h4>
                </div>

                <div className="d-flex align-item-baseline flex-wrap gap-3">
                  <div className="mb-2">
                    <RsDateRangePicker
                      // placement="bottomStart"
                      onClean={(event, picker) =>
                        this.clearDateRange(event, picker)
                      }
                      onOk={(event, picker) =>
                        this.filterByDateRange(event, picker)
                      }
                    />
                  </div>

                  <div className="mx-3">
                    <select
                      className="form-control"
                      onChange={(e) => this.changeByStatus(e.target.value)}
                    >
                      <option value="">All</option>
                      <option value="Approved">Approved</option>
                      <option value="Pending">Pending</option>
                      <option value="Disapproved">Disapproved</option>
                    </select>
                  </div>

                  <div className="">
                    <button
                      className="btn add-btn"
                      data-toggle="modal"
                      data-target="#add_leave"
                    >
                      <i className="fa fa-plus"></i> Apply Loan
                    </button>
                  </div>
                  {/* <div>
                    <button
                      onClick={() =>
                        this.setState({
                          btnSummryActive: !btnSummryActive,
                        })
                      }
                      className="btn summaryBtn mx-3"
                    >
                      Show Summary
                    </button>
                  </div> */}
                </div>
              </div>
              {/*<!-- /Page Title -->*/}

              {/*<!-- Leave Statistics -->*/}
              <section className="stats">
                <div
                  className="stat p-cursor"
                  onClick={() => this.changeByStatus("")}
                >
                  <div className="icon icon-3 ">
                    <i class="fa fa-money"></i>
                  </div>
                  <div className="details">
                    <p>{appliedLoans.length}</p>
                    <span className="dashboard-links">Total Loan </span>
                  </div>
                </div>
                <div
                  className="stat p-cursor"
                  onClick={() => this.changeByStatus("Approved")}
                >
                  <div className="icon icon-1 ">
                    <i class="fa fa-money"></i>
                  </div>
                  <div className="details">
                    <p>
                      {
                        appliedLoans.filter((d) => d.status === "Approved")
                          .length
                      }
                    </p>
                    <span className="dashboard-links">Approved Loan </span>
                  </div>
                </div>
                <div
                  className="stat p-cursor"
                  onClick={() => this.changeByStatus("Pending")}
                >
                  <div className="icon icon-4">
                    <i class="fa fa-money"></i>
                  </div>
                  <div className="details">
                    <p>
                      {
                        appliedLoans.filter((d) => d.status === "Pending")
                          .length
                      }
                    </p>
                    <span className="dashboard-links">Pending Loan </span>
                  </div>
                </div>
                <div
                  className="stat p-cursor"
                  onClick={() => this.changeByStatus("Disapproved")}
                >
                  <div className="icon icon-2">
                    <i class="fa fa-money"></i>
                  </div>
                  <div className="details">
                    <p>
                      {
                        appliedLoans.filter((d) => d.status === "Disapproved")
                          .length
                      }
                    </p>
                    <span className="dashboard-links">Disapproved Loan </span>
                  </div>
                </div>
              </section>
              {/*<!-- /Leave Statistics -->*/}

              <div className="row">
                <div className="col-md-12">
                  <div className="table-responsive">
                    <table className="table table-striped custom-table mb-0 datatable">
                      <thead>
                        <tr>
                          <th>S/N</th>
                          <th>Name</th>
                          <th>Title</th>
                          <th>Amount</th>
                          <th>Repayment</th>
                          <th>Balance</th>
                          <th>Status</th>
                          <th>Payment Status</th>
                          <th>Date</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {appliedLoans?.length
                          ? appliedLoans?.map((loan, i) => {
                              return (
                                <tr key={i} className="p-cursor">
                                  <td>{i + 1}</td>
                                  <td>{`${loan.userID.personalInfo.name.first} ${loan.userID.personalInfo.name.middle} ${loan.userID.personalInfo.name.last}`}</td>
                                  <td>{loan.Title}</td>
                                  <td>{loan.Amount}</td>
                                  <td>{loan?.paid ? loan?.paid : 0}</td>
                                  <td>{loan?.balance ? loan?.balance : 0}</td>
                                  <td
                                    className={`fw-bold ${
                                      loan.status === "Approved"
                                        ? "text-success"
                                        : loan.status === "Disapproved"
                                        ? "text-danger"
                                        : "text-pending"
                                    }`}
                                  >
                                    {loan.status}
                                  </td>
                                  <td
                                    className={`text-center fw-bold ${
                                      loan.paymentsStatus === "Paid"
                                        ? "text-success"
                                        : "text-primary"
                                    }`}
                                  >
                                    {loan.paymentsStatus}
                                  </td>
                                  <td>
                                    {this.showDateInFormat(loan.applyDate)}
                                  </td>
                                  <td>
                                    <div className="dropdown dropdown-action p-cursor">
                                      <div
                                        className="action-icon dropdown-toggle"
                                        data-toggle="dropdown"
                                        aria-expanded="false"
                                      >
                                        <i className="material-icons">
                                          more_vert
                                        </i>
                                      </div>
                                      <div className="dropdown-menu dropdown-menu-right">
                                        <div
                                          className="dropdown-item"
                                          data-toggle="modal"
                                          data-target="#edit_leave"
                                          onClick={() =>
                                            this.setState({
                                              openLoanDetails: true,
                                              requestid: loan.requestid,
                                              staff: loan.staff,
                                            })
                                          }
                                        >
                                          <i className="fa fa-rss m-r-5"></i>{" "}
                                          View Approval Progress
                                        </div>
                                        <div
                                          className="dropdown-item"
                                          data-toggle="modal"
                                          data-target="#delete_leave"
                                          onClick={() =>
                                            this.setState({
                                              openLoanHistory: true,
                                              requestid: loan.requestid,
                                            })
                                          }
                                        >
                                          <i className="fa fa-history m-r-5"></i>{" "}
                                          View Repayment History
                                        </div>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              );
                            })
                          : ""}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            {/*<!-- /Page Content -->*/}

            {/*<!-- Add Leave Modal -->*/}
            <ApplyLoanModal
              employee={loggedEmployee}
              reload={() => this.componentDidMount()}
            />
            {/*<!-- /Add Leave Modal -->*/}

            {/*<!-- Edit Leave Modal -->*/}

            {/*<!-- /Edit Leave Modal -->*/}

            {openLoanDetails && (
              <LoanDetails
                requestid={requestid}
                show={openLoanDetails}
                staff={staff}
                onHide={() =>
                  this.setState({
                    openLoanDetails: false,
                  })
                }
              />
            )}
            {openLoanHistory && (
              <LoanHistory
                requestid={requestid}
                show={openLoanHistory}
                onHide={() =>
                  this.setState({
                    openLoanHistory: false,
                  })
                }
              />
            )}

            {/*<!-- Delete Leave Modal -->*/}

            {/*<!-- /Delete Leave Modal -->*/}
          </div>
          {/*<!-- /Page Wrapper -->*/}
        </div>
        {/*<!-- /Main Wrapper -->*/} {/* <!-- Sidebar Overlay --> */}
        <div className="sidebar-overlay" data-reff=""></div>
      </body>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    User: state.api.User,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(UserDashBoardLoan));
