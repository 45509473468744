import React, { useState, useEffect, useRef } from "react";
import completed from "../../assets/img/completed.svg";
import avg_goal from "../../assets/img/avg-goal.svg";
import inprogress from "../../assets/img/inprogress.svg";
import notstarted from "../../assets/img/notstarted.svg";
import total_goals from "../../assets/img/total-goals.svg";
import donughtchart from "../../assets/img/half-donught.svg";
import trendup from "../../assets/img/trend-up-01.svg";
import piechart from "../../assets/img/pie-chart.svg";
import meeting from "../../assets/img/meeting.svg";
import chevronright from "../../assets/img/chevron-right.svg";
import user from "../../assets/img/user.png";
import user1 from "../../assets/img/user-1.png";
import user2 from "../../assets/img/user-2.png";
import user3 from "../../assets/img/user-3.png";
import wavechart from "../../assets/img/wave-chart.svg";
import WaveChart from "./AreaChart";

import PercentCounter from "./PercentCounter";
import BarChart from "./BarChart";

const Overview = () => {
  const imgRef = useRef(null);

  useEffect(() => {
    const element = imgRef.current;
    if (element) {
      element.style.transition = "width 800ms ease-in-out";
      element.style.width = "100%";
    }
  }, []);
  return (
    <div className="d-flex flex-col gap-4">
      <div className="stats">
        <div className="stats-box border px-4">
          <div className="">
            <img src={total_goals} width={45} alt="" />
          </div>
          <div className="">
            <h1>430</h1>
            <p>Total Goals Set</p>
          </div>
        </div>
        <div className="stats-box border px-4">
          <div className="">
            <img src={completed} width={45} alt="" />
          </div>
          <div className="">
            <h1>235</h1>
            <p>Goals Completed</p>
          </div>
        </div>
        <div className="stats-box border px-4">
          <div className="">
            <img src={inprogress} width={45} alt="" />
          </div>
          <div className="">
            <h1>175</h1>
            <p>In-progress Goals</p>
          </div>
        </div>
        <div className="stats-box border px-4">
          <div className="">
            <img src={notstarted} width={45} alt="" />
          </div>
          <div className="">
            <h1>20</h1>
            <p>Not Started yet</p>
          </div>
        </div>
        <div className="stats-box border px-4">
          <div className="">
            <img src={avg_goal} width={45} alt="" />
          </div>
          <div className="">
            <h1>23 days</h1>
            <p>Goal Completion Time</p>
          </div>
        </div>
      </div>
      <div className="d-flex tw-gap-1 stat-chart justify-content-between">
        <div className="border tw-chart-grow tw-rounded-lg p-3 h-auto flex-grow-1">
          <div className="d-flex flex-wrap align-items-center justify-content-between">
            <div className="heading-chart">Goals Completion Rate</div>
            <div className="d-flex tw-gap-1 align-items-center">
              <div className="d-flex stat-status tw-gap-2 align-items-center">
                <div className="round tw-total-goals"></div>
                <p>Total Goals Set</p>
              </div>
              <div className="d-flex stat-status tw-gap-2 align-items-center">
                <div className="round tw-completed"></div>
                <p>Goals Completed</p>
              </div>
              <select name="year" id="" className="select-chart">
                <option value="this year">This Year</option>
                <option value="6 months ago">6 months ago</option>
                <option value="1 Year Ago">1 Year Ago</option>
                <option value="2 Years Ago">2 Years Ago</option>
              </select>
            </div>
          </div>
          <div className="d-flex mt-4 flex-wrap justify-content-between gap-5">
            <div className="d-flex flex-col gap-4">
              <div className="percent-stat align-items-center d-flex flex-col justify-content-between align-items-center p-4 tw-rounded-lg border">
                <div className="d-flex flex-col justify-content-center gap-3 align-items-center">
                  <PercentCounter />
                  <div className="d-flex  align-items-center gap-3">
                    <img src={trendup} width={18} alt="" />
                    <p className="">+2 Plus Q2</p>
                  </div>
                </div>
                <div className="">
                  <p className="">updated at: 4 Oct 2024 </p>
                </div>
              </div>
              <button
                className="px-4 py-2 border tw-rounded-lg tw-text-primary tw-view-all"
                style={{ fontWeight: 600 }}
              >
                View All Goals
              </button>
            </div>
            <div className="tw-pie-chart flex-grow-1">
              <div className=" h-400">
                <BarChart />
              </div>
            </div>
          </div>
        </div>
        <div className="border  tw-rounded-lg tw-chart-box h-auto p-4 d-flex flex-col tw-gap-1">
          <h1>Goal Status Distribution</h1>
          <img src={donughtchart} alt="" />
          <div className="d-flex tw-gap-1 align-items-center">
            <div className="d-flex stat-status tw-gap-2 align-items-center">
              <div className="round tw-completed"></div>
              <p>Completed</p>
            </div>
            <div className="d-flex stat-status tw-gap-2 align-items-center">
              <div className="round tw-inprogress"></div>
              <p>in progress</p>
            </div>
            <div className="d-flex stat-status tw-gap-2 align-items-center">
              <div className="round tw-not-started"></div>
              <p>Not Started</p>
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-between flex-wrap overflow-hidden gap-5">
        <div className="tw-group-1 border tw-rounded-lg p-4">
          <div className="d-flex flex-col gap-md">
            <h1 className="heading-chart">Upcoming Meetings</h1>
            <div className="d-flex align-items-center gap-5 justify-content-between">
              <div className="d-flex gap-5 align-items-center">
                <img src={meeting} width={45} alt="" />
                <div className="">
                  <h1 className="heading-chart">1:1 Meeting with Manager</h1>
                  <p className="">Wed 17 Dec - 1:00PM</p>
                </div>
              </div>
              <img src={chevronright} width={20} alt="" />
            </div>
            <div className="d-flex align-items-center gap-5 justify-content-between">
              <div className="d-flex gap-5 align-items-center">
                <img src={meeting} width={45} alt="" />
                <div className="">
                  <h1 className="heading-chart">1:1 Meeting with Manager</h1>
                  <p className="">Wed 17 Dec - 1:00PM</p>
                </div>
              </div>
              <img src={chevronright} width={20} alt="" />
            </div>
            <div className="d-flex align-items-center gap-5 justify-content-between">
              <div className="d-flex gap-5 align-items-center">
                <img src={meeting} width={45} alt="" />
                <div className="">
                  <h1 className="heading-chart">1:1 Meeting with Manager</h1>
                  <p className="">Wed 17 Dec - 1:00PM</p>
                </div>
              </div>
              <img src={chevronright} width={20} alt="" />
            </div>
            <div className="d-flex align-items-center gap-5 justify-content-between">
              <div className="d-flex gap-5 align-items-center">
                <img src={meeting} width={45} alt="" />
                <div className="">
                  <h1 className="heading-chart">1:1 Meeting with Manager</h1>
                  <p className="">Wed 17 Dec - 1:00PM</p>
                </div>
              </div>
              <img src={chevronright} width={20} alt="" />
            </div>
          </div>
        </div>
        <div className="tw-group-1 border overflow-hidden tw-rounded-lg p-4">
          <div className="d-flex flex-col gap-md">
            <h1 className="heading-chart">Top 5 Performance</h1>
            <div className="d-flex align-items-center gap-5 justify-content-between">
              <div className="d-flex gap-5 align-items-center">
                <img src={user} width={35} alt="" />
                <div className="">
                  <h1 className="heading-chart">John Dave</h1>
                  <p className="">Research</p>
                </div>
              </div>
              <img src={chevronright} width={20} alt="" />
            </div>
            <div className="d-flex align-items-center gap-5 justify-content-between">
              <div className="d-flex gap-5 align-items-center">
                <img src={user1} width={35} alt="" />
                <div className="">
                  <h1 className="heading-chart">Esther Howard</h1>
                  <p className="">Data analyst</p>
                </div>
              </div>
              <img src={chevronright} width={20} alt="" />
            </div>
            <div className="d-flex align-items-center gap-5 justify-content-between">
              <div className="d-flex gap-5 align-items-center">
                <img src={user2} width={35} alt="" />
                <div className="">
                  <h1 className="heading-chart">Lesllie Alexander</h1>
                  <p className="">Development</p>
                </div>
              </div>
              <img src={chevronright} width={20} alt="" />
            </div>
            <div className="d-flex align-items-center gap-5 justify-content-between">
              <div className="d-flex gap-5 align-items-center">
                <img src={user3} width={35} alt="" />
                <div className="">
                  <h1 className="heading-chart">Robert Fox</h1>
                  <p className="">Finance</p>
                </div>
              </div>
              <img src={chevronright} width={20} alt="" />
            </div>
            <div className="d-flex align-items-center gap-5 justify-content-between">
              <div className="d-flex gap-5 align-items-center">
                <img src={user3} width={35} alt="" />
                <div className="">
                  <h1 className="heading-chart">Robert Fox</h1>
                  <p className="">Finance</p>
                </div>
              </div>
              <img src={chevronright} width={20} alt="" />
            </div>
          </div>
        </div>

        <div className="border tw-rounded-lg mt-3 p-2 px-4 flex-grow-1">
          <div className="d-flex flex-wrap align-items-center mb-3 justify-content-between">
            <div className="heading-chart">Goal Progress Timeline</div>
            <select name="year" id="" className="select-chart">
              <option value="this year">This Year</option>
              <option value="6 months ago">6 months ago</option>
              <option value="1 Year Ago">1 Year Ago</option>
              <option value="2 Years Ago">2 Years Ago</option>
            </select>
          </div>
          <div className="w-100">
            {/* <img
              src={wavechart}
              alt="Wave Chart"
              ref={imgRef}
              style={{ width: "0", overflow: "hidden" }}
            /> */}
            <WaveChart />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Overview;
