import React, { Fragment } from "react";
import { URLS } from "../../urls";
import moment from "moment";
import DatePicker from "react-datetime";
import { Form, Col, Row, Image } from "react-bootstrap";
import { branch_id, sendFormData } from "../../../utils";
import { toast } from "react-toastify";
import ModalLoader from "../../ModalLoader";
import Select from "react-select";
import Cookies from "universal-cookie";

export default class ApplyLeaveModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      buttonText: "Submit",
      isWaiting: false,
      isLoading: false,
      applyLeave: {
        employee: {
          id: null,
          name: "",
          image: "",
          designation: "",
        },
        leaveId: "",
        name: "",
        from: moment(new Date()).format("DD/MM/YYYY"),
        to: moment(new Date()).format("DD/MM/YYYY"),
        days: 1,
        remaining: 0,
        reason: "",
        status: "New",
        appliedDate: "",
        branch_id,
      },
      leaveTypes: [],

      // ----------------
      staff: "",
      departments: [],
      users: [],
      employee: {},
      supervisor: null,
      requester: null,
    };
  }

  componentDidMount() {
    if (process.env.REACT_APP_HAS_INVEX !== "true") {
      return toast.error("Please activate form module to use leave");
    }

    const cookies = new Cookies();

    const employee = cookies.get("employee");
    this.getDepartments();

    this.getSupervisors(employee.id);

    fetch(
      `${URLS.backendEmployees}/get-employee-from-invex-details/${employee.id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data) {
          this.setState({
            requester: data?.invexStaff?.Staff_ID,
          });
        }
      });
  }

  getDepartments = async () => {
    // await waitFor(5000);
    if (process.env.REACT_APP_HAS_INVEX === "true") {
      let response = await fetch(`${URLS.mainSiteBackend}/users/departments`, {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        // credentials: "include",
      });

      if (!response.ok) {
        response = await response.json();
        console.log(response.message);

        throw new Error(response.message);
      }

      const { data } = await response.json();

      const res = data?.departments?.map((el) => ({
        value: el?.Department,
        label: el?.Department,
      }));

      this.setState({
        departments: [
          { value: "", label: "Select Recipient's Department" },
          ...res,
        ],
      });
    }
  };

  getUsersInDepartments = async (department) => {
    let response = await fetch(
      `${URLS.mainSiteBackend}/users/by-department/${department}`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        // credentials: "include",
      }
    );

    if (!response.ok) {
      response = await response.json();
      console.log(response.message);
      throw new Error(response.message);
    }
    const { data } = await response.json();
    const res = data?.users?.map((el) => ({
      value: el?.Staff_ID,
      label: el?.Name,
    }));

    this.setState({
      users: [{ value: "", label: "Select Recipient" }, ...res],
    });
  };

  componentWillReceiveProps(props) {
    if (props.leaveTypes.length) {
      const { applyLeave } = this.state;
      applyLeave.leaveId = props.leaveTypes[0]._id;
      applyLeave.name = props.leaveTypes[0].name;
      applyLeave.remaining = props.leaveTypes[0].remaining;
      applyLeave.days = applyLeave.remaining ? 1 : 0;
      this.setState({
        leaveTypes: props.leaveTypes,
        applyLeave,
      });
    }

    if (props.employee) {
      const { applyLeave } = this.state;
      applyLeave.employee = props.employee;
      this.setState({
        applyLeave,
      });

      // this.getSupervisors(props.employee.id);

      // fetch(
      //   `${URLS.backendEmployees}/get-employee-from-invex-details/${props.employee.id}`,
      //   {
      //     method: "GET",
      //     headers: {
      //       "Content-Type": "application/json",
      //     },
      //   }
      // )
      //   .then((res) => res.json())
      //   .then((data) => {
      //     if (data) {
      //       this.setState({
      //         requester: data?.invexStaff?.Staff_ID,
      //       });
      //     }
      //   });
    }
    // this.getDepartments();
  }

  getSupervisors = async (id) => {
    try {
      this.setState({ isLoading: true });
      let response = await fetch(
        `${URLS.backendEmployees}/getOneEmployeeSupervisor/${id}`,
        {
          method: "GET",
          headers: {
            Accept: "Application/json",
            "Content-Type": "Application/json",
          },
          // credentials: "include",
        }
      );

      if (!response.ok) {
        response = await response.json();
        throw new Error(response.message);
      }
      const { foundSupervisor } = await response.json();

      if (foundSupervisor) {
        if (process.env.REACT_APP_HAS_INVEX === "true") {
          let response = await fetch(
            `${URLS.mainSiteBackend}/users/${foundSupervisor?.Staff_ID}`,
            {
              method: "GET",
              headers: {
                Accept: "Application/json",
                "Content-Type": "Application/json",
              },
              // credentials: "include",
            }
          );

          if (!response.ok) {
            response = await response.json();

            throw new Error(response.message);
          }

          const { data } = await response.json();

          this.setState({
            supervisor: data,
            staff: data?.Staff_ID,
          });
        }
      } else {
        this.setState({
          supervisor: null,
          staff: "",
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.setState({ isLoading: false });
    }
  };

  updateLeaveName = (e) => {
    const { applyLeave, leaveTypes } = this.state;

    applyLeave.leaveId = e.target.value;
    const leaveType = leaveTypes.find((l) => l._id == applyLeave.leaveId);
    applyLeave.name = leaveType?.name;
    applyLeave.remaining = leaveType?.remaining;
    applyLeave.days = applyLeave?.remaining ? 1 : 0;

    applyLeave.from = moment(new Date()).format("DD/MM/YYYY");
    applyLeave.to = moment(new Date()).format("DD/MM/YYYY");
    this.setState({
      applyLeave,
    });
  };
  updateLeaveFrom = (event) => {
    const { applyLeave } = this.state;
    applyLeave.from = event.target
      ? event.target.value
      : moment(event).format("DD/MM/YYYY");
    const diff = moment(applyLeave.to, "DD/MM/YYYY").diff(
      moment(applyLeave.from, "DD/MM/YYYY"),
      "days"
    );
    if (diff < 0) {
      applyLeave.to = applyLeave.from;
      applyLeave.days = applyLeave.remaining ? 1 : 0;
    } else {
      applyLeave.days = diff + 1;
    }
    this.setState({
      applyLeave,
    });
  };
  updateLeaveTo = (event) => {
    const { applyLeave } = this.state;
    applyLeave.to = event.target
      ? event.target.value
      : moment(event).format("DD/MM/YYYY");
    applyLeave.days =
      moment(applyLeave.to, "DD/MM/YYYY").diff(
        moment(applyLeave.from, "DD/MM/YYYY"),
        "days"
      ) + 1;
    this.setState({
      applyLeave,
    });
  };
  updateLeaveDays = (e) => {
    const { applyLeave } = this.state;
    applyLeave.days = e.target.value;
    this.setState({
      applyLeave,
    });
  };
  updateLeaveRemaining = (e) => {
    const { applyLeave } = this.state;
    applyLeave.remaining = e.target.value;
    this.setState({
      applyLeave,
    });
  };
  updateLeaveReason = (e) => {
    const { applyLeave } = this.state;
    applyLeave.reason = e.target.value;
    this.setState({
      applyLeave,
    });
  };

  submit = async (e) => {
    e.preventDefault();
    this.setState({ buttonText: "Submitting ...", isWaiting: true });
    const { applyLeave, staff, requester } = this.state;

    if (Object.entries(applyLeave.employee).length < 1) {
      this.setState({ buttonText: "Submit", isWaiting: false });
      return toast.error("Employee is needed, reload the page", {
        position: "top-right",
      });
    }

    const employeeName = applyLeave.employee.name;
    const employeeId = applyLeave.employee.id;

    let newSave = JSON.stringify(applyLeave);
    newSave = JSON.parse(newSave);
    newSave.appliedDate = new Date();
    newSave.remaining = parseInt(newSave.remaining) - parseInt(newSave.days);
    newSave.to = moment(newSave.to, "DD/MM/YYYY");
    newSave.from = moment(newSave.from, "DD/MM/YYYY");

    const requisition = {
      title: `${employeeName} Leave Application`,
      description: `${employeeName} applied for a leave that will run from ${newSave.from} to ${newSave.to}`,
      type: "Leave",
      amount: 0,
      requestto: staff,
      status: "Pending",
      regdate: moment(new Date()).format("YYYY-MM-DD"),
      requestby: requester ? requester : staff,
      vendor: "",
      comment: applyLeave.reason,
      filledFormData: "",
      loanDetails: JSON.stringify({ employeeName, employeeId }),
    };

    const formData = new FormData();
    // eslint-disable-next-line no-unused-vars
    for (const [key, value] of Object.entries(requisition)) {
      formData.append(key, value);
    }

    const token = "dummy token";

    if (process.env.REACT_APP_HAS_INVEX === "true") {
      const { data } = await sendFormData({
        url: `${URLS.mainSiteBackend}/requisition/create`,
        token,
        formData,
      });

      if (data) {
        newSave.requestid = data?.requisition?.requestid;
        fetch(`${URLS.backendApplyLeaves}/add`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
          },
          body: JSON.stringify({ applyLeave: newSave }),
        }).then((res) => {
          if (res.ok) {
            res.json().then((result) => {
              setTimeout(() => {
                window.$("#add_leave").modal("toggle");
                this.setState({
                  buttonText: "Submit",
                  isWaiting: false,
                  applyLeave: {
                    employee: {
                      id: null,
                      name: "",
                      image: "",
                      designation: "",
                    },
                    leaveId: "",
                    name: "",
                    from: moment(new Date()).format("DD/MM/YYYY"),
                    to: moment(new Date()).format("DD/MM/YYYY"),
                    days: 1,
                    remaining: 0,
                    reason: "",
                    status: "New",
                    appliedDate: "",
                  },
                });
                this.props.updateAppliedLeavesList(result.applyLeave);
              }, 500);
              toast.success("leave applied successfully", {
                position: "top-right",
              });
            });
          } else {
            res.json().then((responseJson) => {
              console.log(responseJson);

              toast.error("Error occurred, Check the form again.", {
                position: "top-right",
              });
              this.setState({ buttonText: "Submit", isWaiting: false });
            });
          }
        });
      } else {
        toast("Error occurred, Check the form again.", {
          type: "error",
          position: "top-right",
        });
        this.setState({ buttonText: "Submit", isWaiting: false });
      }
    } else {
      return toast.error("Please connect to Invex for Leave Approval", {
        position: "top-right",
      });
    }
  };
  validationFrom = (currentDate) => {
    return currentDate.isAfter(moment(new Date()).add(-1, "days"));
  };
  validationTo = (currentDate) => {
    const { applyLeave } = this.state;
    const addDays = applyLeave.remaining ? applyLeave.remaining : 0;
    if (applyLeave.from) {
      return (
        currentDate.isBefore(
          moment(applyLeave.from, "DD/MM/YYYY").add(addDays, "days")
        ) &&
        currentDate.isAfter(
          moment(applyLeave.from, "DD/MM/YYYY").add(-1, "days")
        )
      );
    } else {
      return currentDate.isAfter(moment(new Date()).add(-1, "days"));
    }
  };

  disableDate = (currentDate) => {
    return false;
  };

  render() {
    const {
      applyLeave,
      leaveTypes,
      buttonText,
      users,
      departments,
      staff,
      supervisor,
      isLoading,
    } = this.state;
    // const {employees} = this.props;
    return (
      <div id="add_leave" className="modal custom-modal fade" role="dialog">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Apply Leave</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form onSubmit={this.submit}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    Leave Type <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    as="select"
                    value={applyLeave.leaveId}
                    onChange={this.updateLeaveName}
                    style={{ height: "35px" }}
                  >
                    <option value="">Select Type</option>
                    {leaveTypes.length
                      ? leaveTypes.map((leaveType) => {
                          return (
                            <option value={leaveType._id}>
                              {leaveType.name}
                            </option>
                          );
                        })
                      : ""}
                  </Form.Control>
                </Form.Group>

                <Row>
                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Label>
                        From <span className="text-danger">*</span>
                      </Form.Label>
                      <div className="cal-icon">
                        <DatePicker
                          value={applyLeave.from}
                          closeOnSelect={true}
                          onChange={this.updateLeaveFrom}
                          isValidDate={
                            applyLeave?.employee?.id
                              ? this.validationFrom
                              : this.disableDate
                          }
                          required={true}
                        />
                      </div>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Label>
                        To <span className="text-danger">*</span>
                      </Form.Label>
                      <div className="cal-icon">
                        <DatePicker
                          value={applyLeave.to}
                          closeOnSelect={true}
                          onChange={this.updateLeaveTo}
                          isValidDate={
                            applyLeave?.employee?.id
                              ? this.validationTo
                              : this.disableDate
                          }
                          required={true}
                        />
                      </div>
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Label>
                        Number of days <span className="text-danger">*</span>
                      </Form.Label>
                      <input
                        className="form-control"
                        disabled
                        type="number"
                        value={applyLeave.days}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Label>
                        Remaining Leaves <span className="text-danger">*</span>
                      </Form.Label>
                      <input
                        className="form-control"
                        disabled
                        type="number"
                        value={applyLeave.remaining}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                {/*  */}
                <div className="d-flex align-items-center my-4">
                  <h4 className="pr-2">Send Request To</h4>{" "}
                  <hr className="flex-grow-1 m-0" />
                </div>

                {!supervisor ? (
                  <div className="row gap-3">
                    <Form.Group className="col mb-3">
                      <Form.Label>
                        Department <span className="text-danger">*</span> :
                      </Form.Label>
                      <Select
                        classNamePrefix={`form-select-analytics`}
                        isSearchable={true}
                        placeholder="Select Recipient's Department"
                        options={departments}
                        // value={departments?.find(
                        //   (el) => el.value === queryParams.by
                        // )}
                        onChange={(selected) => {
                          this.getUsersInDepartments(selected?.value);
                        }}
                      />
                    </Form.Group>

                    {users.length > 0 && (
                      <Form.Group className="col mb-3">
                        <Form.Label>
                          Recipient <span className="text-danger">*</span> :
                        </Form.Label>

                        <Select
                          classNamePrefix={`form-select-analytics`}
                          isSearchable={true}
                          placeholder="Select Recipient"
                          options={users}
                          isRequired
                          // value={departments?.find(
                          //   (el) => el.value === queryParams.by
                          // )}
                          onChange={(selected) => {
                            this.setState({ staff: selected?.value });
                          }}
                        />
                      </Form.Group>
                    )}
                  </div>
                ) : (
                  <div className="my-3">
                    <input
                      className="form-control"
                      disabled
                      type="text"
                      value={supervisor?.Name}
                    />
                  </div>
                )}

                {/*  */}

                <div className="form-group">
                  <label>Comment (Optional)</label>
                  <textarea
                    rows="4"
                    className="form-control"
                    value={applyLeave.reason}
                    onChange={this.updateLeaveReason}
                    disabled={applyLeave?.employee?.id ? false : true}
                  ></textarea>
                </div>
                <div className="submit-section">
                  <button
                    className="btn btn-primary submit-btn"
                    disabled={!applyLeave.remaining}
                  >
                    {buttonText}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <ModalLoader show={isLoading} />
      </div>
    );
  }
}
