import React, { useEffect, useState } from "react";

import { Button, Form, Modal, Dropdown } from "react-bootstrap";
import ModalLoader from "../ModalLoader";
import { toast } from "react-toastify";
import { URLS } from "../urls";
import { checkStartDate, fetchActionsUtil, useCookie } from "../../utils";
import Cookies from "universal-cookie";
import moment from "moment";
import Papa from "papaparse";
import { CloudIcon, ExcelIcon, ImportIcon } from "../icons";

export default function AddAttendanceModal({
  setShowUploadModal,
  selectedTimesheetToEdit,
  setSelectedTimesheetToEdit,
  showAttendance,
  updatesetPayrollDate,
  setshowSaveButton,
}) {
  const cookies = new Cookies();
  const userId = cookies.get("userId");
  const nowDate = new Date();

  const branch_id = useCookie("branch_id");

  const [payrollDate, setPayrollDate] = useState({
    payrollMonth: selectedTimesheetToEdit
      ? selectedTimesheetToEdit.payrollMonth
      : nowDate.getMonth() + 1,
    payrollYear: selectedTimesheetToEdit
      ? selectedTimesheetToEdit.payrollYear
      : nowDate.getFullYear(),
  });
  const [loading, setLoading] = useState(false);
  const [CheckTotal, setCheckTotal] = useState(0);
  const [permission, setShowPermission] = useState([]);

  const [excellDetails, setExcellDetails] = useState([]);
  const [attendanceSetting, setAttendanceSetting] = useState({});
  const [attendanceText, setAttendanceText] = useState("Import Attendance");

  useEffect(() => {
    getPermission({ stDt: "", eDt: "", UId: "" });
    getAttendanceSettings();
  }, []);

  const uploadTimeSheet = (e) => {
    e.preventDefault(e);
    // setLoading(true);
    // const formData = new FormData(e.target);
    const values = {
      branch_id,
      payrollMonth: payrollDate.payrollMonth,
      payrollYear: payrollDate.payrollYear,
      employeeId: userId,
      excellDetails,
    };
    showAttendance(values);
    setshowSaveButton(true);
    updatesetPayrollDate({
      payrollYear: parseInt(payrollDate.payrollYear),
      payrollMonth: parseInt(payrollDate.payrollMonth),
    });
    setShowUploadModal(false);
  };

  const currentMonthDates = (date, format) => {
    return new Array(moment(date, format).daysInMonth())
      .fill(null)
      .map((x, i) => moment(date, format).startOf("month").add(i, "days"));
  };

  const getPermission = ({ stDt, eDt, UId }) => {
    setLoading(true);
    const token = "dummy token";
    fetch(
      `${URLS.backendpermmission}/by-branch/${branch_id}?userID=${UId}&startDate=${stDt}&endDate=${eDt}&status=Approved`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      }
    )
      .then((res) => res.json())
      .then((result) => {
        // console.log(result);
        setShowPermission(result?.permission);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        toast("Unable to get TimeSheet", {
          type: "error",
        });
      });
  };

  // const getLeaves = ({ date }) => {
  //   setLoading(true);
  //   const token = "dummy token";
  //   fetch(
  //     `${URLS.backendApplyLeaves}/dynamic-by-months/${branch_id}?date=${date}`,
  //     {
  //       method: "GET",
  //       headers: {
  //         Accept: "application/json",
  //         "Content-Type": "application/json",
  //         Authorization: `Token ${token}`,
  //       },
  //     }
  //   )
  //     .then((res) => res.json())
  //     .then((result) => {
  //       // console.log(result);
  //       setShowLeaves(result?.leave);
  //       setLoading(false);
  //     })
  //     .catch((err) => {
  //       setLoading(false);
  //       toast("Unable to get TimeSheet", {
  //         type: "error",
  //       });
  //     });
  // };

  const getAttendanceSettings = () => {
    setLoading(true);
    fetch(`${URLS.backendattendance}/get-settings/${branch_id}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        //   Authorization: `Token ${token}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        if (result?.attendance) {
          setAttendanceSetting({
            ...result.attendance,
          });
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        toast("Unable to get Settings", {
          type: "error",
        });
      });
  };

  const GeneralPermission = permission.filter((el) => el?.mode === "General");

  const checkForPermission = ({ staffDetails, el, checkType }) => {
    const userPermission = permission.filter(
      (el) => el?.userID?.Staff_ID === staffDetails?.ID
    );

    // console.log({ leaves, staffDetails });

    let absentPermitted = null;

    absentPermitted = userPermission.find(
      (ab) =>
        moment(
          moment(el.date, "MM/DD/YYYY").format("MM/DD/YYYY"),
          "MM/DD/YYYY"
        ).isBetween(
          moment(
            moment(ab.startDate, "YYYY-MM-DD").format("MM/DD/YYYY"),
            "MM/DD/YYYY"
          ),
          moment(
            moment(ab.endDate, "YYYY-MM-DD").format("MM/DD/YYYY"),
            "MM/DD/YYYY"
          ),
          null,
          []
        ) && ab.type === checkType
    );

    if (!absentPermitted) {
      absentPermitted = GeneralPermission.find(
        (ab) =>
          moment(
            moment(el.date, "MM/DD/YYYY").format("MM/DD/YYYY"),
            "MM/DD/YYYY"
          ).isBetween(
            moment(
              moment(ab.startDate, "YYYY-MM-DD").format("MM/DD/YYYY"),
              "MM/DD/YYYY"
            ),
            moment(
              moment(ab.endDate, "YYYY-MM-DD").format("MM/DD/YYYY"),
              "MM/DD/YYYY"
            ),
            null,
            []
          ) && ab.type === checkType
      );
    }

    return absentPermitted;
  };

  const checkForLeave = ({ staffDetails, el, leave }) => {
    const userLeave = leave.filter(
      (el) => el?.employee?.id?.Staff_ID === staffDetails?.ID
    );

    const onLeave = userLeave.find((ab) =>
      moment(
        moment(el.date, "MM/DD/YYYY").format("MM/DD/YYYY"),
        "MM/DD/YYYY"
      ).isBetween(
        moment(
          moment(ab.from, "YYYY-MM-DD").format("MM/DD/YYYY"),
          "MM/DD/YYYY"
        ),
        moment(moment(ab.to, "YYYY-MM-DD").format("MM/DD/YYYY"), "MM/DD/YYYY"),
        null,
        []
      )
    );

    // console.log({ userLeave, leave, onLeave });
    return onLeave;
  };

  const manipulateUserAttendanceData = ({ staffForMonths, leave }) => {
    // console.log(staffForMonths);

    let curDate = currentMonthDates(
      `${payrollDate.payrollMonth}/1/${payrollDate.payrollYear}`,
      "MM/DD/YYYY"
    );

    curDate = curDate.map((el) => ({
      date: el.format("M/D/YYYY"),
      day: el.format("dddd"),
    }));

    const umquiIds = [...new Set(staffForMonths.map((el) => el.WorkId))];

    const data = umquiIds?.map((el) => {
      let staffs = staffForMonths.filter((s) => s.WorkId === el);

      let umquiDays = [...new Set(staffs.map((el) => el.Date))];

      umquiDays = umquiDays.map((uniqueDate) => {
        const dataForDay = staffs
          .filter((staff) => staff.Date === uniqueDate)
          .sort((p1, p2) =>
            p1.Time < p2.Time ? 1 : p1.Time > p2.Time ? -1 : 0
          );

        //HH:mm:ss was there before it was changed to HH:mm for hydroFerric

        //   Check for No checkout
        if (dataForDay.length === 1) {
          return {
            in: dataForDay[0]?.Time,
            out: "--",
            Name: dataForDay[0]?.User,
            ID: dataForDay[0]?.WorkId,
            Date: moment(dataForDay[0]?.Date, "MM/DD/YYYY"),
            result: "No SignOut",
            startDate: dataForDay[0]?.startDate,
          };
        }
        //   Check for Early Closure
        else if (
          moment(dataForDay[1]?.Time, "HH:mm").isBefore(
            moment(attendanceSetting?.closingTime, "HH:mm")
          )
        ) {
          return {
            in: dataForDay[0]?.Time,
            out: dataForDay[1]?.Time,
            Name: dataForDay[0]?.User,
            ID: dataForDay[0]?.WorkId,
            Date: moment(dataForDay[0]?.Date, "MM/DD/YYYY"),
            result: "Early Closure",
            startDate: dataForDay[0]?.startDate,
          };
        }
        //   Check for Lateness
        else if (
          moment(attendanceSetting?.resumeTime, "HH:mm").isBefore(
            moment(dataForDay[0]?.Time, "HH:mm")
          )
        ) {
          return {
            in: dataForDay[0]?.Time,
            out: dataForDay[1]?.Time,
            Name: dataForDay[0]?.User,
            ID: dataForDay[0]?.WorkId,
            Date: moment(dataForDay[0]?.Date, "MM/DD/YYYY"),
            result: "Late",
            startDate: dataForDay[0]?.startDate,
          };
        } else {
          return {
            in: dataForDay[0]?.Time,
            out: dataForDay[1]?.Time,
            Name: dataForDay[0]?.User,
            ID: dataForDay[0]?.WorkId,
            Date: moment(dataForDay[0]?.Date, "MM/DD/YYYY"),
            result: "Good",
            startDate: dataForDay[0]?.startDate,
          };
        }
      });

      return { ...umquiDays };
    });

    const members = [];

    // Check the dates of the month and find weekend and their absents
    curDate.forEach((el) => {
      data.forEach((staffs) => {
        const staffDetails = Object.values(staffs);

        const foundDate = staffDetails?.find(
          (sd) => sd?.Date?.format("M/D/YYYY") === el?.date?.toString()
        );

        const isLeave = checkForLeave({
          staffDetails: staffDetails[0],
          el,
          leave,
        });

        const notAStaff = checkStartDate({
          date: moment(el?.date, "MM/DD/YYYY").format("DD/MM/YYYY"),
          startDate: staffDetails[0]?.startDate,
        });

        // console.log("isLeave => ", isLeave);
        // console.log("notAStaff => ", notAStaff, el);
        if (foundDate) {
          if (isLeave) {
            members.push({
              ...foundDate,
              result: "OnLeave",
            });
          } else if (notAStaff) {
            members.push({
              ...staffDetails[0],
              in: "--",
              out: "--",
              result: "Not a staff",
              Date: moment(el.date.toString(), "MM/DD/YYYY"),
            });
          } else {
            if (foundDate.result === "No SignOut") {
              if (
                checkForPermission({
                  staffDetails: staffDetails[0],
                  el,
                  checkType: "NoSignOut",
                })
              ) {
                members.push({
                  ...foundDate,
                  result: "Permitted",
                });
              } else {
                members.push({ ...foundDate });
              }
            } else if (foundDate.result === "Late") {
              if (
                checkForPermission({
                  staffDetails: staffDetails[0],
                  el,
                  checkType: "Lateness",
                })
              ) {
                members.push({
                  ...foundDate,
                  result: "Permitted",
                });
              } else {
                members.push({ ...foundDate });
              }
            } else if (foundDate.result === "Early Closure") {
              if (
                checkForPermission({
                  staffDetails: staffDetails[0],
                  el,
                  checkType: "EarlyClosure",
                })
              ) {
                members.push({
                  ...foundDate,
                  result: "Permitted",
                });
              } else {
                members.push({ ...foundDate });
              }
            } else {
              members.push({ ...foundDate });
            }
          }
        } else if (
          el.day.toString() === "Sunday" ||
          el.day.toString() === "Saturday"
        ) {
          members.push({
            ...staffDetails[0],
            in: "--",
            out: "--",
            result: "Weekend",
            Date: moment(el.date.toString(), "MM/DD/YYYY"),
          });
        } else {
          if (isLeave) {
            members.push({
              ...staffDetails[0],
              in: "--",
              out: "--",
              result: "OnLeave",
              Date: moment(el.date.toString(), "MM/DD/YYYY"),
            });
          } else if (notAStaff) {
            members.push({
              ...staffDetails[0],
              in: "--",
              out: "--",
              result: "Not a staff",
              Date: moment(el.date.toString(), "MM/DD/YYYY"),
            });
          } else {
            if (
              checkForPermission({
                staffDetails: staffDetails[0],
                el,
                checkType: "Absent",
              })
            ) {
              members.push({
                ...staffDetails[0],
                in: "--",
                out: "--",
                result: "Permitted",
                Date: moment(el.date.toString(), "MM/DD/YYYY"),
              });
            } else {
              members.push({
                ...staffDetails[0],
                in: "--",
                out: "--",
                result: "Absent",
                Date: moment(el.date.toString(), "MM/DD/YYYY"),
              });
            }
          }
        }
      });
    });

    // console.log(members);
    setCheckTotal(members.length);
    setExcellDetails(members);

    setLoading(false);
  };

  function handleFile({ eventData: e, chooseDate }) {
    setLoading(true);

    const file = e?.target?.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = async function ({ target }) {
        try {
          const { leave } = await fetchActionsUtil(
            `${URLS.backendApplyLeaves}/dynamic-by-months/${branch_id}?date=${payrollDate.payrollYear}-${payrollDate.payrollMonth}-1`
          );

          const csv = Papa.parse(target?.result, {
            header: true,
          });

          const parsedData = csv?.data;

          const staffForMonths = parsedData?.filter((el) =>
            moment(
              moment(el.Date, "MM/DD/YYYY").format("MM/DD/YYYY"),
              "MM/DD/YYYY"
            ).isSame(
              moment(
                moment(chooseDate ? new Date(chooseDate) : new Date()).format(
                  "MM/DD/YYYY"
                ),
                "MM/DD/YYYY"
              ),
              "month"
            )
          );

          manipulateUserAttendanceData({ staffForMonths, leave });
        } catch (err) {
          console.log(err);
        }
      };
      reader.readAsText(file);
    }
  }

  const getFromInvexAndMobile = async ({ mobile }) => {
    setLoading(true);
    const current = moment(
      `${payrollDate.payrollYear}-${payrollDate.payrollMonth}-1`,
      "YYYY-MM-DD"
    ).daysInMonth();

    // const startDate = `${payrollDate.payrollYear}-${payrollDate.payrollMonth}-1`;

    // const endDate = `${payrollDate.payrollYear}-${payrollDate.payrollMonth}-${current}`;

    let attendace;

    if (mobile) {
      const data = await fetchActionsUtil(
        `${URLS.backendattendance}/get-attendance-from-mobile-app?branchId=${branch_id}&month=${payrollDate.payrollMonth}&year=${payrollDate.payrollYear}`
      );

      attendace = data?.attendace;
    } else {
      const month =
        `${payrollDate.payrollMonth}`.split("").length > 1
          ? `${payrollDate.payrollMonth}`
          : `0${payrollDate.payrollMonth}`;

      const startDate = `${payrollDate.payrollYear}/${month}`;

      const endDate = `${payrollDate.payrollYear}/${payrollDate.payrollMonth}/${current}`;

      const data = await fetchActionsUtil(
        `${URLS.mainSiteBackend}/users/attendance/log?startDate=${startDate}&endDate=${endDate}`
      );

      attendace = data?.attendace;
    }
    // console.log({ attendace });

    const { employees } = await fetchActionsUtil(
      `${URLS.backendEmployees}/get/${branch_id}`
    );

    const { leave } = await fetchActionsUtil(
      `${URLS.backendApplyLeaves}/dynamic-by-months/${branch_id}?date=${payrollDate.payrollYear}-${payrollDate.payrollMonth}-1`
    );

    const umquiIds = [
      ...new Set(attendace?.map((el) => `${el.Employee_ID}-${el.Access_Date}`)),
    ];

    const cleanedData = umquiIds
      .map((el) => {
        const uniqueEmployees = attendace
          ?.filter((ft) => el === `${ft.Employee_ID}-${ft.Access_Date}`)
          .sort((p1, p2) => (p1.id < p2.id ? -1 : p1.id > p2.id ? 1 : 0))
          .filter((ele, i, arr) => i === 0 || i === arr.length - 1)
          .map((ele) => {
            const user = employees?.find(
              (em) => em.Staff_ID === ele?.Employee_ID
            );
            const userName = user?.personalInfo?.name;
            const startDate = user?.jobInfo?.startDate;

            const name = `${userName?.first ? userName?.first : ""} ${
              userName?.middle ? userName?.middle : ""
            } ${userName?.last ? userName?.last : ""}`;

            return {
              User: name,
              WorkId: ele?.Employee_ID,
              CardNo: "NULL",
              Date: moment(ele?.Access_Date, "YYYY-MM-DD").format("MM/DD/YYYY"),
              Time: moment(
                ele?.Access_Date_and_Time,
                "YYYY-MM-DD HH:mm"
              ).format("H:mm"),
              "IN/OUT": "IN",
              EventCode: "30038",
              startDate,
            };
          });

        return uniqueEmployees;
      })
      .flat(1);
    manipulateUserAttendanceData({ staffForMonths: cleanedData, leave });
  };

  const cancelRequest = (e) => {
    e.preventDefault(e);
    setPayrollDate({
      payrollMonth: nowDate.getMonth() + 1,
      payrollYear: nowDate.getFullYear(),
    });
    setExcellDetails([]);
    setAttendanceText("Import Attendance");
    setCheckTotal(0);
  };

  return (
    <>
      <Modal
        className="custom-modal"
        show={true}
        centered
        onHide={() => {
          if (setSelectedTimesheetToEdit) setSelectedTimesheetToEdit();
          setShowUploadModal(false);
        }}
      >
        <Form>
          <Modal.Header closeButton>
            <Modal.Title>Import Attendance</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <input name="employeeId" type="hidden" value={userId} />
            <input name="branch_id" type="hidden" value={branch_id} />

            <div className="d-flex justify-content-center my-4">
              <div className="d-flex">
                <div className="form-inline">
                  <label>Month</label>
                  <select
                    className="form-control mx-2"
                    name="payrollMonth"
                    value={payrollDate.payrollMonth}
                    onChange={(e) =>
                      setPayrollDate({
                        payrollMonth: e.target.value,
                        payrollYear: payrollDate.payrollYear,
                      })
                    }
                  >
                    {[...Array(12)].map((e, index) => (
                      <option key={index}>{index + 1}</option>
                    ))}
                  </select>
                </div>
                <div className="form-inline ml-3">
                  <label>Year</label>
                  <input
                    type="number"
                    name="payrollYear"
                    style={{ width: "100px" }}
                    className="form-control mx-2"
                    value={payrollDate.payrollYear}
                    onChange={(e) =>
                      setPayrollDate({
                        payrollMonth: payrollDate.payrollMonth,
                        payrollYear: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
            </div>

            <Dropdown className="d-flex justify-content-center">
              <Dropdown.Toggle variant="primary" bsPrefix="">
                <ImportIcon color="#fff" /> {attendanceText}
              </Dropdown.Toggle>

              <Dropdown.Menu
                popperConfig={{
                  strategy: "fixed",
                }}
                renderOnMount
                className="text-center"
              >
                <Dropdown.Item as="label" className="p-cursor" title="Import">
                  <input
                    type="file"
                    className="d-none"
                    onChange={(e) => {
                      handleFile({
                        eventData: e,
                        chooseDate: `${payrollDate.payrollMonth}/1/${payrollDate.payrollYear}`,
                      });
                      setAttendanceText("Attendance From Excel");
                    }}
                    accept=".csv"
                  />
                  <ExcelIcon color="#008000" />{" "}
                  <span className="mx-4">Excel</span>
                </Dropdown.Item>
                <Dropdown.Item
                  as="div"
                  onClick={() => {
                    getFromInvexAndMobile({ mobile: false });
                    setAttendanceText("Attendance From Cloud");
                  }}
                  className="p-cursor"
                >
                  <CloudIcon color="#2463ae" />{" "}
                  <span className="mx-4">Cloud</span>
                </Dropdown.Item>
                <Dropdown.Item
                  as="div"
                  onClick={() => {
                    getFromInvexAndMobile({ mobile: true });
                    setAttendanceText("Attendance From Mobile");
                  }}
                  className="p-cursor"
                >
                  <CloudIcon color="#2463ae" />{" "}
                  <span className="mx-4">Mobile App</span>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>

            <div className="text-center fw-bold mt-4">
              <p>{`${CheckTotal} Rows`}</p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            {excellDetails.length > 0 && (
              <Button onClick={(e) => uploadTimeSheet(e)}>View</Button>
            )}

            <button
              className="btn btn-secondary mx-4"
              onClick={(e) => cancelRequest(e)}
            >
              Cancel
            </button>
          </Modal.Footer>
        </Form>
      </Modal>
      <ModalLoader show={loading} />
    </>
  );
}
