/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-script-url */
import React, { useEffect, useState } from "react";
import { branch_url, URLS } from "../urls";
import { Link } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";
import { connect } from "react-redux";
import Cookies from "universal-cookie";
import moment from "moment";
import { Dropdown } from "react-bootstrap";
import { addAllDeductions } from "../deduction-settings/deductionAction";
import { addAllSettings } from "../settings/settingsAction";
import { branch_id, fetchActionsUtil, NewLogo } from "../../utils";
import Avatar from "../../Avatar";
import { addAllBonus } from "../bonus-settings/bonusAction";
import { addAllAllowance } from "../allowance/AllowanceAction";
import { addAllBenefit } from "../benefit/BenefitAction";
import { DownArrowIcon, NotificationIcon } from "../icons";
const Topbar = (props) => {
  const [state, setState] = useState({
    employee: null,
    notifications: [],
    cloudUrl: process.env.REACT_APP_CLOUD_DRIVE_FRONTEND,
    collaborationUrl: process.env.REACT_APP_COLLABORATION_FRONTEND,
    isLoading: false,
    branches: [],
  });

  const [selectedBranch, setSelectedBranch] = useState("");

  useEffect(() => {
    let _isMounted = true;

    const fetchAllData = async () => {
      try {
        setState((el) => ({
          ...el,
          isLoading: true,
        }));

        const cookies = new Cookies();
        const id = cookies.get("userId");
        const branchName = cookies.get("branch_name");

        setSelectedBranch(branchName);
        const token = "dummy token";

        const employeesData = fetchActionsUtil(
          `${URLS.backendEmployees}/getByUserId/${id}`,
          "GET",
          token
        );

        const notificationData = fetchActionsUtil(
          `${URLS.backendNotifications}/${id}`,
          "GET",
          token
        );

        const branchData = fetchActionsUtil(
          `${URLS.backendBranch}/get`,
          "GET",
          token
        );

        const [employeesResult, notificationResult, branchResult] =
          await Promise.all([employeesData, notificationData, branchData]);

        if (_isMounted) {
          setState((el) => ({
            ...el,
            employees: employeesResult?.employee,
            notifications: notificationResult.notifications || [],
            branches: branchResult.branches || [],
          }));

          fetch(`${URLS.backendApp}/set-up`, {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: `Token ${token}`,
            },
          })
            .then((res) => res.json())
            .then((result) => {
              props.addAllDeductions(result.deductionSettings);
              props.addAllSettings(result.settings);
              props.addAllBonus(result.bonusSettings);
              props.addAllAllowance(result.allowance);
              props.addAllBenefit(result.benefit);
            });
        }
      } catch (error) {
        if (_isMounted) {
          console.log(error);
        }
      } finally {
        if (_isMounted) {
          setState((el) => ({ ...el, isLoading: false }));
        }
      }
    };

    fetchAllData();
    return () => {
      _isMounted = false; // cleanup function sets the flag to false
    };
  }, []);

  const getDateDiff = (date) => {
    let createDate = moment(date);
    let now = moment(new Date());
    let diff = now.diff(createDate);
    diff = parseInt(diff / 1000);
    if (diff < 60) {
      return diff + " seconds ago";
    }
    diff = parseInt(diff / 60);
    if (diff < 60) {
      if (diff === 1) return "a min ago";
      else return diff + " mins ago";
    }

    diff = parseInt(diff / 60);
    if (diff < 24) {
      if (diff === 1) return "an hour ago";
      else return diff + " hours ago";
    }

    diff = parseInt(diff / 24);
    if (diff < 30) {
      if (diff === 1) return "a day ago";
      else return diff + " days ago";
    }

    diff = parseInt(diff / 30);
    if (diff === 1) return "a month ago";
    else return diff + " months ago";
  };

  const handleLogOut = async () => {
    try {
      const cookies = new Cookies();
      // const token = cookies.get("token");

      let domain = null;

      const url = branch_url?.split(".");

      if (url?.length > 1) {
        domain = window.location.href.includes("localhost")
          ? null
          : `.${url[1]}.${url[2]}`;
      }

      // let res = await fetch(URLS.backendUsers + "/logOut", {
      //   method: "POST",
      //   headers: {
      //     Accept: "application/json",
      //     "Content-Type": "application/json",
      //     Authorization: `Bearer ${token}`,
      //   },
      // });

      // res = await res.json();

      // if (res?.success) {
      //   if (process.env.REACT_APP_HAS_INVEX === "true") {
      //     let des = await fetch(URLS.mainSiteBackend + "/auth/logout", {
      //       method: "POST",
      //       headers: {
      //         Accept: "Application/json",
      //         "Content-Type": "Application/json",
      //       },
      //       body: JSON.stringify({ Staff_ID: "" }),
      //       credentials: "include",
      //     });

      //     await des.json();

      //     cookies.remove("token", { path: "/", ...(domain ? { domain } : {}) });
      //     cookies.remove("company_id", {
      //       path: "/",
      //       ...(domain ? { domain } : {}),
      //     });
      //     cookies.remove("user", { path: "/", ...(domain ? { domain } : {}) });
      //     cookies.remove("isSuperAdmin", {
      //       path: "/",
      //       ...(domain ? { domain } : {}),
      //     });
      //     cookies.remove("isAdmin", {
      //       path: "/",
      //       ...(domain ? { domain } : {}),
      //     });
      //     cookies.remove("userId", {
      //       path: "/",
      //       ...(domain ? { domain } : {}),
      //     });
      //     cookies.remove("isLoggedIn", {
      //       path: "/",
      //       ...(domain ? { domain } : {}),
      //     });
      //     cookies.remove("isFirstTime", {
      //       path: "/",
      //       ...(domain ? { domain } : {}),
      //     });
      //     cookies.remove("branch_id", {
      //       path: "/",
      //       ...(domain ? { domain } : {}),
      //     });
      //     cookies.remove("branch_url", {
      //       path: "/",
      //       ...(domain ? { domain } : {}),
      //     });
      //     cookies.remove("branch_name", {
      //       path: "/",
      //       ...(domain ? { domain } : {}),
      //     });
      //     cookies.remove("employee", {
      //       path: "/",
      //       ...(domain ? { domain } : {}),
      //     });
      //     window.location.href = `${URLS.login}`;
      //   } else {
      cookies.remove("token", { path: "/", ...(domain ? { domain } : {}) });
      cookies.remove("company_id", {
        path: "/",
        ...(domain ? { domain } : {}),
      });
      cookies.remove("user", { path: "/", ...(domain ? { domain } : {}) });
      cookies.remove("isSuperAdmin", {
        path: "/",
        ...(domain ? { domain } : {}),
      });
      cookies.remove("isAdmin", {
        path: "/",
        ...(domain ? { domain } : {}),
      });
      cookies.remove("userId", {
        path: "/",
        ...(domain ? { domain } : {}),
      });
      cookies.remove("isLoggedIn", {
        path: "/",
        ...(domain ? { domain } : {}),
      });
      cookies.remove("isFirstTime", {
        path: "/",
        ...(domain ? { domain } : {}),
      });
      cookies.remove("branch_id", {
        path: "/",
        ...(domain ? { domain } : {}),
      });
      cookies.remove("branch_url", {
        path: "/",
        ...(domain ? { domain } : {}),
      });
      cookies.remove("branch_name", {
        path: "/",
        ...(domain ? { domain } : {}),
      });
      cookies.remove("employee", {
        path: "/",
        ...(domain ? { domain } : {}),
      });
      window.location.href = `${URLS.login}`;
      //   }
      // }
    } catch (error) {
      console.log(error);
    }
  };

  const switchCompany = (item) => {
    const expiryDate = new Date(Date.now() + 7 * 24 * 60 * 60 * 1000);
    const cookies = new Cookies();

    const url = item?.url?.split(".");

    let domain = window.location.href.includes("localhost")
      ? null
      : `.${url[1]}.${url[2]}`;

    cookies.set("branch_id", item._id, {
      path: "/",
      expires: expiryDate,
      ...(domain ? { domain } : {}),
    });
    cookies.set("branch_url", item?.url, {
      path: "/",
      expires: expiryDate,
      ...(domain ? { domain } : {}),
    });

    cookies.set("branch_name", item?.name, {
      path: "/",
      expires: expiryDate,
      ...(domain ? { domain } : {}),
    });

    window.location.reload();
  };

  const { employee, notifications, isLoading, branches } = state;
  const employeeName = employee ? employee.personalInfo.name.first : "";
  const employeeImage =
    employee && employee.emergencyInfo && employee.emergencyInfo.image
      ? URLS.backendStatic + "/" + employee.emergencyInfo.image
      : "";
  return (
    <>
      {isLoading ? null : (
        <div className="header">
          {/* <!-- Logo --> */}
          <div className="header-left">
            {process.env.REACT_APP_HAS_INVEX === "true" ? (
              <a href={`${URLS.mainSite}/dashboard`} className="logo">
                <NewLogo className="logo" />
              </a>
            ) : (
              <Link to={`${URLS.dashboard}`} className="logo">
                <NewLogo className="logo" />
              </Link>
            )}
          </div>
          {/* <!-- /Logo --> */}

          {/* <!-- Header Title --> */}
          {/* <div className="page-title-box d-flex"></div> */}
          {/* <!-- /Header Title --> */}

          {/* <!-- Header Menu --> */}
          <div className="d-flex justify-content-end align-item-baseline mx-4">
            <div className="mt-3">
              <Dropdown>
                <Dropdown.Toggle variant="white" bsPrefix={`toggle`}>
                  {selectedBranch || "Switch Company"}
                </Dropdown.Toggle>
                <Dropdown.Menu
                  popperConfig={{
                    strategy: "fixed",
                  }}
                  renderOnMount
                >
                  {branches.map((el) => (
                    <Dropdown.Item
                      key={el.name}
                      as="button"
                      className={`${branch_id === el._id ? "active" : ""}`}
                      onClick={() => switchCompany(el)}
                    >
                      {el.name}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </div>

            <ul className="nav user-menu">
              {/* switch company */}

              {/* <!-- Notifications --> */}
              <li className="nav-item dropdown w-100">
                <a
                  href="javaScript:Void(0);"
                  className="dropdown-toggle nav-link"
                  data-toggle="dropdown"
                  style={{
                    color: "#000",
                  }}
                >
                  <NotificationIcon />
                  {notifications && notifications.length ? (
                    <span class="badge badge-pill">{notifications.length}</span>
                  ) : null}
                </a>

                <div className="dropdown-menu notifications">
                  <div className="topnav-dropdown-header">
                    <span className="notification-title">Notifications</span>
                    {/* <a href='javaScript:void(0)' className='clear-noti'> Clear All </a> */}
                  </div>
                  <div className="noti-content">
                    <ul className="notification-list">
                      {notifications &&
                        notifications.map((notification) => {
                          return (
                            <li className="notification-message">
                              <a
                                href={
                                  URLS.dashboard + "/" + notification.target
                                }
                              >
                                <div className="media">
                                  <span
                                    style={{
                                      width: "38px",
                                      height: "38px",
                                      lineHeight: "38px",
                                    }}
                                  >
                                    {/* <img alt='' src={URLS.landingPage +'/assets/img/profiles/avatar-02.jpg'} /> */}
                                  </span>
                                  <div className="media-body">
                                    <p className="noti-details">
                                      {ReactHtmlParser(notification.message)}
                                    </p>
                                    <p className="noti-time">
                                      <span className="notification-time">
                                        {getDateDiff(notification.createDate)}
                                      </span>
                                    </p>
                                  </div>
                                </div>
                              </a>
                            </li>
                          );
                        })}
                    </ul>
                  </div>
                </div>
              </li>
              {/* <!-- /Notifications --> */}

              {/* <!-- Message Notifications --> */}
              <li className="nav-item dropdown">
                <div>
                  <p></p>
                </div>
              </li>
              {/* <!-- /Message Notifications --> */}
            </ul>

            <div className="mt-3">
              <Dropdown>
                <Dropdown.Toggle className="cursor no-show-botton">
                  <div className="d-flex">
                    <div className="avatar-Nav">
                      <Avatar
                        image={employeeImage ? employeeImage : ""}
                        name={employeeName}
                      />
                    </div>

                    <DownArrowIcon />
                  </div>
                </Dropdown.Toggle>
                <Dropdown.Menu
                  popperConfig={{
                    strategy: "fixed",
                  }}
                  renderOnMount
                >
                  <Dropdown.Item as="button">
                    <Link
                      className="dropdown-item"
                      to={employee ? URLS.profile + "/" + employee._id : ""}
                    >
                      My Profile
                    </Link>
                  </Dropdown.Item>

                  <Dropdown.Item as="button">
                    <Link className="dropdown-item" to={URLS.settings}>
                      Settings
                    </Link>
                  </Dropdown.Item>
                  <Dropdown.Item as="button">
                    <span
                      style={{ cursor: "pointer" }}
                      className="dropdown-item"
                      onClick={() => handleLogOut()}
                    >
                      Logout
                    </span>
                  </Dropdown.Item>
                  <Dropdown.Item as="button">
                    <span
                      style={{ cursor: "pointer" }}
                      className="dropdown-item"
                    >
                      Version {process.env?.REACT_APP_VERSION}
                    </span>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
          {/* <!-- /Header Menu --> */}

          {/* <!-- Mobile Menu --> */}
          <div className="dropdown mobile-user-menu">
            <a
              href="javaScript:void(0)"
              className="nav-link dropdown-toggle"
              data-toggle="dropdown"
              aria-expanded="false"
            >
              <i className="fa fa-ellipsis-v"></i>
            </a>
            <div className="dropdown-menu dropdown-menu-right">
              <Link
                className="dropdown-item"
                to={employee ? URLS.profil + "/" + employee._id : ""}
              >
                My Profile
              </Link>
              <a className="dropdown-item" href={`${URLS.settings}`}>
                Settings
              </a>
              <div className="dropdown-item" onClick={() => handleLogOut()}>
                Logout
              </div>
              <div className="dropdown-item" onClick={() => handleLogOut()}>
                Version {process.env?.REACT_APP_VERSION}
              </div>
            </div>
          </div>
          {/* <!-- /Mobile Menu --> */}
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    settings: state.settingsReducer.settings,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addAllDeductions: (deductions) => dispatch(addAllDeductions(deductions)),
    addAllSettings: (settings) => dispatch(addAllSettings(settings)),
    addAllBonus: (bonus) => dispatch(addAllBonus(bonus)),
    addAllAllowance: (allowance) => dispatch(addAllAllowance(allowance)),
    addAllBenefit: (benefit) => dispatch(addAllBenefit(benefit)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Topbar);
