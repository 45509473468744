import React from "react";
import { URLS } from "../urls";
import { toast } from "react-toastify";
import DatePicker from "react-datetime";
import moment from "moment";
import { Modal, Row, Form, Col } from "react-bootstrap";

export const EditSuspension = ({
  suspensionDataToUpdate,
  suspensions,
  setSuspensions,
  setSuspensionDataToUpdate,
  show,
  handleClose,
}) => {
  const editSuspension = (e) => {
    e.preventDefault();
    const token = "dummy token";
    fetch(`${URLS.backendSuspension}/edit`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
      body: JSON.stringify({
        suspensionToEdit: suspensionDataToUpdate,
        setSuspensionDataToUpdate,
      }),
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.success) {
          const foundIndex = suspensions.findIndex(
            (suspend) => suspend.employeeId === result.suspension.employeeId
          );
          suspensions[foundIndex] = result.suspension;
          setSuspensions([...suspensions]);

          toast("Suspension saved", {
            type: "success",
          });
          handleClose();
        } else {
          throw Error();
        }
      })
      .catch((err) => {
        console.log(err);
        toast("Unable to edit resignation", {
          type: "error",
        });
      });
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      aria-labelledby=""
      centered
      size="md"
    >
      <Modal.Header closeButton>
        <Modal.Title>Edit Suspension</Modal.Title>
      </Modal.Header>
      <div className="modal-body">
        <form onSubmit={(e) => editSuspension(e)}>
          <Row>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>Employee</Form.Label>
                <Form.Control
                  value={suspensionDataToUpdate.employeeName}
                  disabled
                />
              </Form.Group>
            </Col>

            <Col>
              <Form.Group>
                <Form.Label>
                  <span className="text-danger">*</span> Type
                </Form.Label>
                <select
                  className="form-control"
                  aria-label="Default select example"
                  value={suspensionDataToUpdate.type}
                  name="type"
                  onChange={(e) =>
                    setSuspensionDataToUpdate({
                      ...suspensionDataToUpdate,
                      [e.target.name]: e.target.value,
                    })
                  }
                >
                  <option value="">Select Type</option>
                  <option value="Payment">With Payment</option>
                  <option value="Non-Payment">Without Payment</option>
                </select>
              </Form.Group>
            </Col>
          </Row>
          {/*  */}

          <Row>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>
                  <span className="text-danger">*</span> Notice Date
                </Form.Label>
                <DatePicker
                  value={suspensionDataToUpdate.noticeDate}
                  required
                  onChange={(e) => {
                    setSuspensionDataToUpdate({
                      ...suspensionDataToUpdate,
                      noticeDate: moment(e._d).format("DD/MM/YYYY"),
                    });
                  }}
                  closeOnSelect={true}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>
                  <span className="text-danger">*</span> Suspension Date
                </Form.Label>
                <DatePicker
                  value={suspensionDataToUpdate.suspensionDate}
                  required
                  onChange={(e) => {
                    setSuspensionDataToUpdate({
                      ...suspensionDataToUpdate,
                      suspensionDate: moment(e._d).format("DD/MM/YYYY"),
                    });
                  }}
                  closeOnSelect={true}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>
                  <span className="text-danger">*</span> Suspension Due Date
                </Form.Label>
                <DatePicker
                  value={suspensionDataToUpdate.suspensionEndDate}
                  required
                  onChange={(e) => {
                    setSuspensionDataToUpdate({
                      ...suspensionDataToUpdate,
                      suspensionEndDate: moment(e._d).format("DD/MM/YYYY"),
                    });
                  }}
                  closeOnSelect={true}
                />
              </Form.Group>
            </Col>
          </Row>
          {/*  */}

          <Form.Group className="mb-3">
            <Form.Label>
              <span className="text-danger">*</span> Reason
            </Form.Label>
            <Form.Control
              as="textarea"
              name="reason"
              value={suspensionDataToUpdate.reason}
              onChange={(e) => {
                setSuspensionDataToUpdate({
                  ...suspensionDataToUpdate,
                  [e.target.name]: e.target.value,
                });
              }}
              rows={4}
              placeholder="..."
              required
            />
          </Form.Group>

          <div className="submit-section">
            <button className="btn btn-primary submit-btn">Submit</button>
          </div>
        </form>
      </div>
    </Modal>
  );
};
