import React, { Fragment } from "react";
import { URLS } from "../urls";
import moment from "moment";
import { Form } from "react-bootstrap";
import { toast } from "react-toastify";
import Select from "react-select";
import CurrencyInput from "react-currency-input-field";
import { branch_id } from "../../utils";
import ModalLoader from "../ModalLoader";

export default class ApplyLoanModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      buttonText: "Submit",
      isWaiting: false,
      isLoading: false,
      Amount: 0,
      userId: "",
      applyDate: moment(new Date()).format("DD/MM/YYYY"),
      staff: "",
      departments: [],
      users: [],
      comment: "",
      appliedDate: "",
      title: "",
      description: "",
      employee: {},
      employees: [],
      supervisor: null,
      requester: null,
    };
  }
  componentWillReceiveProps(props) {}

  componentDidMount() {
    if (process.env.REACT_APP_HAS_INVEX !== "true") {
      return toast.error("Please activate accountant module to use Loan");
    }

    this.getDepartments();
    this.getEmployees();
  }

  getDepartments = async () => {
    // await waitFor(5000);
    if (process.env.REACT_APP_HAS_INVEX === "true") {
      let response = await fetch(`${URLS.mainSiteBackend}/users/departments`, {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        // credentials: "include",
      });

      if (!response.ok) {
        response = await response.json();
        console.log(response.message);
        throw new Error(response.message);
      }

      const { data } = await response.json();

      const res = data?.departments?.map((el) => ({
        value: el?.Department,
        label: el?.Department,
      }));

      this.setState({
        departments: [
          { value: "", label: "Select Recipient's Department" },
          ...res,
        ],
      });
    }
  };

  getEmployees = async () => {
    const token = "dummy token";
    fetch(`${URLS.backendEmployees}/get/${branch_id}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        const emp = result.employees.map((em) => ({
          label: `${
            em.personalInfo.name.first ? em.personalInfo.name.first : ""
          } ${em.personalInfo.name.middle ? em.personalInfo.name.middle : ""} ${
            em.personalInfo.name.last ? em.personalInfo.name.last : ""
          }`,
          value: JSON.stringify({
            id: em._id,
            name: `${
              em.personalInfo.name.first ? em.personalInfo.name.first : ""
            } ${
              em.personalInfo.name.middle ? em.personalInfo.name.middle : ""
            } ${em.personalInfo.name.last ? em.personalInfo.name.last : ""}`,
          }),
        }));
        this.setState({
          employees: emp,
        });
      });
  };

  getSupervisors = async (id) => {
    try {
      this.setState({ isLoading: true });
      let response = await fetch(
        `${URLS.backendEmployees}/getOneEmployeeSupervisor/${id}`,
        {
          method: "GET",
          headers: {
            Accept: "Application/json",
            "Content-Type": "Application/json",
          },
          // credentials: "include",
        }
      );

      if (!response.ok) {
        response = await response.json();
        throw new Error(response.message);
      }
      const { foundSupervisor } = await response.json();

      if (foundSupervisor) {
        if (process.env.REACT_APP_HAS_INVEX === "true") {
          let response = await fetch(
            `${URLS.mainSiteBackend}/users/${foundSupervisor?.Staff_ID}`,
            {
              method: "GET",
              headers: {
                Accept: "Application/json",
                "Content-Type": "Application/json",
              },
              // credentials: "include",
            }
          );

          if (!response.ok) {
            response = await response.json();

            throw new Error(response.message);
          }

          const { data } = await response.json();

          this.setState({
            supervisor: data,
            staff: data?.Staff_ID,
          });
        }
      } else {
        this.setState({
          supervisor: null,
          staff: "",
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.setState({ isLoading: false });
    }
  };

  getUsersInDepartments = async (department) => {
    let response = await fetch(
      `${URLS.mainSiteBackend}/users/by-department/${department}`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        // credentials: "include",
      }
    );

    if (!response.ok) {
      response = await response.json();
      console.log(response.message);
      throw new Error(response.message);
    }
    const { data } = await response.json();

    const res = data?.users?.map((el) => ({
      value: el?.Staff_ID,
      label: el?.Name,
    }));

    this.setState({
      users: [{ value: "", label: "Select Recipient" }, ...res],
    });
  };

  onChangeInputs = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  updateAmount = (value) => {
    this.setState({
      Amount: value,
    });
  };

  submit = (e) => {
    e.preventDefault();
    this.setState({ buttonText: "Submitting ...", isWaiting: true });
    const {
      applyDate,
      title,
      description,
      comment,
      Amount,
      staff,
      employee,
      requester,
    } = this.state;

    if (Object.entries(employee).length < 1) {
      return toast.error("Please select an employee", {
        position: "top-right",
      });
    }

    const employeeName = JSON.parse(employee).name;
    const employeeId = JSON.parse(employee).id;

    const requsion = {
      title,
      description,
      type: "Loan",
      amount: Amount,
      requestto: staff,
      status: "Pending",
      regdate: moment(new Date()).format("YYYY-MM-DD"),
      requestby: requester ? requester : staff,
      vendor: "",
      comment,
      filledFormData: "",
      loanDetails: JSON.stringify({ employeeName, employeeId }),
    };

    const formData = new FormData();
    // eslint-disable-next-line no-unused-vars
    for (const [key, value] of Object.entries(requsion)) {
      formData.append(key, value);
    }
    // console.log(requsion);
    const token = "dummy token";

    fetch(`${URLS.backendLoan}/check/${employeeId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    }).then((z) => {
      if (z?.status === 500) {
        z.json().then((x) => {
          this.setState({ buttonText: "Submit", isWaiting: false });
          toast(x.message, {
            type: "error",
            position: "top-right",
          });
        });

        return;
      }

      if (process.env.REACT_APP_HAS_INVEX === "true") {
        fetch(`${URLS.mainSiteBackend}/requisition/create`, {
          method: "POST",
          // credentials: "include",
          headers: {
            Authorization: `Token ${token}`,
          },
          body: formData,
        }).then((res) => {
          if (res.ok) {
            res.json().then((result) => {
              console.log(result);
              fetch(`${URLS.backendLoan}`, {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Token ${token}`,
                },
                body: JSON.stringify({
                  Title: title,
                  Amount,
                  applyDate,
                  status: "Pending",
                  paymentsStatus: "Not paid",
                  userID: employeeId,
                  staff,
                  requestid: result?.data?.requisition?.requestid,
                  description,
                  comment,
                  branch_id,
                }),
              }).then((el) => {
                if (el.ok) {
                  el.json().then((response) => {
                    console.log(response);

                    toast("Loan Successfully Applied.", {
                      type: "success",
                      position: "top-right",
                    });
                    this.setState({
                      buttonText: "Submit",
                      isWaiting: false,
                      staff: "",
                      departments: [],
                      users: [],
                      comment: "",
                      appliedDate: "",
                      title: "",
                      description: "",
                      Amount: 0,
                      userId: "",
                      employee: {},
                      employees: [],
                    });
                  });
                } else {
                  el.json().then((json) => {
                    console.log(json);

                    toast("Error occurred, Check the form again.", {
                      type: "error",
                      position: "top-right",
                    });
                    this.setState({ buttonText: "Submit", isWaiting: false });
                  });
                }
              });
            });
          } else {
            res.json().then((responseJson) => {
              console.log(responseJson);
              toast("Error occurred, Check the form again.", {
                type: "error",
                position: "top-right",
              });
              this.setState({ buttonText: "Submit", isWaiting: false });
            });
          }
        });
      } else {
        return alert("Please activate accountant module to use Loan");
      }
    });
  };

  getEmployeeFromInvex = (id) => {
    fetch(`${URLS.backendEmployees}/get-employee-from-invex-details/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data) {
          this.setState({
            requester: data?.invexStaff?.Staff_ID,
          });
        }
      });
  };

  render() {
    const {
      departments,
      users,
      Amount,
      staff,
      buttonText,
      title,
      description,
      comment,
      isWaiting,
      employees,
      employee,
      supervisor,
      isLoading,
    } = this.state;

    return (
      <div id="add_leave" className="modal custom-modal fade" role="dialog">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Apply Loan</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form onSubmit={this.submit}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    Employee <span className="text-danger">*</span> :
                  </Form.Label>

                  <Select
                    closeMenuOnSelect={true}
                    isSearchable
                    required
                    classNamePrefix="form-control"
                    value={employees?.find((el) => el.value === employee?.name)}
                    onChange={(selected) => {
                      this.setState({ employee: selected.value });
                      this.getSupervisors(JSON.parse(selected.value).id); // eslint-disable-line
                      this.getEmployeeFromInvex(JSON.parse(selected.value).id);
                    }}
                    options={employees}
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>
                    Title <span className="text-danger">*</span> :
                  </Form.Label>
                  <Form.Control
                    name="title"
                    placeholder="Enter a title"
                    value={title}
                    onChange={this.onChangeInputs}
                    required
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>
                    Description <span className="text-danger">*</span> :
                  </Form.Label>
                  <label className="border p-0 rounded pb-2 message-area d-block">
                    <Form.Control
                      name="description"
                      value={description}
                      onChange={this.onChangeInputs}
                      as="textarea"
                      className="w-100 border-0 p-3"
                      placeholder="Enter your detail description"
                      rows={4}
                      required
                    />
                  </label>
                </Form.Group>
                <Form.Group className="mb-4">
                  <Form.Label>
                    Loan Amount <span className="text-danger">*</span> :
                  </Form.Label>

                  <CurrencyInput
                    className="form-control"
                    value={Amount}
                    allowNegativeValue={false}
                    // allowDecimals={false}
                    onValueChange={(value, name) => this.updateAmount(value)}
                  />
                </Form.Group>

                <div className="d-flex align-items-center my-4">
                  <h4 className="pr-2">Send Request To</h4>{" "}
                  <hr className="flex-grow-1 m-0" />
                </div>

                {!supervisor ? (
                  <div className="row gap-3">
                    <Form.Group className="col mb-3">
                      <Form.Label>
                        Department <span className="text-danger">*</span> :
                      </Form.Label>
                      <Select
                        classNamePrefix={`form-select-analytics`}
                        isSearchable={true}
                        placeholder="Select Recipient's Department"
                        options={departments}
                        // value={departments?.find(
                        //   (el) => el.value === queryParams.by
                        // )}
                        onChange={(selected) => {
                          this.getUsersInDepartments(selected?.value);
                        }}
                      />
                    </Form.Group>

                    {users.length > 0 && (
                      <Form.Group className="col mb-3">
                        <Form.Label>
                          Recipient <span className="text-danger">*</span> :
                        </Form.Label>
                        <Select
                          classNamePrefix={`form-select-analytics`}
                          isSearchable={true}
                          placeholder="Select Recipient"
                          options={users}
                          // value={departments?.find(
                          //   (el) => el.value === queryParams.by
                          // )}
                          onChange={(selected) => {
                            const e = {
                              target: { name: "staff", value: selected?.value },
                            };
                            this.onChangeInputs(e);
                          }}
                        />
                      </Form.Group>
                    )}
                  </div>
                ) : (
                  <div className="my-3">
                    <input
                      className="form-control"
                      disabled
                      type="text"
                      value={supervisor?.Name}
                    />
                  </div>
                )}
                <Form.Group className="mb-3">
                  <Form.Label>Comment :</Form.Label>
                  <label className="border p-0 rounded pb-2 message-area d-block">
                    <Form.Control
                      name="comment"
                      value={comment}
                      onChange={this.onChangeInputs}
                      as="textarea"
                      className="w-100 border-0 p-3"
                      placeholder="Enter a comment"
                      rows={4}
                    />
                  </label>
                </Form.Group>
                <div className="submit-section">
                  <button
                    className="btn btn-primary submit-btn"
                    disabled={isWaiting}
                  >
                    {buttonText}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>

        <ModalLoader show={isLoading} />
      </div>
    );
  }
}
